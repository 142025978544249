import {api} from "./baseApi";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
export type MakeEmpty<T extends {[key: string]: unknown}, K extends keyof T> = {[_ in K]?: never};
export type Incremental<T> = T | {[P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {input: string; output: string};
  String: {input: string; output: string};
  Boolean: {input: boolean; output: boolean};
  Int: {input: number; output: number};
  Float: {input: number; output: number};
  Decimal: {input: any; output: any};
  ISO8601Date: {input: any; output: any};
  ISO8601DateTime: {input: any; output: any};
  JSON: {input: any; output: any};
  TranslatedString: {input: any; output: any};
  Upload: {input: any; output: any};
};

/** Input attributes for accepting terms */
export type AcceptTermsInput = {
  ipAddress?: InputMaybe<Scalars["String"]["input"]>;
  terms: Array<TermsInput>;
};

export enum Addon {
  SalesUser = "SALES_USER",
}

export type Address = {
  __typename?: "Address";
  city: Scalars["String"]["output"];
  line1: Scalars["String"]["output"];
  line2?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
};

export type AddressInput = {
  city: Scalars["String"]["input"];
  line1: Scalars["String"]["input"];
  line2?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type Allergen = {
  __typename?: "Allergen";
  code: AllergenCode;
  description?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
};

export enum AllergenCode {
  Celery = "CELERY",
  Crustacean = "CRUSTACEAN",
  Eggs = "EGGS",
  Fish = "FISH",
  Gluten = "GLUTEN",
  Lupin = "LUPIN",
  Milk = "MILK",
  Mollusks = "MOLLUSKS",
  Mustard = "MUSTARD",
  None = "NONE",
  Nuts = "NUTS",
  Peanuts = "PEANUTS",
  Sesame = "SESAME",
  Soybeans = "SOYBEANS",
  Sulfites = "SULFITES",
}

export type Announcement = {
  __typename?: "Announcement";
  id: Scalars["ID"]["output"];
  salesChannels: Array<SalesChannels>;
  status: AnnouncementStatus;
  text: Scalars["String"]["output"];
};

/** Autogenerated return type of AnnouncementCreate. */
export type AnnouncementCreatePayload = {
  __typename?: "AnnouncementCreatePayload";
  announcement?: Maybe<Announcement>;
};

/** Autogenerated return type of AnnouncementDelete. */
export type AnnouncementDeletePayload = {
  __typename?: "AnnouncementDeletePayload";
  announcement?: Maybe<Announcement>;
};

/** Input attributes for a shop announcement */
export type AnnouncementInput = {
  salesChannels?: InputMaybe<Array<SalesChannels>>;
  status: AnnouncementStatus;
  text: Scalars["String"]["input"];
};

export enum AnnouncementStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
}

/** Autogenerated return type of AnnouncementUpdate. */
export type AnnouncementUpdatePayload = {
  __typename?: "AnnouncementUpdatePayload";
  announcement?: Maybe<Announcement>;
};

export enum AspectRatioMode {
  Cover = "COVER",
  Original = "ORIGINAL",
}

export enum AuthRight {
  CommerceRead = "COMMERCE_READ",
  CommerceWrite = "COMMERCE_WRITE",
  CommonRead = "COMMON_READ",
  CustomerExport = "CUSTOMER_EXPORT",
  CustomerRead = "CUSTOMER_READ",
  CustomerWrite = "CUSTOMER_WRITE",
  FinanceRead = "FINANCE_READ",
  FulfillmentRead = "FULFILLMENT_READ",
  FulfillmentWrite = "FULFILLMENT_WRITE",
  ListingsRead = "LISTINGS_READ",
  OrderConfirm = "ORDER_CONFIRM",
  OrderRead = "ORDER_READ",
  OrderWrite = "ORDER_WRITE",
  PricingGroupRead = "PRICING_GROUP_READ",
  PricingGroupWrite = "PRICING_GROUP_WRITE",
  ProductRead = "PRODUCT_READ",
  ProductWrite = "PRODUCT_WRITE",
  ReportRead = "REPORT_READ",
  SettingsRead = "SETTINGS_READ",
  SettingsWrite = "SETTINGS_WRITE",
}

/** Balance overview for a Maker */
export type BalanceOverview = {
  __typename?: "BalanceOverview";
  balance: Money;
  fees: Array<TransactionFee>;
  period: Scalars["String"]["output"];
  totalFees: Money;
  totalPayments: Money;
};

export type BankAccount = {
  __typename?: "BankAccount";
  currency: Scalars["String"]["output"];
  details: Scalars["JSON"]["output"];
  id: Scalars["ID"]["output"];
};

/** Autogenerated return type of BankAccountCreate. */
export type BankAccountCreatePayload = {
  __typename?: "BankAccountCreatePayload";
  bankAccount?: Maybe<BankAccount>;
};

/** Input attributes for creating or updating a bank account */
export type BankAccountInput = {
  /** currency for the account as 3 letter code */
  currency: Scalars["String"]["input"];
  /** actual account details, like {"iban": "DK1234000012341111"} */
  details: Scalars["JSON"]["input"];
};

/** Autogenerated return type of BankAccountUpdate. */
export type BankAccountUpdatePayload = {
  __typename?: "BankAccountUpdatePayload";
  bankAccount?: Maybe<BankAccount>;
};

export type BatchCode = {
  __typename?: "BatchCode";
  code: Scalars["String"]["output"];
  productId: Scalars["ID"]["output"];
  quantity: Scalars["Int"]["output"];
};

/** Input attributes for batch codes */
export type BatchCodeInput = {
  code: Scalars["String"]["input"];
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export type Beverage = ProductInterface & {
  __typename?: "Beverage";
  alcoholPercent?: Maybe<Scalars["Float"]["output"]>;
  allergens: Array<Allergen>;
  bemakersSku: Scalars["String"]["output"];
  brand?: Maybe<Brand>;
  caseQuantityRetail: Scalars["Int"]["output"];
  caseQuantityWholesale: Scalars["Int"]["output"];
  cnCode?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  degreePlato?: Maybe<Scalars["Float"]["output"]>;
  /** @deprecated removed */
  description?: Maybe<Scalars["String"]["output"]>;
  exciseProductCode?: Maybe<Scalars["String"]["output"]>;
  grossWeight?: Maybe<Scalars["Float"]["output"]>;
  gtin?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  images: Array<Image>;
  kind: ProductKind;
  minOrderQuantityRetail: Scalars["Int"]["output"];
  minOrderQuantityWholesale: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  netWeight?: Maybe<Scalars["Float"]["output"]>;
  ogImageUrl?: Maybe<Scalars["String"]["output"]>;
  organic: Scalars["Boolean"]["output"];
  packagingCode?: Maybe<Scalars["String"]["output"]>;
  premix?: Maybe<PremixKind>;
  prepacked: Scalars["Boolean"]["output"];
  productPrices: Array<ProductPrice>;
  shopCountries: Array<Scalars["String"]["output"]>;
  shopGlobalUrl?: Maybe<Scalars["String"]["output"]>;
  sku: Scalars["String"]["output"];
  status: ProductStatus;
  stocks: Array<Stock>;
  sugarPerLiter?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
  volume?: Maybe<Scalars["Float"]["output"]>;
};

/** The connection type for Beverage. */
export type BeverageConnection = {
  __typename?: "BeverageConnection";
  /** A list of nodes. */
  nodes: Array<Beverage>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** You can filter alcohol products by these fields */
export type BeverageFilter = {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  shopCountries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Input attributes for creating or updating an alcohol product */
export type BeverageInput = {
  alcoholPercent?: InputMaybe<Scalars["Float"]["input"]>;
  allergens?: InputMaybe<Array<AllergenCode>>;
  brandId?: InputMaybe<Scalars["ID"]["input"]>;
  caseQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  caseQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  cnCode?: InputMaybe<Scalars["String"]["input"]>;
  degreePlato?: InputMaybe<Scalars["Float"]["input"]>;
  /** @deprecated removed */
  description?: InputMaybe<Scalars["String"]["input"]>;
  exciseProductCode?: InputMaybe<Scalars["String"]["input"]>;
  grossWeight?: InputMaybe<Scalars["Float"]["input"]>;
  gtin?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<Array<ImageInput>>;
  minOrderQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  minOrderQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  netWeight?: InputMaybe<Scalars["Float"]["input"]>;
  ogImageFile?: InputMaybe<Scalars["Upload"]["input"]>;
  organic?: InputMaybe<Scalars["Boolean"]["input"]>;
  packagingCode?: InputMaybe<Scalars["String"]["input"]>;
  premix?: InputMaybe<PremixKind>;
  productPrices: Array<ProductPriceInput>;
  sku: Scalars["String"]["input"];
  stocks?: InputMaybe<Array<StockInput>>;
  sugarPerLiter?: InputMaybe<Scalars["Float"]["input"]>;
  volume?: InputMaybe<Scalars["Float"]["input"]>;
};

export enum BeverageSortField {
  Alcohol = "ALCOHOL",
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  Sku = "SKU",
  Volume = "VOLUME",
}

export type BeverageSorting = {
  direction: SortDirection;
  field: BeverageSortField;
};

export type BillingAddress = {
  __typename?: "BillingAddress";
  city: Scalars["String"]["output"];
  company?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  default: Scalars["Boolean"]["output"];
  line1: Scalars["String"]["output"];
  line2?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
};

export type BillingAddressInput = {
  city: Scalars["String"]["input"];
  company?: InputMaybe<Scalars["String"]["input"]>;
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  line1: Scalars["String"]["input"];
  line2?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type Brand = {
  __typename?: "Brand";
  coverUrl?: Maybe<Scalars["String"]["output"]>;
  descriptionHtml: Scalars["TranslatedString"]["output"];
  descriptionJson: Scalars["TranslatedString"]["output"];
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  homepageUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  instagramUrl?: Maybe<Scalars["String"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
  tagline: Scalars["TranslatedString"]["output"];
};

/** Autogenerated return type of BrandCreate. */
export type BrandCreatePayload = {
  __typename?: "BrandCreatePayload";
  brand?: Maybe<Brand>;
};

export type BrandInput = {
  coverFile?: InputMaybe<Scalars["Upload"]["input"]>;
  descriptionHtml?: InputMaybe<Scalars["TranslatedString"]["input"]>;
  descriptionJson?: InputMaybe<Scalars["TranslatedString"]["input"]>;
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  homepageUrl?: InputMaybe<Scalars["String"]["input"]>;
  instagramUrl?: InputMaybe<Scalars["String"]["input"]>;
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  logoFile?: InputMaybe<Scalars["Upload"]["input"]>;
  name: Scalars["String"]["input"];
  tagline?: InputMaybe<Scalars["TranslatedString"]["input"]>;
};

/** Autogenerated return type of BrandUpdate. */
export type BrandUpdatePayload = {
  __typename?: "BrandUpdatePayload";
  brand?: Maybe<Brand>;
};

export type BusinessMember = {
  __typename?: "BusinessMember";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  consents: Array<Consent>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  sentAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  status: InvitationStatus;
};

/** Autogenerated return type of BusinessMemberCreate. */
export type BusinessMemberCreatePayload = {
  __typename?: "BusinessMemberCreatePayload";
  businessMember?: Maybe<BusinessMember>;
};

/** Autogenerated return type of BusinessMemberDelete. */
export type BusinessMemberDeletePayload = {
  __typename?: "BusinessMemberDeletePayload";
  businessMember?: Maybe<BusinessMember>;
};

/** Input attributes for an collection */
export type BusinessMemberInput = {
  email: Scalars["String"]["input"];
};

/** Autogenerated return type of BusinessMemberResend. */
export type BusinessMemberResendPayload = {
  __typename?: "BusinessMemberResendPayload";
  businessMember?: Maybe<BusinessMember>;
};

/** Autogenerated return type of BusinessMemberUpdate. */
export type BusinessMemberUpdatePayload = {
  __typename?: "BusinessMemberUpdatePayload";
  businessMember?: Maybe<BusinessMember>;
};

export type Carrier = {
  __typename?: "Carrier";
  deliveryCollect: Scalars["Boolean"]["output"];
  deliveryHome: Scalars["Boolean"]["output"];
  deliveryTime: Scalars["Boolean"]["output"];
  deliveryTimeOptions?: Maybe<Array<TimeOptions>>;
  estimatedPrice?: Maybe<Money>;
  kind: CarrierKind;
  name: Scalars["String"]["output"];
  palletFull: Scalars["Boolean"]["output"];
  palletHalf: Scalars["Boolean"]["output"];
  palletQuarter: Scalars["Boolean"]["output"];
  parcel: Scalars["Boolean"]["output"];
  pickupTime: Scalars["Boolean"]["output"];
  pickupTimeOptions?: Maybe<Array<TimeOptions>>;
};

export enum CarrierKind {
  BemakersDelivery = "BEMAKERS_DELIVERY",
  Dachser = "DACHSER",
  DanskeFragtmaend = "DANSKE_FRAGTMAEND",
  Dhl = "DHL",
  Dsv = "DSV",
  Exacta = "EXACTA",
  Fedex = "FEDEX",
  Freja = "FREJA",
  Gls = "GLS",
  Hensel = "HENSEL",
  Manual = "MANUAL",
  NoShipping = "NO_SHIPPING",
  Postnord = "POSTNORD",
  Tine = "TINE",
  Ups = "UPS",
}

export type CarrierOptions = {
  __typename?: "CarrierOptions";
  deliveryDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  deliveryTimeFrom?: Maybe<Scalars["String"]["output"]>;
  deliveryTimeTo?: Maybe<Scalars["String"]["output"]>;
  pickupDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  pickupTimeFrom?: Maybe<Scalars["String"]["output"]>;
  pickupTimeTo?: Maybe<Scalars["String"]["output"]>;
};

/** Input attributes for a carrier options */
export type CarrierOptionsInput = {
  deliveryDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  deliveryTimeFrom?: InputMaybe<Scalars["String"]["input"]>;
  deliveryTimeTo?: InputMaybe<Scalars["String"]["input"]>;
  pickupDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  pickupTimeFrom?: InputMaybe<Scalars["String"]["input"]>;
  pickupTimeTo?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CartPaymentMethod {
  Klarna = "KLARNA",
  Mobilepay = "MOBILEPAY",
  Paypal = "PAYPAL",
  Stripe = "STRIPE",
}

export type ChargeMethod = {
  __typename?: "ChargeMethod";
  details: Scalars["JSON"]["output"];
  id: Scalars["ID"]["output"];
  kind: Scalars["String"]["output"];
  status: Scalars["String"]["output"];
};

/** A Stripe Checkout Session for setting up a Charge Method */
export type ChargeMethodSetup = {
  __typename?: "ChargeMethodSetup";
  setupUrl: Scalars["String"]["output"];
};

/** Input attributes for creating a Stripe Session for setting up a Charge Method */
export type ChargeMethodSetupInput = {
  /** URL to redirect to after canceled setup */
  cancelUrl: Scalars["String"]["input"];
  /** URL to redirect to after successful setup */
  successUrl: Scalars["String"]["input"];
};

/** Autogenerated return type of ChargeMethodSetup. */
export type ChargeMethodSetupPayload = {
  __typename?: "ChargeMethodSetupPayload";
  chargeMethodSetup?: Maybe<ChargeMethodSetup>;
};

export type Collection = {
  __typename?: "Collection";
  defaultDescription?: Maybe<Scalars["String"]["output"]>;
  defaultTitle: Scalars["String"]["output"];
  /** @deprecated Use `html_description and json_description` instead. */
  description?: Maybe<Scalars["String"]["output"]>;
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  htmlDescription?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  items: Array<CollectionItem>;
  jsonDescription?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ogDescription?: Maybe<Scalars["String"]["output"]>;
  ogImageUrl?: Maybe<Scalars["String"]["output"]>;
  ogTitle?: Maybe<Scalars["String"]["output"]>;
  position: Scalars["Int"]["output"];
  salesChannels?: Maybe<Array<SalesChannels>>;
  seoDescription?: Maybe<Scalars["String"]["output"]>;
  seoTitle?: Maybe<Scalars["String"]["output"]>;
  shop: Shop;
  shopBanner?: Maybe<ShopBanner>;
  slug?: Maybe<Scalars["String"]["output"]>;
  status: CollectionStatus;
};

/** Autogenerated return type of CollectionCreate. */
export type CollectionCreatePayload = {
  __typename?: "CollectionCreatePayload";
  collection?: Maybe<Collection>;
};

/** Autogenerated return type of CollectionDelete. */
export type CollectionDeletePayload = {
  __typename?: "CollectionDeletePayload";
  collection?: Maybe<Collection>;
};

/** Input attributes for an collection */
export type CollectionInput = {
  /** @deprecated Use `html_description and json_description` instead. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  htmlDescription?: InputMaybe<Scalars["String"]["input"]>;
  items?: InputMaybe<Array<CollectionItemInput>>;
  jsonDescription?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  ogImageFile?: InputMaybe<Scalars["Upload"]["input"]>;
  salesChannels?: InputMaybe<Array<SalesChannels>>;
  shopBannerId?: InputMaybe<Scalars["ID"]["input"]>;
  status: CollectionStatus;
};

export type CollectionItem = {
  __typename?: "CollectionItem";
  createdAt: Scalars["ISO8601DateTime"]["output"];
  position: Scalars["Int"]["output"];
  shopProduct: ShopProduct;
};

/** Input attributes for an collection item */
export type CollectionItemInput = {
  shopProductId: Scalars["ID"]["input"];
};

/** Input attributes for meta attributes of a collection */
export type CollectionMetaInput = {
  ogDescription?: InputMaybe<Scalars["String"]["input"]>;
  ogTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
};

/** Input attributes for reorder */
export type CollectionReorderInput = {
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of CollectionReorder. */
export type CollectionReorderPayload = {
  __typename?: "CollectionReorderPayload";
  collections?: Maybe<Array<Collection>>;
};

export enum CollectionStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
}

/** Autogenerated return type of CollectionUpdateMeta. */
export type CollectionUpdateMetaPayload = {
  __typename?: "CollectionUpdateMetaPayload";
  collection?: Maybe<Collection>;
};

/** Autogenerated return type of CollectionUpdate. */
export type CollectionUpdatePayload = {
  __typename?: "CollectionUpdatePayload";
  collection?: Maybe<Collection>;
};

export type Consent = {
  __typename?: "Consent";
  acceptedAt: Scalars["ISO8601DateTime"]["output"];
  description: Scalars["String"]["output"];
  kind: ConsentKind;
  version: Scalars["String"]["output"];
};

export enum ConsentKind {
  MakerNewsletter = "MAKER_NEWSLETTER",
  Marketing = "MARKETING",
  MissingConsent = "MISSING_CONSENT",
}

export type Consignor = {
  __typename?: "Consignor";
  /** Address details of the consignor */
  address?: Maybe<MailingAddress>;
  /** Excise number of the consignor */
  exciseNumber?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of CreateBeverage. */
export type CreateBeveragePayload = {
  __typename?: "CreateBeveragePayload";
  product?: Maybe<Beverage>;
};

/** Autogenerated return type of CreateProductBundle. */
export type CreateProductBundlePayload = {
  __typename?: "CreateProductBundlePayload";
  productBundle?: Maybe<ProductBundle>;
};

export type CreatedBy = {
  __typename?: "CreatedBy";
  email: Scalars["String"]["output"];
  id?: Maybe<Scalars["ID"]["output"]>;
  impersonation: Scalars["Boolean"]["output"];
  kind: CreatedByKind;
  name: Scalars["String"]["output"];
};

export enum CreatedByKind {
  BusinessUser = "BUSINESS_USER",
  Customer = "CUSTOMER",
  System = "SYSTEM",
  User = "USER",
}

export type CsmUser = {
  __typename?: "CsmUser";
  email: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
};

export type Current = {
  __typename?: "Current";
  currentMaker?: Maybe<CurrentMaker>;
  currentUser: CurrentUser;
  impersonation: Scalars["Boolean"]["output"];
  maker?: Maybe<Maker>;
  user: User;
};

export type CurrentMaker = {
  __typename?: "CurrentMaker";
  addons: Array<Addon>;
  countryConstraints: Array<Scalars["String"]["output"]>;
  csm?: Maybe<CsmUser>;
  features: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  integrations?: Maybe<Array<IntegrationCredentialKind>>;
  markets?: Maybe<Array<MakerMarket>>;
  name: Scalars["String"]["output"];
  onboarding?: Maybe<Onboarding>;
  rights: Array<AuthRight>;
  trade: Scalars["Boolean"]["output"];
  tradeName?: Maybe<Scalars["String"]["output"]>;
};

export type CurrentUser = {
  __typename?: "CurrentUser";
  email: Scalars["String"]["output"];
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
};

export type Customer = {
  __typename?: "Customer";
  billingAddresses?: Maybe<Array<BillingAddress>>;
  billingEmail?: Maybe<Scalars["String"]["output"]>;
  ccInvoices: Scalars["Boolean"]["output"];
  consents: Array<Consent>;
  country: Scalars["String"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  currency: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  exciseDestinationKind?: Maybe<ExciseDestinationKind>;
  exciseNumber?: Maybe<Scalars["String"]["output"]>;
  exciseTaxWarehouse?: Maybe<Scalars["String"]["output"]>;
  exciseWarehouseKeeper?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kind: CustomerKind;
  lastOrders: Array<Order>;
  legalDrinkingAge?: Maybe<Scalars["Boolean"]["output"]>;
  mailingAddresses: Array<MailingAddress>;
  name: Scalars["String"]["output"];
  ordersCount: Scalars["Int"]["output"];
  paymentTerms?: Maybe<Scalars["String"]["output"]>;
  pricingGroup?: Maybe<PricingGroup>;
  stats: CustomerStats;
  tags: Array<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for Customer. */
export type CustomerConnection = {
  __typename?: "CustomerConnection";
  /** A list of nodes. */
  nodes: Array<Customer>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of CustomerCreate. */
export type CustomerCreatePayload = {
  __typename?: "CustomerCreatePayload";
  customer?: Maybe<Customer>;
};

export type CustomerExport = {
  __typename?: "CustomerExport";
  exportUrl: Scalars["String"]["output"];
};

/** You can filter customers by these fields */
export type CustomerFilter = {
  consents?: InputMaybe<Array<ConsentKind>>;
  country?: InputMaybe<Array<Scalars["String"]["input"]>>;
  kind?: InputMaybe<Array<CustomerKind>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Specify whether all tags should be present or any of them using AND or OR. */
  tagsOperator?: InputMaybe<Filter>;
};

/** Input attributes for creating or updating a customer */
export type CustomerInput = {
  billingAddresses?: InputMaybe<Array<BillingAddressInput>>;
  billingEmail?: InputMaybe<Scalars["String"]["input"]>;
  ccInvoices: Scalars["Boolean"]["input"];
  country: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  /** required for _distributor_ customers */
  exciseDestinationKind?: InputMaybe<ExciseDestinationKind>;
  /** required for _distributor_ customers */
  exciseNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** required for _distributor_ customers */
  exciseTaxWarehouse?: InputMaybe<Scalars["String"]["input"]>;
  /** required for _distributor_ customers */
  exciseWarehouseKeeper?: InputMaybe<Scalars["String"]["input"]>;
  kind: CustomerKind;
  /** required for _consumer_ customers */
  legalDrinkingAge?: InputMaybe<Scalars["Boolean"]["input"]>;
  mailingAddresses: Array<MailingAddressInput>;
  name: Scalars["String"]["input"];
  /** required for _business_ and _distributor_ customers */
  paymentTerms?: InputMaybe<Scalars["String"]["input"]>;
  pricingGroupId?: InputMaybe<Scalars["ID"]["input"]>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** required for _business_ and _distributor customers */
  taxId?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CustomerKind {
  Business = "BUSINESS",
  Consumer = "CONSUMER",
  Distributor = "DISTRIBUTOR",
  Marketplace = "MARKETPLACE",
}

export enum CustomerSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
}

export type CustomerSorting = {
  direction: SortDirection;
  field: CustomerSortField;
};

export type CustomerStats = {
  __typename?: "CustomerStats";
  averageOrderValue: Money;
  ordersCount: Scalars["Int"]["output"];
  totalOrdersValue: Money;
};

/** Autogenerated return type of CustomerUpdate. */
export type CustomerUpdatePayload = {
  __typename?: "CustomerUpdatePayload";
  customer?: Maybe<Customer>;
};

/** Autogenerated return type of DeleteProduct. */
export type DeleteProductPayload = {
  __typename?: "DeleteProductPayload";
  product?: Maybe<ProductInterface>;
};

export enum DeliveryMethod {
  ClickAndCollect = "CLICK_AND_COLLECT",
  Collect = "COLLECT",
  Digital = "DIGITAL",
  Home = "HOME",
  Internal = "INTERNAL",
}

export type DiscountAmount = DiscountInterface & {
  __typename?: "DiscountAmount";
  activeFrom?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  activeTo?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  amountValue: Money;
  code: Scalars["String"]["output"];
  conditionOnQuantity?: Maybe<Scalars["Int"]["output"]>;
  conditionPrice?: Maybe<Money>;
  conditionType: DiscountCondition;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  displayStatus: DiscountDisplayStatus;
  id: Scalars["ID"]["output"];
  kind: DiscountKind;
  limitPerUser?: Maybe<Scalars["Int"]["output"]>;
  limitTotal?: Maybe<Scalars["Int"]["output"]>;
  salesChannels: Array<SalesChannels>;
  status: DiscountStatus;
};

/** Autogenerated return type of DiscountAmountCreate. */
export type DiscountAmountCreatePayload = {
  __typename?: "DiscountAmountCreatePayload";
  discount?: Maybe<DiscountAmount>;
};

/** Input attributes for a discount */
export type DiscountAmountInput = {
  activeFrom?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  activeTo?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  amountValue: MoneyInput;
  code: Scalars["String"]["input"];
  conditionOnQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  conditionPrice?: InputMaybe<MoneyInput>;
  conditionType: DiscountCondition;
  limitPerUser?: InputMaybe<Scalars["Int"]["input"]>;
  limitTotal?: InputMaybe<Scalars["Int"]["input"]>;
  salesChannels?: InputMaybe<Array<SalesChannels>>;
  status: DiscountStatus;
};

/** Autogenerated return type of DiscountAmountUpdate. */
export type DiscountAmountUpdatePayload = {
  __typename?: "DiscountAmountUpdatePayload";
  discount?: Maybe<DiscountAmount>;
};

export enum DiscountCondition {
  None = "NONE",
  TotalItems = "TOTAL_ITEMS",
  TotalPrice = "TOTAL_PRICE",
}

/** Autogenerated return type of DiscountDelete. */
export type DiscountDeletePayload = {
  __typename?: "DiscountDeletePayload";
  discount?: Maybe<DiscountInterface>;
};

export enum DiscountDisplayStatus {
  Active = "ACTIVE",
  Expired = "EXPIRED",
  Scheduled = "SCHEDULED",
}

export type DiscountInterface = {
  activeFrom?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  activeTo?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  code: Scalars["String"]["output"];
  conditionOnQuantity?: Maybe<Scalars["Int"]["output"]>;
  conditionPrice?: Maybe<Money>;
  conditionType: DiscountCondition;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  displayStatus: DiscountDisplayStatus;
  id: Scalars["ID"]["output"];
  kind: DiscountKind;
  limitPerUser?: Maybe<Scalars["Int"]["output"]>;
  limitTotal?: Maybe<Scalars["Int"]["output"]>;
  salesChannels: Array<SalesChannels>;
  status: DiscountStatus;
};

export enum DiscountKind {
  DiscountAmount = "DISCOUNT_AMOUNT",
  DiscountPercentage = "DISCOUNT_PERCENTAGE",
}

export type DiscountPercentage = DiscountInterface & {
  __typename?: "DiscountPercentage";
  activeFrom?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  activeTo?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  amountPercentage: Scalars["Float"]["output"];
  code: Scalars["String"]["output"];
  conditionOnQuantity?: Maybe<Scalars["Int"]["output"]>;
  conditionPrice?: Maybe<Money>;
  conditionType: DiscountCondition;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  displayStatus: DiscountDisplayStatus;
  id: Scalars["ID"]["output"];
  kind: DiscountKind;
  limitPerUser?: Maybe<Scalars["Int"]["output"]>;
  limitTotal?: Maybe<Scalars["Int"]["output"]>;
  salesChannels: Array<SalesChannels>;
  status: DiscountStatus;
};

/** Autogenerated return type of DiscountPercentageCreate. */
export type DiscountPercentageCreatePayload = {
  __typename?: "DiscountPercentageCreatePayload";
  discount?: Maybe<DiscountPercentage>;
};

/** Input attributes for a discount */
export type DiscountPercentageInput = {
  activeFrom?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  activeTo?: InputMaybe<Scalars["ISO8601DateTime"]["input"]>;
  amountPercentage: Scalars["Float"]["input"];
  code: Scalars["String"]["input"];
  conditionOnQuantity?: InputMaybe<Scalars["Int"]["input"]>;
  conditionPrice?: InputMaybe<MoneyInput>;
  conditionType: DiscountCondition;
  limitPerUser?: InputMaybe<Scalars["Int"]["input"]>;
  limitTotal?: InputMaybe<Scalars["Int"]["input"]>;
  salesChannels?: InputMaybe<Array<SalesChannels>>;
  status: DiscountStatus;
};

/** Autogenerated return type of DiscountPercentageUpdate. */
export type DiscountPercentageUpdatePayload = {
  __typename?: "DiscountPercentageUpdatePayload";
  discount?: Maybe<DiscountPercentage>;
};

export enum DiscountStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
}

export type DiscountUsage = {
  __typename?: "DiscountUsage";
  code: Scalars["String"]["output"];
  discountPercentage?: Maybe<Scalars["Float"]["output"]>;
  netAmount: Money;
};

export enum ExciseDestinationKind {
  Consignee = "CONSIGNEE",
  Warehouse = "WAREHOUSE",
}

export enum ExportFileTypes {
  Csv = "CSV",
  Xlsx = "XLSX",
}

export type ExternalReference = {
  __typename?: "ExternalReference";
  city: Scalars["String"]["output"];
  externalId: Scalars["String"]["output"];
  kind?: Maybe<Scalars["String"]["output"]>;
  line1: Scalars["String"]["output"];
  line2?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
  system: ExternalSystem;
};

export enum ExternalSystem {
  Nbs = "NBS",
  Tracezilla = "TRACEZILLA",
}

export type Extra = ProductInterface & {
  __typename?: "Extra";
  allergens: Array<Allergen>;
  bemakersSku: Scalars["String"]["output"];
  brand?: Maybe<Brand>;
  caseQuantityRetail: Scalars["Int"]["output"];
  caseQuantityWholesale: Scalars["Int"]["output"];
  cnCode?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  /** @deprecated removed */
  description?: Maybe<Scalars["String"]["output"]>;
  extraKind?: Maybe<ExtraKind>;
  grossWeight?: Maybe<Scalars["Float"]["output"]>;
  gtin?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  images: Array<Image>;
  kind: ProductKind;
  minOrderQuantityRetail: Scalars["Int"]["output"];
  minOrderQuantityWholesale: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  ogImageUrl?: Maybe<Scalars["String"]["output"]>;
  organic: Scalars["Boolean"]["output"];
  physicalProduct: Scalars["Boolean"]["output"];
  prepacked: Scalars["Boolean"]["output"];
  productPrices: Array<ProductPrice>;
  shopCountries: Array<Scalars["String"]["output"]>;
  shopGlobalUrl?: Maybe<Scalars["String"]["output"]>;
  sku: Scalars["String"]["output"];
  status: ProductStatus;
  stocks: Array<Stock>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for Extra. */
export type ExtraConnection = {
  __typename?: "ExtraConnection";
  /** A list of nodes. */
  nodes: Array<Extra>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ExtraCreate. */
export type ExtraCreatePayload = {
  __typename?: "ExtraCreatePayload";
  extra?: Maybe<Extra>;
};

/** You can filter extras by these fields */
export type ExtraFilter = {
  physicalProduct?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  shopCountries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Input attributes for creating or updating an extra product */
export type ExtraInput = {
  allergens?: InputMaybe<Array<AllergenCode>>;
  brandId?: InputMaybe<Scalars["ID"]["input"]>;
  caseQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  caseQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  cnCode?: InputMaybe<Scalars["String"]["input"]>;
  /** @deprecated removed */
  description?: InputMaybe<Scalars["String"]["input"]>;
  extraKind?: InputMaybe<ExtraKind>;
  grossWeight?: InputMaybe<Scalars["Float"]["input"]>;
  images?: InputMaybe<Array<ImageInput>>;
  minOrderQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  minOrderQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  ogImageFile?: InputMaybe<Scalars["Upload"]["input"]>;
  organic?: InputMaybe<Scalars["Boolean"]["input"]>;
  physicalProduct: Scalars["Boolean"]["input"];
  productPrices: Array<ProductPriceInput>;
  sku: Scalars["String"]["input"];
  stocks?: InputMaybe<Array<StockInput>>;
};

export enum ExtraKind {
  Book = "BOOK",
  Clothing = "CLOTHING",
  Food = "FOOD",
  Other = "OTHER",
}

export enum ExtraSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  Sku = "SKU",
}

export type ExtraSorting = {
  direction: SortDirection;
  field: ExtraSortField;
};

/** Autogenerated return type of ExtraUpdate. */
export type ExtraUpdatePayload = {
  __typename?: "ExtraUpdatePayload";
  extra?: Maybe<Extra>;
};

export enum Filter {
  And = "AND",
  Or = "OR",
}

/** Finance overview for a Maker, including balance, sales and outstanding payment overviews */
export type FinanceOverview = {
  __typename?: "FinanceOverview";
  balanceOverview?: Maybe<BalanceOverview>;
  outstandingPayments?: Maybe<OutstandingPayments>;
  salesOverview?: Maybe<SalesOverview>;
};

/** Filtering attributes for finance overview for a Maker, including balance, sales and outstanding payment overviews */
export type FinanceOverviewFilter = {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

export type FindServicePointInput = {
  city?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["String"]["input"];
  line1?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
};

export type FulfillmentRule = {
  __typename?: "FulfillmentRule";
  destinationCountry: Scalars["String"]["output"];
  orderKind: OrderKind;
  orderSource: OrderSource;
  warehouse: Warehouse;
};

export type Image = {
  __typename?: "Image";
  alt?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  srcUrl: Scalars["String"]["output"];
};

export type ImageInput = {
  alt?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  srcFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export enum IntegrationCredentialKind {
  Tracezilla = "TRACEZILLA",
}

export type Invitation = {
  __typename?: "Invitation";
  acceptedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  canceledAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  constraintKind: MembershipConstraintKind;
  countryConstraints: Array<Scalars["String"]["output"]>;
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  invitedBy?: Maybe<User>;
  kind: InvitationKind;
  name?: Maybe<Scalars["String"]["output"]>;
  role: MembershipRole;
  sentAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  status: InvitationStatus;
};

/** Autogenerated return type of InvitationCreate. */
export type InvitationCreatePayload = {
  __typename?: "InvitationCreatePayload";
  invitation?: Maybe<Invitation>;
};

/** Input attribute for user invitation. */
export type InvitationInput = {
  constraintKind?: InputMaybe<MembershipConstraintKind>;
  countryConstraints?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  role?: InputMaybe<MembershipRole>;
};

export enum InvitationKind {
  Signing = "SIGNING",
  User = "USER",
}

/** Autogenerated return type of InvitationResend. */
export type InvitationResendPayload = {
  __typename?: "InvitationResendPayload";
  invitation?: Maybe<Invitation>;
};

export enum InvitationStatus {
  Accepted = "ACCEPTED",
  Canceled = "CANCELED",
  Pending = "PENDING",
}

export type InvoiceSummary = {
  __typename?: "InvoiceSummary";
  exportCreditNotesUrl: Scalars["String"]["output"];
  exportOrderInvoicesUrl: Scalars["String"]["output"];
  totalGrossSales: Money;
  totalNetSales: Money;
};

export enum LabelStockKind {
  CanRecycling = "CAN_RECYCLING",
  Deposit = "DEPOSIT",
  ImportedBy = "IMPORTED_BY",
  Pregnancy = "PREGNANCY",
  RecyclingCardboard = "RECYCLING_CARDBOARD",
}

export type Location = {
  __typename?: "Location";
  city: Scalars["String"]["output"];
  country: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  line1: Scalars["String"]["output"];
  line2?: Maybe<Scalars["String"]["output"]>;
  mapLink?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  phone?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  status: LocationStatus;
};

/** Autogenerated return type of LocationCreate. */
export type LocationCreatePayload = {
  __typename?: "LocationCreatePayload";
  location?: Maybe<Location>;
};

/** Autogenerated return type of LocationDelete. */
export type LocationDeletePayload = {
  __typename?: "LocationDeletePayload";
  location?: Maybe<Location>;
};

/** Input attributes for a location */
export type LocationInput = {
  city: Scalars["String"]["input"];
  email: Scalars["String"]["input"];
  line1: Scalars["String"]["input"];
  line2?: InputMaybe<Scalars["String"]["input"]>;
  mapLink?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  phone?: InputMaybe<Scalars["String"]["input"]>;
  postcode: Scalars["String"]["input"];
  status: LocationStatus;
};

export enum LocationStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
}

/** Autogenerated return type of LocationUpdate. */
export type LocationUpdatePayload = {
  __typename?: "LocationUpdatePayload";
  location?: Maybe<Location>;
};

export type MailingAddress = {
  __typename?: "MailingAddress";
  city: Scalars["String"]["output"];
  company?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  default: Scalars["Boolean"]["output"];
  line1: Scalars["String"]["output"];
  line2?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  postcode: Scalars["String"]["output"];
  state?: Maybe<Scalars["String"]["output"]>;
};

export type MailingAddressInput = {
  city: Scalars["String"]["input"];
  company?: InputMaybe<Scalars["String"]["input"]>;
  default?: InputMaybe<Scalars["Boolean"]["input"]>;
  line1: Scalars["String"]["input"];
  line2?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  phone: Scalars["String"]["input"];
  postcode: Scalars["String"]["input"];
  state?: InputMaybe<Scalars["String"]["input"]>;
};

export type Maker = {
  __typename?: "Maker";
  address?: Maybe<Address>;
  bankAccounts?: Maybe<Array<BankAccount>>;
  chargeMethods?: Maybe<Array<ChargeMethod>>;
  contactEmail?: Maybe<Scalars["String"]["output"]>;
  contactName?: Maybe<Scalars["String"]["output"]>;
  contactPhone?: Maybe<Scalars["String"]["output"]>;
  contractUrl?: Maybe<Scalars["String"]["output"]>;
  country: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  ecoCertificate?: Maybe<MakerCertificate>;
  exciseNumber?: Maybe<Scalars["String"]["output"]>;
  faviconUrl?: Maybe<Scalars["String"]["output"]>;
  features: Array<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  invoiceEmail?: Maybe<Scalars["String"]["output"]>;
  logoUrl?: Maybe<Scalars["String"]["output"]>;
  markets?: Maybe<Array<MakerMarket>>;
  name: Scalars["String"]["output"];
  printLogoUrl?: Maybe<Scalars["String"]["output"]>;
  taxId?: Maybe<Scalars["String"]["output"]>;
  termsToAccept: Array<Terms>;
  termsToUpdate: Array<Terms>;
  trade: Scalars["Boolean"]["output"];
  tradeName?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of MakerAcceptTerms. */
export type MakerAcceptTermsPayload = {
  __typename?: "MakerAcceptTermsPayload";
  maker?: Maybe<Maker>;
};

export type MakerCertificate = {
  __typename?: "MakerCertificate";
  activeTo: Scalars["ISO8601Date"]["output"];
  documentUrl: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  kind: MakerCertificateKind;
  name: Scalars["String"]["output"];
};

/** Input attributes for creating or updating a certificate */
export type MakerCertificateInput = {
  activeTo: Scalars["ISO8601Date"]["input"];
  documentFile: Scalars["Upload"]["input"];
  kind: MakerCertificateKind;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MakerCertificateKind {
  Eco = "ECO",
}

/** Autogenerated return type of MakerCreate. */
export type MakerCreatePayload = {
  __typename?: "MakerCreatePayload";
  maker?: Maybe<Maker>;
};

/** Input attributes for creating or updating a maker */
export type MakerInput = {
  address?: InputMaybe<AddressInput>;
  contactEmail?: InputMaybe<Scalars["String"]["input"]>;
  contactName?: InputMaybe<Scalars["String"]["input"]>;
  contactPhone?: InputMaybe<Scalars["String"]["input"]>;
  country: Scalars["String"]["input"];
  ecoCertificate?: InputMaybe<MakerCertificateInput>;
  exciseNumber?: InputMaybe<Scalars["String"]["input"]>;
  faviconFile?: InputMaybe<Scalars["Upload"]["input"]>;
  invoiceEmail?: InputMaybe<Scalars["String"]["input"]>;
  logoFile?: InputMaybe<Scalars["Upload"]["input"]>;
  name: Scalars["String"]["input"];
  printLogoFile?: InputMaybe<Scalars["Upload"]["input"]>;
  taxId?: InputMaybe<Scalars["String"]["input"]>;
  tradeName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MakerMarket = {
  __typename?: "MakerMarket";
  country: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  kind: MakerMarketKind;
};

export enum MakerMarketKind {
  B2B = "B2B",
  B2C = "B2C",
}

/** Autogenerated return type of MakerUpdate. */
export type MakerUpdatePayload = {
  __typename?: "MakerUpdatePayload";
  maker?: Maybe<Maker>;
};

/** Autogenerated return type of MakerUpdateTerms. */
export type MakerUpdateTermsPayload = {
  __typename?: "MakerUpdateTermsPayload";
  maker?: Maybe<Maker>;
};

export type Membership = {
  __typename?: "Membership";
  constraintKind: MembershipConstraintKind;
  countryConstraints: Array<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  role: MembershipRole;
  user: User;
};

export enum MembershipConstraintKind {
  Countries = "COUNTRIES",
  None = "NONE",
}

export type MembershipInput = {
  constraintKind?: InputMaybe<MembershipConstraintKind>;
  countryConstraints?: InputMaybe<Array<Scalars["String"]["input"]>>;
  role: MembershipRole;
};

export enum MembershipRole {
  Owner = "OWNER",
  Sales = "SALES",
}

/** Autogenerated return type of MembershipUpdate. */
export type MembershipUpdatePayload = {
  __typename?: "MembershipUpdatePayload";
  membership?: Maybe<Membership>;
};

export type Money = {
  __typename?: "Money";
  amount: Scalars["Decimal"]["output"];
  currency: Scalars["String"]["output"];
};

/** Specifies the fields for a monetary value with currency. */
export type MoneyInput = {
  amount: Scalars["Decimal"]["input"];
  currency: Scalars["String"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  announcementCreate?: Maybe<AnnouncementCreatePayload>;
  announcementDelete?: Maybe<AnnouncementDeletePayload>;
  announcementUpdate?: Maybe<AnnouncementUpdatePayload>;
  bankAccountCreate?: Maybe<BankAccountCreatePayload>;
  bankAccountUpdate?: Maybe<BankAccountUpdatePayload>;
  brandCreate?: Maybe<BrandCreatePayload>;
  brandUpdate?: Maybe<BrandUpdatePayload>;
  businessMemberCreate?: Maybe<BusinessMemberCreatePayload>;
  businessMemberDelete?: Maybe<BusinessMemberDeletePayload>;
  businessMemberResend?: Maybe<BusinessMemberResendPayload>;
  businessMemberUpdate?: Maybe<BusinessMemberUpdatePayload>;
  /** Setup a Stripe Checkout Session to setup a Charge Method for a Maker */
  chargeMethodSetup?: Maybe<ChargeMethodSetupPayload>;
  collectionCreate?: Maybe<CollectionCreatePayload>;
  collectionDelete?: Maybe<CollectionDeletePayload>;
  collectionReorder?: Maybe<CollectionReorderPayload>;
  collectionUpdate?: Maybe<CollectionUpdatePayload>;
  collectionUpdateMeta?: Maybe<CollectionUpdateMetaPayload>;
  createBeverage?: Maybe<CreateBeveragePayload>;
  createProductBundle?: Maybe<CreateProductBundlePayload>;
  customerCreate?: Maybe<CustomerCreatePayload>;
  customerUpdate?: Maybe<CustomerUpdatePayload>;
  deleteProduct?: Maybe<DeleteProductPayload>;
  discountAmountCreate?: Maybe<DiscountAmountCreatePayload>;
  discountAmountUpdate?: Maybe<DiscountAmountUpdatePayload>;
  discountDelete?: Maybe<DiscountDeletePayload>;
  discountPercentageCreate?: Maybe<DiscountPercentageCreatePayload>;
  discountPercentageUpdate?: Maybe<DiscountPercentageUpdatePayload>;
  extraCreate?: Maybe<ExtraCreatePayload>;
  extraUpdate?: Maybe<ExtraUpdatePayload>;
  invitationCreate?: Maybe<InvitationCreatePayload>;
  invitationResend?: Maybe<InvitationResendPayload>;
  locationCreate?: Maybe<LocationCreatePayload>;
  locationDelete?: Maybe<LocationDeletePayload>;
  locationUpdate?: Maybe<LocationUpdatePayload>;
  makerAcceptTerms?: Maybe<MakerAcceptTermsPayload>;
  makerCreate?: Maybe<MakerCreatePayload>;
  makerUpdate?: Maybe<MakerUpdatePayload>;
  makerUpdateTerms?: Maybe<MakerUpdateTermsPayload>;
  membershipUpdate?: Maybe<MembershipUpdatePayload>;
  orderCreate?: Maybe<OrderCreatePayload>;
  orderDelete?: Maybe<OrderDeletePayload>;
  orderMarkAwaitingPickup?: Maybe<OrderMarkAwaitingPickupPayload>;
  orderMarkDelivered?: Maybe<OrderMarkDeliveredPayload>;
  orderShipped?: Maybe<OrderShippedPayload>;
  orderSubmit?: Maybe<OrderSubmitPayload>;
  orderUpdate?: Maybe<OrderUpdatePayload>;
  pickingListConfirm?: Maybe<PickingListConfirmPayload>;
  pricingGroupAssign?: Maybe<PricingGroupAssignPayload>;
  pricingGroupCreate?: Maybe<PricingGroupCreatePayload>;
  pricingGroupDelete?: Maybe<PricingGroupDeletePayload>;
  pricingGroupUpdate?: Maybe<PricingGroupUpdatePayload>;
  shippingZoneUpdate?: Maybe<ShippingZoneUpdatePayload>;
  shopBannerCreate?: Maybe<ShopBannerCreatePayload>;
  shopBannerDelete?: Maybe<ShopBannerDeletePayload>;
  shopBannerUpdate?: Maybe<ShopBannerUpdatePayload>;
  shopCopyProducts?: Maybe<ShopCopyProductsPayload>;
  shopCreate?: Maybe<ShopCreatePayload>;
  shopDelete?: Maybe<ShopDeletePayload>;
  shopProductCreate?: Maybe<ShopProductCreatePayload>;
  shopProductDelete?: Maybe<ShopProductDeletePayload>;
  shopProductUpdate?: Maybe<ShopProductUpdatePayload>;
  shopProductUpdateMeta?: Maybe<ShopProductUpdateMetaPayload>;
  shopUpdate?: Maybe<ShopUpdatePayload>;
  signingComplete?: Maybe<SigningCompletePayload>;
  stockMovementCreate?: Maybe<StockMovementCreatePayload>;
  stockMovementDelete?: Maybe<StockMovementDeletePayload>;
  stockMovementSubmit?: Maybe<StockMovementSubmitPayload>;
  stockMovementUpdate?: Maybe<StockMovementUpdatePayload>;
  storeUpdate?: Maybe<StoreUpdatePayload>;
  tracezillaConfirm?: Maybe<TracezillaConfirmPayload>;
  updateBeverage?: Maybe<UpdateBeveragePayload>;
  updateProductBundle?: Maybe<UpdateProductBundlePayload>;
  updateProductPrices?: Maybe<UpdateProductPricesPayload>;
  warehouseUpdate?: Maybe<WarehouseUpdatePayload>;
};

export type MutationAnnouncementCreateArgs = {
  input: AnnouncementInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationAnnouncementDeleteArgs = {
  announcementId: Scalars["ID"]["input"];
};

export type MutationAnnouncementUpdateArgs = {
  announcementId: Scalars["ID"]["input"];
  input: AnnouncementInput;
};

export type MutationBankAccountCreateArgs = {
  input: BankAccountInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationBankAccountUpdateArgs = {
  bankAccountId: Scalars["ID"]["input"];
  input: BankAccountInput;
};

export type MutationBrandCreateArgs = {
  input: BrandInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationBrandUpdateArgs = {
  brandId: Scalars["ID"]["input"];
  input: BrandInput;
};

export type MutationBusinessMemberCreateArgs = {
  customerId: Scalars["ID"]["input"];
  input: BusinessMemberInput;
};

export type MutationBusinessMemberDeleteArgs = {
  businessMemberId: Scalars["ID"]["input"];
};

export type MutationBusinessMemberResendArgs = {
  businessMemberId: Scalars["ID"]["input"];
};

export type MutationBusinessMemberUpdateArgs = {
  businessMemberId: Scalars["ID"]["input"];
  input: BusinessMemberInput;
};

export type MutationChargeMethodSetupArgs = {
  input: ChargeMethodSetupInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationCollectionCreateArgs = {
  input: CollectionInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationCollectionDeleteArgs = {
  collectionId: Scalars["ID"]["input"];
};

export type MutationCollectionReorderArgs = {
  input: Array<CollectionReorderInput>;
  shopId: Scalars["ID"]["input"];
};

export type MutationCollectionUpdateArgs = {
  collectionId: Scalars["ID"]["input"];
  input: CollectionInput;
};

export type MutationCollectionUpdateMetaArgs = {
  collectionId: Scalars["ID"]["input"];
  input: CollectionMetaInput;
};

export type MutationCreateBeverageArgs = {
  input: BeverageInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationCreateProductBundleArgs = {
  input: ProductBundleInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationCustomerCreateArgs = {
  input: CustomerInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationCustomerUpdateArgs = {
  customerId: Scalars["ID"]["input"];
  input: CustomerInput;
};

export type MutationDeleteProductArgs = {
  productId: Scalars["ID"]["input"];
};

export type MutationDiscountAmountCreateArgs = {
  input: DiscountAmountInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationDiscountAmountUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: DiscountAmountInput;
};

export type MutationDiscountDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDiscountPercentageCreateArgs = {
  input: DiscountPercentageInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationDiscountPercentageUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: DiscountPercentageInput;
};

export type MutationExtraCreateArgs = {
  input: ExtraInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationExtraUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: ExtraInput;
};

export type MutationInvitationCreateArgs = {
  input: InvitationInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationInvitationResendArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationLocationCreateArgs = {
  input: LocationInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationLocationDeleteArgs = {
  locationId: Scalars["ID"]["input"];
};

export type MutationLocationUpdateArgs = {
  input: LocationInput;
  locationId: Scalars["ID"]["input"];
};

export type MutationMakerAcceptTermsArgs = {
  id: Scalars["ID"]["input"];
  input: AcceptTermsInput;
};

export type MutationMakerCreateArgs = {
  input: MakerInput;
};

export type MutationMakerUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: MakerInput;
};

export type MutationMakerUpdateTermsArgs = {
  id: Scalars["ID"]["input"];
  input: TermsInput;
};

export type MutationMembershipUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: MembershipInput;
};

export type MutationOrderCreateArgs = {
  input: OrderInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationOrderDeleteArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationOrderMarkAwaitingPickupArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationOrderMarkDeliveredArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationOrderShippedArgs = {
  carrier: Scalars["String"]["input"];
  itemId: Scalars["String"]["input"];
  orderId: Scalars["ID"]["input"];
};

export type MutationOrderSubmitArgs = {
  orderId: Scalars["ID"]["input"];
};

export type MutationOrderUpdateArgs = {
  input: OrderInput;
  orderId: Scalars["ID"]["input"];
};

export type MutationPickingListConfirmArgs = {
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  orderItems: Array<PickingListItemInput>;
  signedId?: InputMaybe<Scalars["String"]["input"]>;
  trackingUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationPricingGroupAssignArgs = {
  customerId: Scalars["ID"]["input"];
  pricingGroupId: Scalars["ID"]["input"];
};

export type MutationPricingGroupCreateArgs = {
  input: PricingGroupInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationPricingGroupDeleteArgs = {
  pricingGroupId: Scalars["ID"]["input"];
};

export type MutationPricingGroupUpdateArgs = {
  input: PricingGroupInput;
  pricingGroupId: Scalars["ID"]["input"];
};

export type MutationShippingZoneUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: ShippingZoneInput;
};

export type MutationShopBannerCreateArgs = {
  input: ShopBannerInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationShopBannerDeleteArgs = {
  shopBannerId: Scalars["ID"]["input"];
};

export type MutationShopBannerUpdateArgs = {
  input: ShopBannerInput;
  shopBannerId: Scalars["ID"]["input"];
};

export type MutationShopCopyProductsArgs = {
  input: ShopCopyProductsInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationShopCreateArgs = {
  input: ShopInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationShopDeleteArgs = {
  shopId: Scalars["ID"]["input"];
};

export type MutationShopProductCreateArgs = {
  input: ShopProductInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationShopProductDeleteArgs = {
  shopProductId: Scalars["ID"]["input"];
};

export type MutationShopProductUpdateArgs = {
  input: ShopProductInput;
  shopProductId: Scalars["ID"]["input"];
};

export type MutationShopProductUpdateMetaArgs = {
  input: ShopProductMetaInput;
  shopProductId: Scalars["ID"]["input"];
};

export type MutationShopUpdateArgs = {
  input: ShopInput;
  shopId: Scalars["ID"]["input"];
};

export type MutationSigningCompleteArgs = {
  makerId: Scalars["ID"]["input"];
};

export type MutationStockMovementCreateArgs = {
  input: StockMovementInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationStockMovementDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStockMovementSubmitArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationStockMovementUpdateArgs = {
  id: Scalars["ID"]["input"];
  input: StockMovementInput;
};

export type MutationStoreUpdateArgs = {
  input: StoreInput;
  makerId: Scalars["ID"]["input"];
};

export type MutationTracezillaConfirmArgs = {
  tracezillaId?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateBeverageArgs = {
  input: BeverageInput;
  productId: Scalars["ID"]["input"];
};

export type MutationUpdateProductBundleArgs = {
  bundleId: Scalars["ID"]["input"];
  input: ProductBundleInput;
};

export type MutationUpdateProductPricesArgs = {
  input: UpdateProductPricesInput;
  productId: Scalars["ID"]["input"];
};

export type MutationWarehouseUpdateArgs = {
  input: WarehouseInput;
  warehouseId: Scalars["ID"]["input"];
};

export type Onboarding = {
  __typename?: "Onboarding";
  chargeMethods: Array<ChargeMethod>;
  contractUrl?: Maybe<Scalars["String"]["output"]>;
  termsToAccept: Array<Terms>;
  termsToUpdate: Array<Terms>;
};

export type OpeningHours = {
  __typename?: "OpeningHours";
  closeTime?: Maybe<Scalars["String"]["output"]>;
  day: Scalars["String"]["output"];
  openTime?: Maybe<Scalars["String"]["output"]>;
};

export type Order = {
  __typename?: "Order";
  attentionNote?: Maybe<Scalars["String"]["output"]>;
  billingAddress?: Maybe<BillingAddress>;
  carrierOptions?: Maybe<CarrierOptions>;
  combinedDocsUrl?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  createdBy?: Maybe<CreatedBy>;
  currency: Scalars["String"]["output"];
  customer: Customer;
  deliveredAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  deliveryAddress?: Maybe<MailingAddress>;
  deliveryMethod?: Maybe<DeliveryMethod>;
  destinationCountry: Scalars["String"]["output"];
  discountUsages?: Maybe<Array<DiscountUsage>>;
  eadDocUrl?: Maybe<Scalars["String"]["output"]>;
  externalReferences?: Maybe<Array<ExternalReference>>;
  id: Scalars["ID"]["output"];
  internalNote?: Maybe<Scalars["String"]["output"]>;
  invoiceDocUrl?: Maybe<Scalars["String"]["output"]>;
  kind: OrderKind;
  location?: Maybe<Location>;
  netTotal?: Maybe<Money>;
  number: Scalars["String"]["output"];
  orderItems?: Maybe<Array<OrderItem>>;
  orderLabels?: Maybe<Array<OrderLabel>>;
  overdueDays?: Maybe<Scalars["Int"]["output"]>;
  packagingDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  paidAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentStatus: OrderPaymentStatus;
  proformaInvoiceDocUrl?: Maybe<Scalars["String"]["output"]>;
  registeredAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  sadDocUrl?: Maybe<Scalars["String"]["output"]>;
  settledAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  shippedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  shippingAddress?: Maybe<MailingAddress>;
  shippingCarrier?: Maybe<CarrierKind>;
  shippingDocUrl?: Maybe<Scalars["String"]["output"]>;
  shippingId?: Maybe<Scalars["String"]["output"]>;
  shippingLine?: Maybe<ShippingLine>;
  shippingPackage?: Maybe<ShippingPackage>;
  shippingServicePoint?: Maybe<ServicePoint>;
  shippingTrackingUrl?: Maybe<Scalars["String"]["output"]>;
  shippingWeight?: Maybe<Scalars["Float"]["output"]>;
  source: OrderSource;
  status: OrderStatus;
  stockOptions: Array<StockOption>;
  submittedAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  tags: Array<Scalars["String"]["output"]>;
  verificationFailed: Scalars["Boolean"]["output"];
  warehouse?: Maybe<Warehouse>;
  waybillDocUrl?: Maybe<Scalars["String"]["output"]>;
  writtenOffAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
};

/** The connection type for Order. */
export type OrderConnection = {
  __typename?: "OrderConnection";
  /** A list of nodes. */
  nodes: Array<Order>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of OrderCreate. */
export type OrderCreatePayload = {
  __typename?: "OrderCreatePayload";
  order?: Maybe<Order>;
};

/** Autogenerated return type of OrderDelete. */
export type OrderDeletePayload = {
  __typename?: "OrderDeletePayload";
  order?: Maybe<Order>;
};

/** You can filter orders by these fields */
export type OrderFilter = {
  country?: InputMaybe<Array<Scalars["String"]["input"]>>;
  deliveryMethod?: InputMaybe<Array<DeliveryMethod>>;
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  kind?: InputMaybe<Array<OrderKind>>;
  packagingDateFrom?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  packagingDateTo?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  paymentStatus?: InputMaybe<Array<OrderPaymentStatus>>;
  registeredAtFrom?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  registeredAtTo?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  /** search by customer name for now, more fields the future */
  search?: InputMaybe<Scalars["String"]["input"]>;
  source?: InputMaybe<Array<OrderSource>>;
  status?: InputMaybe<Array<OrderStatus>>;
  tab?: InputMaybe<OrderTab>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Specify whether all tags should be present or any of them using AND or OR. */
  tagsOperator?: InputMaybe<Filter>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

/** Input attributes for an order */
export type OrderInput = {
  attentionNote?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress?: InputMaybe<BillingAddressInput>;
  carrierOptions?: InputMaybe<CarrierOptionsInput>;
  customerId: Scalars["ID"]["input"];
  deliveryMethod?: InputMaybe<DeliveryMethod>;
  internalNote?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  orderItems?: InputMaybe<Array<OrderItemInput>>;
  packagingDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  shippingAddress?: InputMaybe<MailingAddressInput>;
  shippingCarrier?: InputMaybe<CarrierKind>;
  shippingLine?: InputMaybe<ShippingLineInput>;
  shippingPackage?: InputMaybe<ShippingPackage>;
  shippingServicePointId?: InputMaybe<Scalars["ID"]["input"]>;
  shippingWeight?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<OrderSource>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  waybillFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

export type OrderInvoiceDetails = {
  __typename?: "OrderInvoiceDetails";
  commission: Money;
  dueAt: Scalars["ISO8601DateTime"]["output"];
  exciseDuty: Money;
  grossSales: Money;
  id: Scalars["ID"]["output"];
  invoiceDocUrl?: Maybe<Scalars["String"]["output"]>;
  invoicedAt: Scalars["ISO8601DateTime"]["output"];
  makerCurrency: Scalars["String"]["output"];
  netSales: Money;
  number: Scalars["String"]["output"];
  order: Order;
  paymentProcessing: Money;
  recycling: Money;
  reverseInvoiceDocUrl?: Maybe<Scalars["String"]["output"]>;
  settlementNumber?: Maybe<Scalars["String"]["output"]>;
  shipping: Money;
};

/** The connection type for OrderInvoiceDetails. */
export type OrderInvoiceDetailsConnection = {
  __typename?: "OrderInvoiceDetailsConnection";
  /** A list of nodes. */
  nodes: Array<OrderInvoiceDetails>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Input attributes for an order */
export type OrderInvoiceSearchInput = {
  country?: InputMaybe<Array<Scalars["String"]["input"]>>;
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  kind?: InputMaybe<Array<OrderKind>>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

export enum OrderInvoiceSortField {
  GrossTotal = "GROSS_TOTAL",
  InvoicedAt = "INVOICED_AT",
  OrderId = "ORDER_ID",
}

export type OrderInvoiceSorting = {
  direction: SortDirection;
  field: OrderInvoiceSortField;
};

export type OrderItem = {
  __typename?: "OrderItem";
  batchCodes: Array<BatchCode>;
  bundledItems?: Maybe<Array<ProductBundleItem>>;
  discount?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  order: Order;
  orderLabels?: Maybe<Array<OrderLabel>>;
  price: Money;
  product: ProductInterface;
  quantity: Scalars["Int"]["output"];
  totalPrice: Money;
};

/** Input attributes for an order item */
export type OrderItemInput = {
  discount?: InputMaybe<Scalars["Float"]["input"]>;
  price: MoneyInput;
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export enum OrderKind {
  Business = "BUSINESS",
  Consumer = "CONSUMER",
  Distributor = "DISTRIBUTOR",
  Internal = "INTERNAL",
}

export type OrderLabel = {
  __typename?: "OrderLabel";
  /** Formatted kind with additional details, ready to display to the user */
  displayKind: Scalars["String"]["output"];
  kind: LabelStockKind;
  productId?: Maybe<Scalars["ID"]["output"]>;
  productName?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of OrderMarkAwaitingPickup. */
export type OrderMarkAwaitingPickupPayload = {
  __typename?: "OrderMarkAwaitingPickupPayload";
  order?: Maybe<Order>;
};

/** Autogenerated return type of OrderMarkDelivered. */
export type OrderMarkDeliveredPayload = {
  __typename?: "OrderMarkDeliveredPayload";
  order?: Maybe<Order>;
};

export enum OrderPaymentStatus {
  Canceled = "CANCELED",
  Overdue = "OVERDUE",
  Paid = "PAID",
  Settled = "SETTLED",
  Unpaid = "UNPAID",
  WrittenOff = "WRITTEN_OFF",
}

/** Autogenerated return type of OrderShipped. */
export type OrderShippedPayload = {
  __typename?: "OrderShippedPayload";
  order?: Maybe<Order>;
};

export enum OrderSortField {
  CreatedAt = "CREATED_AT",
  NetTotal = "NET_TOTAL",
  OrderStatusAndRegisteredAt = "ORDER_STATUS_AND_REGISTERED_AT",
  PackagingDate = "PACKAGING_DATE",
  PaymentStatus = "PAYMENT_STATUS",
  RegisteredAt = "REGISTERED_AT",
  Status = "STATUS",
  SubmittedAt = "SUBMITTED_AT",
}

export type OrderSorting = {
  direction: SortDirection;
  field: OrderSortField;
};

export enum OrderSource {
  Amazon = "AMAZON",
  Faire = "FAIRE",
  Platform = "PLATFORM",
  Shopify = "SHOPIFY",
  Storefront = "STOREFRONT",
  Systembolaget = "SYSTEMBOLAGET",
  Trade = "TRADE",
  Vinmonopolet = "VINMONOPOLET",
}

export enum OrderStatus {
  AwaitingPayment = "AWAITING_PAYMENT",
  AwaitingPickup = "AWAITING_PICKUP",
  AwaitingRegistration = "AWAITING_REGISTRATION",
  AwaitingVerification = "AWAITING_VERIFICATION",
  Canceled = "CANCELED",
  Confirmed = "CONFIRMED",
  Delayed = "DELAYED",
  Delivered = "DELIVERED",
  Draft = "DRAFT",
  ExpectedDelay = "EXPECTED_DELAY",
  Registered = "REGISTERED",
  Returned = "RETURNED",
  Shipped = "SHIPPED",
  Stuck = "STUCK",
  Submitted = "SUBMITTED",
  Upcoming = "UPCOMING",
}

/** Autogenerated return type of OrderSubmit. */
export type OrderSubmitPayload = {
  __typename?: "OrderSubmitPayload";
  order?: Maybe<Order>;
};

export enum OrderTab {
  Closed = "CLOSED",
  New = "NEW",
  Open = "OPEN",
  Upcoming = "UPCOMING",
}

export type OrderTabsMetadata = {
  __typename?: "OrderTabsMetadata";
  allTabCount: Scalars["Int"]["output"];
  closedTabCount: Scalars["Int"]["output"];
  newTabCount: Scalars["Int"]["output"];
  openTabCount: Scalars["Int"]["output"];
  upcomingTabCount: Scalars["Int"]["output"];
};

/** Autogenerated return type of OrderUpdate. */
export type OrderUpdatePayload = {
  __typename?: "OrderUpdatePayload";
  order?: Maybe<Order>;
};

/** Input attributes for an order */
export type OrderWithIdInput = {
  attentionNote?: InputMaybe<Scalars["String"]["input"]>;
  billingAddress?: InputMaybe<BillingAddressInput>;
  carrierOptions?: InputMaybe<CarrierOptionsInput>;
  customerId: Scalars["ID"]["input"];
  deliveryMethod?: InputMaybe<DeliveryMethod>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  internalNote?: InputMaybe<Scalars["String"]["input"]>;
  locationId?: InputMaybe<Scalars["ID"]["input"]>;
  orderItems?: InputMaybe<Array<OrderItemInput>>;
  packagingDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  shippingAddress?: InputMaybe<MailingAddressInput>;
  shippingCarrier?: InputMaybe<CarrierKind>;
  shippingLine?: InputMaybe<ShippingLineInput>;
  shippingPackage?: InputMaybe<ShippingPackage>;
  shippingServicePointId?: InputMaybe<Scalars["ID"]["input"]>;
  shippingWeight?: InputMaybe<Scalars["Float"]["input"]>;
  source?: InputMaybe<OrderSource>;
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  waybillFile?: InputMaybe<Scalars["Upload"]["input"]>;
};

/** Outstanding payments overview for a Maker */
export type OutstandingPayments = {
  __typename?: "OutstandingPayments";
  outstandingAmount: Money;
  overdueAmount: Money;
  overdueCount: Scalars["Int"]["output"];
  unpaidAmount: Money;
  unpaidCount: Scalars["Int"]["output"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export enum PaymentMethod {
  Capture = "CAPTURE",
  External = "EXTERNAL",
  Invoice = "INVOICE",
  Upfront = "UPFRONT",
}

export enum PaymentMethodsMode {
  Automatic = "AUTOMATIC",
  Custom = "CUSTOM",
}

export type PickingList = {
  __typename?: "PickingList";
  contactEmail: Scalars["String"]["output"];
  deliveryEmail?: Maybe<Scalars["String"]["output"]>;
  fulfillmentEmail?: Maybe<Scalars["String"]["output"]>;
  order: Order;
  pickupType?: Maybe<Scalars["String"]["output"]>;
  useBemakersSku: Scalars["Boolean"]["output"];
};

/** Autogenerated return type of PickingListConfirm. */
export type PickingListConfirmPayload = {
  __typename?: "PickingListConfirmPayload";
  pickingList?: Maybe<PickingList>;
};

/** Input attributes for picking list confirmation */
export type PickingListItemInput = {
  batchCodes: Array<BatchCodeInput>;
  id: Scalars["String"]["input"];
};

export type Plan = {
  __typename?: "Plan";
  activeFrom: Scalars["ISO8601DateTime"]["output"];
  contractUrl?: Maybe<Scalars["String"]["output"]>;
  kind: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  terms: Array<Terms>;
};

export enum PremixKind {
  AlcoholPlus = "ALCOHOL_PLUS",
  /** @deprecated Use ALCOHOL_PLUS instead */
  BeerWinePlus = "BEER_WINE_PLUS",
  /**
   * A seltzer product
   * @deprecated Use ALCOHOL_PLUS instead
   */
  Seltzer = "SELTZER",
  /** @deprecated Use ALCOHOL_PLUS instead */
  SpiritPlus = "SPIRIT_PLUS",
  /**
   * A sugary product
   * @deprecated Use ALCOHOL_PLUS instead
   */
  Sugary = "SUGARY",
}

export type PricingCalculation = {
  __typename?: "PricingCalculation";
  breakdown: Array<Array<PricingCalculationItem>>;
  exchangeRate?: Maybe<Scalars["Float"]["output"]>;
  summary: Array<PricingCalculationItem>;
};

export type PricingCalculationItem = {
  __typename?: "PricingCalculationItem";
  kind: Scalars["String"]["output"];
  percentage?: Maybe<Scalars["Float"]["output"]>;
  priceCustomer: Money;
  priceMaker: Money;
  style: PricingCalculatorItemStyle;
  text?: Maybe<Scalars["String"]["output"]>;
};

export enum PricingCalculatorChannel {
  Amazon = "AMAZON",
  Faire = "FAIRE",
  Platform = "PLATFORM",
  Shopify = "SHOPIFY",
  Storefront = "STOREFRONT",
  Systembolaget = "SYSTEMBOLAGET",
  Trade = "TRADE",
  Vinmonopolet = "VINMONOPOLET",
}

/** Specifies the fields for a price calculator input. */
export type PricingCalculatorInput = {
  alcoholPercent: Scalars["Float"]["input"];
  channel: PricingCalculatorChannel;
  customerPaysShipping?: InputMaybe<Scalars["Boolean"]["input"]>;
  degreePlato?: InputMaybe<Scalars["Float"]["input"]>;
  destinationCountry: Scalars["String"]["input"];
  destinationPostcode?: InputMaybe<Scalars["String"]["input"]>;
  exciseProductCode: Scalars["String"]["input"];
  grossWeight: Scalars["Float"]["input"];
  makerCountry?: InputMaybe<Scalars["String"]["input"]>;
  makerCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mode: PricingCalculatorMode;
  netWeight?: InputMaybe<Scalars["Float"]["input"]>;
  orderKind: PricingCalculatorOrderKind;
  originCountry?: InputMaybe<Scalars["String"]["input"]>;
  originPostcode?: InputMaybe<Scalars["String"]["input"]>;
  packagingCode: Scalars["String"]["input"];
  paymentMethod?: InputMaybe<PricingCalculatorPaymentMethod>;
  priceInput: MoneyInput;
  pricingPlan: Scalars["String"]["input"];
  quantity: Scalars["Int"]["input"];
  volume: Scalars["Float"]["input"];
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum PricingCalculatorItemStyle {
  Highlight = "HIGHLIGHT",
  Plain = "PLAIN",
  Unknown = "UNKNOWN",
}

export enum PricingCalculatorMode {
  /** Ex works price excl. VAT & Excise */
  ExWorks = "EX_WORKS",
  /** Wholesale - excl. VAT, incl. Excise duty */
  RetailPrice = "RETAIL_PRICE",
  /** Retail price - incl. VAT & Excise duty */
  ShelfPrice = "SHELF_PRICE",
}

export enum PricingCalculatorOrderKind {
  Business = "BUSINESS",
  Consumer = "CONSUMER",
  Distributor = "DISTRIBUTOR",
}

export enum PricingCalculatorPaymentMethod {
  Invoice = "INVOICE",
  Klarna = "KLARNA",
  Mobilepay = "MOBILEPAY",
  Paypal = "PAYPAL",
  Stripe = "STRIPE",
}

export type PricingCalculatorSeeds = {
  __typename?: "PricingCalculatorSeeds";
  pricingPlans: Array<PricingPlan>;
  warehouses: Array<Warehouse>;
};

export type PricingGroup = {
  __typename?: "PricingGroup";
  createdBy?: Maybe<CurrentUser>;
  customers?: Maybe<Array<Customer>>;
  customersCount?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  discount: Scalars["Float"]["output"];
  id: Scalars["ID"]["output"];
  lastActivityAt?: Maybe<Scalars["ISO8601DateTime"]["output"]>;
  name: Scalars["String"]["output"];
  priceBase: PricingGroupPriceBase;
  status: PricingGroupStatus;
};

/** Autogenerated return type of PricingGroupAssign. */
export type PricingGroupAssignPayload = {
  __typename?: "PricingGroupAssignPayload";
  pricingGroup?: Maybe<PricingGroup>;
};

/** Autogenerated return type of PricingGroupCreate. */
export type PricingGroupCreatePayload = {
  __typename?: "PricingGroupCreatePayload";
  pricingGroup?: Maybe<PricingGroup>;
};

/** Autogenerated return type of PricingGroupDelete. */
export type PricingGroupDeletePayload = {
  __typename?: "PricingGroupDeletePayload";
  pricingGroup?: Maybe<PricingGroup>;
};

/** Input attributes for a Pricing Group */
export type PricingGroupInput = {
  customerIds: Array<Scalars["ID"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  discount: Scalars["Float"]["input"];
  name: Scalars["String"]["input"];
  priceBase: PricingGroupPriceBase;
};

export enum PricingGroupPriceBase {
  Retail = "RETAIL",
  Wholesale = "WHOLESALE",
}

export enum PricingGroupStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
}

/** Autogenerated return type of PricingGroupUpdate. */
export type PricingGroupUpdatePayload = {
  __typename?: "PricingGroupUpdatePayload";
  pricingGroup?: Maybe<PricingGroup>;
};

export type PricingPlan = {
  __typename?: "PricingPlan";
  addons: Array<Addon>;
  commissionDist: Scalars["Float"]["output"];
  commissionInt: Scalars["Float"]["output"];
  commissionNat: Scalars["Float"]["output"];
  commissionTradeInt: Scalars["Float"]["output"];
  commissionTradeNat: Scalars["Float"]["output"];
  distFee: Money;
  includedCommission: Money;
  key: Scalars["String"]["output"];
  monthlyFee: Money;
  name: Scalars["String"]["output"];
  setupFee: Money;
};

export type ProductBundle = ProductInterface & {
  __typename?: "ProductBundle";
  allergens: Array<Allergen>;
  bemakersSku: Scalars["String"]["output"];
  brand?: Maybe<Brand>;
  caseQuantityRetail: Scalars["Int"]["output"];
  caseQuantityWholesale: Scalars["Int"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  /** @deprecated removed */
  description?: Maybe<Scalars["String"]["output"]>;
  grossWeight?: Maybe<Scalars["Float"]["output"]>;
  gtin?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  images: Array<Image>;
  items: Array<ProductBundleItem>;
  kind: ProductKind;
  minOrderQuantityRetail: Scalars["Int"]["output"];
  minOrderQuantityWholesale: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  ogImageUrl?: Maybe<Scalars["String"]["output"]>;
  organic: Scalars["Boolean"]["output"];
  prepacked: Scalars["Boolean"]["output"];
  productPrices: Array<ProductPrice>;
  shopCountries: Array<Scalars["String"]["output"]>;
  shopGlobalUrl?: Maybe<Scalars["String"]["output"]>;
  sku: Scalars["String"]["output"];
  status: ProductStatus;
  stocks: Array<Stock>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for ProductBundle. */
export type ProductBundleConnection = {
  __typename?: "ProductBundleConnection";
  /** A list of nodes. */
  nodes: Array<ProductBundle>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** You can filter product bundles by these fields */
export type ProductBundleFilter = {
  prepacked?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** will search by SKU and name */
  search?: InputMaybe<Scalars["String"]["input"]>;
  shopCountries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** works correctly only for prepacked bundles */
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Input attributes for creating or updating a product bundle */
export type ProductBundleInput = {
  brandId?: InputMaybe<Scalars["ID"]["input"]>;
  caseQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  caseQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  /** @deprecated removed */
  description?: InputMaybe<Scalars["String"]["input"]>;
  images?: InputMaybe<Array<ImageInput>>;
  items: Array<ProductBundleItemInput>;
  minOrderQuantityRetail?: InputMaybe<Scalars["Int"]["input"]>;
  minOrderQuantityWholesale?: InputMaybe<Scalars["Int"]["input"]>;
  name: Scalars["String"]["input"];
  ogImageFile?: InputMaybe<Scalars["Upload"]["input"]>;
  organic?: InputMaybe<Scalars["Boolean"]["input"]>;
  prepacked?: InputMaybe<Scalars["Boolean"]["input"]>;
  productPrices: Array<ProductPriceInput>;
  sku: Scalars["String"]["input"];
  stocks?: InputMaybe<Array<StockInput>>;
};

export type ProductBundleItem = {
  __typename?: "ProductBundleItem";
  bundle: ProductBundle;
  id: Scalars["ID"]["output"];
  product: ProductInterface;
  quantity: Scalars["Int"]["output"];
};

/** Input attributes for a product bundle item */
export type ProductBundleItemInput = {
  productId: Scalars["ID"]["input"];
  quantity: Scalars["Int"]["input"];
};

export enum ProductBundleSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  Sku = "SKU",
}

export type ProductBundleSorting = {
  direction: SortDirection;
  field: ProductBundleSortField;
};

export type ProductDeletionDetails = {
  __typename?: "ProductDeletionDetails";
  canBeDeleted: Scalars["Boolean"]["output"];
  deletionBlockers: Array<ProductDeletionItem>;
  relatedItems: Array<ProductDeletionItem>;
};

export type ProductDeletionItem = {
  __typename?: "ProductDeletionItem";
  kind: ProductDeletionItemKind;
  subtitle?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
};

export enum ProductDeletionItemKind {
  InStock = "IN_STOCK",
  PartOfBundle = "PART_OF_BUNDLE",
  PartOfTrade = "PART_OF_TRADE",
  ShopProduct = "SHOP_PRODUCT",
}

/** General filter for all products types */
export type ProductFilter = {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  kind?: InputMaybe<Array<ProductKind>>;
  physicalProduct?: InputMaybe<Scalars["Boolean"]["input"]>;
  prepacked?: InputMaybe<Scalars["Boolean"]["input"]>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  shopCountries?: InputMaybe<Array<Scalars["String"]["input"]>>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  /** Works only for prepacked products */
  warehouseId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ProductInterface = {
  allergens: Array<Allergen>;
  bemakersSku: Scalars["String"]["output"];
  brand?: Maybe<Brand>;
  caseQuantityRetail: Scalars["Int"]["output"];
  caseQuantityWholesale: Scalars["Int"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  /** @deprecated removed */
  description?: Maybe<Scalars["String"]["output"]>;
  grossWeight?: Maybe<Scalars["Float"]["output"]>;
  gtin?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  images: Array<Image>;
  kind: ProductKind;
  minOrderQuantityRetail: Scalars["Int"]["output"];
  minOrderQuantityWholesale: Scalars["Int"]["output"];
  name: Scalars["String"]["output"];
  ogImageUrl?: Maybe<Scalars["String"]["output"]>;
  organic: Scalars["Boolean"]["output"];
  prepacked: Scalars["Boolean"]["output"];
  productPrices: Array<ProductPrice>;
  shopCountries: Array<Scalars["String"]["output"]>;
  shopGlobalUrl?: Maybe<Scalars["String"]["output"]>;
  sku: Scalars["String"]["output"];
  status: ProductStatus;
  stocks: Array<Stock>;
  updatedAt: Scalars["ISO8601DateTime"]["output"];
};

/** The connection type for ProductInterface. */
export type ProductInterfaceConnection = {
  __typename?: "ProductInterfaceConnection";
  /** A list of nodes. */
  nodes: Array<ProductInterface>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export enum ProductKind {
  Beverage = "BEVERAGE",
  Extra = "EXTRA",
  ProductBundle = "PRODUCT_BUNDLE",
}

export type ProductPrice = {
  __typename?: "ProductPrice";
  market: MakerMarket;
  price: Money;
};

/** Input attributes for creating or updating a product price */
export type ProductPriceInput = {
  makerMarketId: Scalars["ID"]["input"];
  price: MoneyInput;
};

export type ProductSeeds = {
  __typename?: "ProductSeeds";
  allergens: Array<Allergen>;
  brands: Array<Brand>;
};

export enum ProductSortField {
  Alcohol = "ALCOHOL",
  CreatedAt = "CREATED_AT",
  Name = "NAME",
  Sku = "SKU",
  Volume = "VOLUME",
}

export type ProductSorting = {
  direction: SortDirection;
  field: ProductSortField;
};

export enum ProductStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
}

export type Query = {
  __typename?: "Query";
  /** Get Maker's Plan */
  activePlan?: Maybe<Plan>;
  /**
   * Returns paginated alcohol products of this Maker according to filters
   * @deprecated Use allProducts
   */
  allBeverages?: Maybe<BeverageConnection>;
  /** Paginated list of credit notes */
  allCreditNotes?: Maybe<OrderInvoiceDetailsConnection>;
  /** Returns all customers of this Maker - paginated */
  allCustomers: CustomerConnection;
  /**
   * Returns paginated list of extras for this maker
   * @deprecated Use allProducts
   */
  allExtras?: Maybe<ExtraConnection>;
  /** Paginated list of invoices */
  allOrderInvoices?: Maybe<OrderInvoiceDetailsConnection>;
  /** Returns all orders of this Maker - paginated */
  allOrders: OrderConnection;
  /**
   * Returns paginated list of product bundles for this maker
   * @deprecated Use allProducts
   */
  allProductBundles?: Maybe<ProductBundleConnection>;
  /** Returns paginated products of this Maker according to filters */
  allProducts?: Maybe<ProductInterfaceConnection>;
  /** Returns paginated, filtered and ordered list of shop products */
  allShopProducts?: Maybe<ShopProductConnection>;
  /** Get announcement by id */
  announcement?: Maybe<Announcement>;
  /** Returns shop announcements */
  announcements?: Maybe<Array<Announcement>>;
  /** Returns a single alcohol product */
  beverage?: Maybe<Beverage>;
  /** Returns a single brand */
  brand?: Maybe<Brand>;
  /** Returns all brands for a given Maker */
  brands: Array<Brand>;
  /** Return a single business member */
  businessMember?: Maybe<BusinessMember>;
  /** Return all business members of current Customer */
  businessMembers?: Maybe<Array<BusinessMember>>;
  /** Calculate price for a product */
  calculatePrice: PricingCalculation;
  /** Returns anything that might be needed to initialize the pricing calculator */
  calculatePriceSeeds: PricingCalculatorSeeds;
  /** Returns a list of carriers and their prices */
  calculateShipping: Array<ShippingCalculatorOffer>;
  /** Check stock for new order item */
  checkStockForOrder: Scalars["Boolean"]["output"];
  /** Returns collection details */
  collection?: Maybe<Collection>;
  /** Returns all shops collections */
  collections?: Maybe<Array<Collection>>;
  /** Returns the current user and maker */
  current?: Maybe<Current>;
  /** Returns a single customer */
  customer?: Maybe<Customer>;
  /** Returns all available tags for customers of this maker */
  customerTags?: Maybe<Array<Scalars["String"]["output"]>>;
  /**
   * Returns all customers matching the given name
   * @deprecated Use 'allCustomers' with filterBy
   */
  customersByName?: Maybe<Array<Customer>>;
  /** Returns business customers without pricing group */
  customersWithoutPricingGroup: Array<Customer>;
  /** Get all Shop's Discounts */
  discount?: Maybe<DiscountInterface>;
  /** Get discount by id */
  discounts: Array<DiscountInterface>;
  /** Exports all customers of the maker according to filters */
  exportCustomers: CustomerExport;
  /** Exports customers with accepted consents */
  exportSubscribers: CustomerExport;
  /** Returns a single extra product */
  extra?: Maybe<Extra>;
  /** Get finance overview for a maker, including balance, sales and outstanding payments overview */
  financeOverview?: Maybe<FinanceOverview>;
  /** Get relevant carriers */
  findCarriers: Array<Carrier>;
  /** Returns first 20 products matching by filter */
  findProducts?: Maybe<Array<ProductInterface>>;
  /** Find nearest service point */
  findServicePoints: Array<ServicePoint>;
  /** Returns relevant warehouse based on Auto Rules */
  findWarehouse?: Maybe<Warehouse>;
  /** Returns all fulfillment rules */
  fulfillmentRules: Array<FulfillmentRule>;
  /** Generate url for uploading report */
  generateReport: ReportGenerate;
  /** Returns a single invitation */
  invitation?: Maybe<Invitation>;
  /** Returns all pending invitations for a given Maker */
  invitations?: Maybe<Array<Invitation>>;
  /** Metadata for a scope of invoices and credit notes */
  invoiceSummary?: Maybe<InvoiceSummary>;
  /** Get Location by id */
  location?: Maybe<Location>;
  /** Find all Maker's Locations */
  locations: Array<Location>;
  /** Returns a single maker */
  maker?: Maybe<Maker>;
  /** Returns all users with their membership for a given Maker */
  memberships?: Maybe<Array<Membership>>;
  /** Returns a specific Order */
  order?: Maybe<Order>;
  /** Order counts for tabs */
  orderTabsMetadata: OrderTabsMetadata;
  /** Returns order details for picking list form */
  pickingList?: Maybe<PickingList>;
  /** Returns a single pricing group */
  pricingGroup?: Maybe<PricingGroup>;
  /** Returns all pricing groups for a given Maker */
  pricingGroups: Array<PricingGroup>;
  /** Get all available plans */
  pricingPlans: Array<PricingPlan>;
  /** Returns a single product */
  product?: Maybe<ProductInterface>;
  /** Returns a single product bundle */
  productBundle?: Maybe<ProductBundle>;
  /** Check if product can be deleted */
  productDeletionDetails?: Maybe<ProductDeletionDetails>;
  /** Returns anything that might be needed to create/update a product */
  productSeeds: ProductSeeds;
  /** Return shops products by name */
  selectForCollection?: Maybe<Array<ShopProduct>>;
  /** Returns first 20 products matching the given name for current shop */
  selectForShop?: Maybe<Array<ProductInterface>>;
  /** Returns a paginated list of maker's settlements for the finance overview */
  settlements?: Maybe<SettlementConnection>;
  /** Returns specific shipping zone */
  shippingZone?: Maybe<ShippingZone>;
  /** Returns shipping zones */
  shippingZones?: Maybe<Array<ShippingZone>>;
  /** Returns banner details */
  shopBanner?: Maybe<ShopBanner>;
  /** Returns all shop's banners */
  shopBanners: Array<ShopBanner>;
  /** Return shop product data */
  shopProduct?: Maybe<ShopProduct>;
  /** Return all makers shop */
  shops?: Maybe<Array<Shop>>;
  /** Get Stock movement */
  stockMovement: StockMovement;
  /** Find all Maker's Stock movements */
  stockMovements: Array<StockMovement>;
  /** Returns the Maker's store */
  store?: Maybe<Store>;
  /** Returns a suggested product price based on the Customer's pricing group */
  suggestedProductPrice?: Maybe<SuggestedProductPrice>;
  /** Returns a specific Tracezilla Order */
  tracezillaOrder?: Maybe<TracezillaOrder>;
  /** Returns a paginated list of maker's transactions for the finance overview */
  transactions?: Maybe<TransactionConnection>;
  /** Returns all users for a given Maker */
  users?: Maybe<Array<User>>;
  /** Returns all fulfillment warehouses */
  warehouses: Array<Warehouse>;
};

export type QueryActivePlanArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryAllBeveragesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<BeverageFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<BeverageSorting>;
};

export type QueryAllCreditNotesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<OrderInvoiceSorting>;
};

export type QueryAllCustomersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<CustomerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<CustomerSorting>;
};

export type QueryAllExtrasArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<ExtraFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<ExtraSorting>;
};

export type QueryAllOrderInvoicesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<OrderInvoiceSorting>;
};

export type QueryAllOrdersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<OrderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<OrderSorting>;
};

export type QueryAllProductBundlesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<ProductBundleFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<ProductBundleSorting>;
};

export type QueryAllProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<ProductFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<ProductSorting>;
};

export type QueryAllShopProductsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<ShopProductSearchInput>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  shopId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<ShopProductSorting>;
};

export type QueryAnnouncementArgs = {
  announcementId: Scalars["ID"]["input"];
};

export type QueryAnnouncementsArgs = {
  shopId: Scalars["ID"]["input"];
};

export type QueryBeverageArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBrandArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBrandsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryBusinessMemberArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryBusinessMembersArgs = {
  customerId: Scalars["ID"]["input"];
};

export type QueryCalculatePriceArgs = {
  calculatorInput: PricingCalculatorInput;
};

export type QueryCalculatePriceSeedsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryCalculateShippingArgs = {
  calculator: ShippingCalculatorInput;
  makerId: Scalars["ID"]["input"];
};

export type QueryCheckStockForOrderArgs = {
  order: OrderWithIdInput;
  orderItem: OrderItemInput;
};

export type QueryCollectionArgs = {
  collectionId: Scalars["ID"]["input"];
};

export type QueryCollectionsArgs = {
  shopId: Scalars["ID"]["input"];
};

export type QueryCustomerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCustomerTagsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryCustomersByNameArgs = {
  makerId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryCustomersWithoutPricingGroupArgs = {
  makerId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryDiscountArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDiscountsArgs = {
  shopId: Scalars["ID"]["input"];
};

export type QueryExportCustomersArgs = {
  filterBy?: InputMaybe<CustomerFilter>;
  makerId: Scalars["ID"]["input"];
};

export type QueryExportSubscribersArgs = {
  filterBy: CustomerFilter;
  format?: InputMaybe<ExportFileTypes>;
  makerId: Scalars["ID"]["input"];
};

export type QueryExtraArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryFinanceOverviewArgs = {
  filterBy?: InputMaybe<FinanceOverviewFilter>;
  makerId: Scalars["ID"]["input"];
};

export type QueryFindCarriersArgs = {
  order: OrderInput;
};

export type QueryFindProductsArgs = {
  filterBy?: InputMaybe<ProductFilter>;
  makerId: Scalars["ID"]["input"];
};

export type QueryFindServicePointsArgs = {
  address: FindServicePointInput;
  shippingCarrier: CarrierKind;
};

export type QueryFindWarehouseArgs = {
  order: OrderInput;
};

export type QueryFulfillmentRulesArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryGenerateReportArgs = {
  filterBy?: InputMaybe<ReportFilter>;
  kind: ReportKind;
  makerId: Scalars["ID"]["input"];
};

export type QueryInvitationArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryInvitationsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryInvoiceSummaryArgs = {
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
  makerId: Scalars["ID"]["input"];
};

export type QueryLocationArgs = {
  locationId: Scalars["ID"]["input"];
};

export type QueryLocationsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryMakerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryMembershipsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryOrderArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryOrderTabsMetadataArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryPickingListArgs = {
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  signedId?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryPricingGroupArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPricingGroupsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryProductArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProductBundleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryProductDeletionDetailsArgs = {
  productId: Scalars["ID"]["input"];
};

export type QueryProductSeedsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QuerySelectForCollectionArgs = {
  name: Scalars["String"]["input"];
  shopId: Scalars["ID"]["input"];
};

export type QuerySelectForShopArgs = {
  name: Scalars["String"]["input"];
  shopId: Scalars["ID"]["input"];
};

export type QuerySettlementsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<SettlementFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<SettlementSorting>;
};

export type QueryShippingZoneArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryShippingZonesArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryShopBannerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryShopBannersArgs = {
  shopId: Scalars["ID"]["input"];
};

export type QueryShopProductArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryShopsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryStockMovementArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryStockMovementsArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryStoreArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QuerySuggestedProductPriceArgs = {
  country: Scalars["String"]["input"];
  customerId: Scalars["ID"]["input"];
  productId: Scalars["ID"]["input"];
};

export type QueryTracezillaOrderArgs = {
  tracezillaId: Scalars["ID"]["input"];
};

export type QueryTransactionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<TransactionFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  makerId: Scalars["ID"]["input"];
  sortBy?: InputMaybe<TransactionSorting>;
};

export type QueryUsersArgs = {
  makerId: Scalars["ID"]["input"];
};

export type QueryWarehousesArgs = {
  makerId: Scalars["ID"]["input"];
};

/** Filter fields for build reports */
export type ReportFilter = {
  country?: InputMaybe<Array<Scalars["String"]["input"]>>;
  createdById?: InputMaybe<Scalars["ID"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  customerKind?: InputMaybe<Array<CustomerKind>>;
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  orderSource?: InputMaybe<Array<OrderSource>>;
  productIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tags?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tagsOperator?: InputMaybe<Filter>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

export type ReportGenerate = {
  __typename?: "ReportGenerate";
  exportUrl: Scalars["String"]["output"];
};

export enum ReportKind {
  CustomerSales = "CUSTOMER_SALES",
  SalesSummary = "SALES_SUMMARY",
  VolumeStats = "VOLUME_STATS",
}

export enum SalesChannels {
  Retail = "RETAIL",
  Wholesale = "WHOLESALE",
}

/** Sales overview for a Maker */
export type SalesOverview = {
  __typename?: "SalesOverview";
  orderCount: Scalars["Int"]["output"];
  totalGrossSales: Money;
  totalNetSales: Money;
};

export type ServicePoint = {
  __typename?: "ServicePoint";
  country: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  openingHours?: Maybe<Array<OpeningHours>>;
  servicePointId: Scalars["String"]["output"];
  typeName?: Maybe<Scalars["String"]["output"]>;
  visitingAddress?: Maybe<MailingAddress>;
};

export type Settlement = {
  __typename?: "Settlement";
  balance: Money;
  fees: Money;
  id: Scalars["ID"]["output"];
  payments: Money;
  payouts: Money;
  period: Scalars["String"]["output"];
  refunds: Money;
  serviceInvoiceDocUrl?: Maybe<Scalars["String"]["output"]>;
  settledAt: Scalars["ISO8601DateTime"]["output"];
  settlementDocUrl?: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for Settlement. */
export type SettlementConnection = {
  __typename?: "SettlementConnection";
  /** A list of nodes. */
  nodes: Array<Settlement>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Filtering attributes for Settlements */
export type SettlementFilter = {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

export enum SettlementSortField {
  SettledAt = "SETTLED_AT",
}

export type SettlementSorting = {
  direction: SortDirection;
  field: SettlementSortField;
};

export type ShippingCalculatorInput = {
  adhocPickup?: InputMaybe<Scalars["Boolean"]["input"]>;
  alcoholProducts?: InputMaybe<Scalars["Boolean"]["input"]>;
  currency: Scalars["String"]["input"];
  dangerousGoods?: InputMaybe<Scalars["Boolean"]["input"]>;
  orderKind: OrderKind;
  targetCountry: Scalars["String"]["input"];
  targetPostcode?: InputMaybe<Scalars["String"]["input"]>;
  warehouseId: Scalars["ID"]["input"];
  weight: Scalars["Float"]["input"];
};

export type ShippingCalculatorOffer = {
  __typename?: "ShippingCalculatorOffer";
  carrierKind: CarrierKind;
  packages: Array<ShippingCalculatorPackage>;
};

export type ShippingCalculatorPackage = {
  __typename?: "ShippingCalculatorPackage";
  name: Scalars["String"]["output"];
  price: Money;
};

export type ShippingLine = {
  __typename?: "ShippingLine";
  kind: ShippingLineKind;
  name: Scalars["String"]["output"];
  price: Money;
};

export type ShippingLineInput = {
  kind: ShippingLineKind;
  name: Scalars["String"]["input"];
  price: MoneyInput;
};

export enum ShippingLineKind {
  Custom = "CUSTOM",
  Free = "FREE",
}

export enum ShippingPackage {
  PalletFull = "PALLET_FULL",
  PalletHalf = "PALLET_HALF",
  PalletQuarter = "PALLET_QUARTER",
  Parcel = "PARCEL",
}

export type ShippingRate = {
  __typename?: "ShippingRate";
  conditionPriceMax?: Maybe<Money>;
  conditionPriceMin?: Maybe<Money>;
  id: Scalars["ID"]["output"];
  kind: ShippingRateKind;
  marketKind: MakerMarketKind;
  name: Scalars["String"]["output"];
  price: Money;
  status: ShippingRateStatus;
};

export type ShippingRateInput = {
  conditionPriceMax?: InputMaybe<MoneyInput>;
  conditionPriceMin?: InputMaybe<MoneyInput>;
  kind: ShippingRateKind;
  marketKind: MakerMarketKind;
  name?: InputMaybe<Scalars["String"]["input"]>;
  price?: InputMaybe<MoneyInput>;
};

export enum ShippingRateKind {
  Collect = "COLLECT",
  Free = "FREE",
  Home = "HOME",
}

export enum ShippingRateStatus {
  Active = "ACTIVE",
  Disabled = "DISABLED",
}

export type ShippingZone = {
  __typename?: "ShippingZone";
  carrier: CarrierKind;
  country: Scalars["String"]["output"];
  currency: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  shippingRates: Array<ShippingRate>;
};

export type ShippingZoneInput = {
  shippingRates: Array<ShippingRateInput>;
};

/** Autogenerated return type of ShippingZoneUpdate. */
export type ShippingZoneUpdatePayload = {
  __typename?: "ShippingZoneUpdatePayload";
  shippingZone?: Maybe<ShippingZone>;
};

export type Shop = {
  __typename?: "Shop";
  automaticPaymentMethods: Array<CartPaymentMethod>;
  collections: Array<Collection>;
  country: Scalars["String"]["output"];
  customPaymentMethods?: Maybe<Array<CartPaymentMethod>>;
  defaultDescription: Scalars["String"]["output"];
  defaultTitle: Scalars["String"]["output"];
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  locale: Scalars["String"]["output"];
  paymentMethods?: Maybe<Array<CartPaymentMethod>>;
  paymentMethodsMode: PaymentMethodsMode;
  seoDescription?: Maybe<Scalars["String"]["output"]>;
  seoTitle?: Maybe<Scalars["String"]["output"]>;
  shopProductsCount: Scalars["Int"]["output"];
  status: ShopStatus;
  store: Store;
  validCustomPaymentMethods: Array<CartPaymentMethod>;
};

export type ShopBanner = {
  __typename?: "ShopBanner";
  aspectRatio: AspectRatioMode;
  backgroundImageUrl?: Maybe<Scalars["String"]["output"]>;
  backgroundVideoUrl?: Maybe<Scalars["String"]["output"]>;
  buttonLabel?: Maybe<Scalars["String"]["output"]>;
  buttonLink?: Maybe<Scalars["String"]["output"]>;
  collections: Array<Collection>;
  headingText?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  overlayBg: Scalars["Boolean"]["output"];
  status: ShopBannerStatus;
  subheadingText?: Maybe<Scalars["String"]["output"]>;
};

export type ShopBannerCollectionInput = {
  id: Scalars["ID"]["input"];
};

/** Autogenerated return type of ShopBannerCreate. */
export type ShopBannerCreatePayload = {
  __typename?: "ShopBannerCreatePayload";
  shopBanner?: Maybe<ShopBanner>;
};

/** Autogenerated return type of ShopBannerDelete. */
export type ShopBannerDeletePayload = {
  __typename?: "ShopBannerDeletePayload";
  shopBanner?: Maybe<ShopBanner>;
};

/** Input attributes for an shop banner */
export type ShopBannerInput = {
  aspectRatio?: InputMaybe<AspectRatioMode>;
  backgroundImageFile?: InputMaybe<Scalars["Upload"]["input"]>;
  backgroundVideoUrl?: InputMaybe<Scalars["String"]["input"]>;
  buttonLabel?: InputMaybe<Scalars["String"]["input"]>;
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  collections?: InputMaybe<Array<ShopBannerCollectionInput>>;
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  overlayBg?: InputMaybe<Scalars["Boolean"]["input"]>;
  status: ShopBannerStatus;
  subheadingText?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ShopBannerStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
}

/** Autogenerated return type of ShopBannerUpdate. */
export type ShopBannerUpdatePayload = {
  __typename?: "ShopBannerUpdatePayload";
  shopBanner?: Maybe<ShopBanner>;
};

export type ShopCopyProducts = {
  __typename?: "ShopCopyProducts";
  collectionsMigrated: Scalars["Int"]["output"];
  collectionsSkipped: Scalars["Int"]["output"];
  collectionsTotal: Scalars["Int"]["output"];
  productsMigrated: Scalars["Int"]["output"];
  productsSkipped: Scalars["Int"]["output"];
  productsTotal: Scalars["Int"]["output"];
};

/** Input attributes for copy products from one shop to another */
export type ShopCopyProductsInput = {
  fromShopId: Scalars["ID"]["input"];
  withCollections: Scalars["Boolean"]["input"];
};

/** Autogenerated return type of ShopCopyProducts. */
export type ShopCopyProductsPayload = {
  __typename?: "ShopCopyProductsPayload";
  data?: Maybe<ShopCopyProducts>;
};

/** Autogenerated return type of ShopCreate. */
export type ShopCreatePayload = {
  __typename?: "ShopCreatePayload";
  shop?: Maybe<Shop>;
};

/** Autogenerated return type of ShopDelete. */
export type ShopDeletePayload = {
  __typename?: "ShopDeletePayload";
  shop?: Maybe<Shop>;
};

/** Input attributes for a shop */
export type ShopInput = {
  locale?: InputMaybe<Scalars["String"]["input"]>;
  paymentMethods?: InputMaybe<Array<CartPaymentMethod>>;
  paymentMethodsMode?: InputMaybe<PaymentMethodsMode>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  status: ShopStatus;
};

export type ShopProduct = {
  __typename?: "ShopProduct";
  collections: Array<Collection>;
  defaultDescription: Scalars["String"]["output"];
  defaultOgImageUrl?: Maybe<Scalars["String"]["output"]>;
  defaultTitle: Scalars["String"]["output"];
  fullUrl?: Maybe<Scalars["String"]["output"]>;
  htmlDescription?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  jsonDescription?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  ogDescription?: Maybe<Scalars["String"]["output"]>;
  ogTitle?: Maybe<Scalars["String"]["output"]>;
  product: ProductInterface;
  salesChannels: Array<SalesChannels>;
  seoDescription?: Maybe<Scalars["String"]["output"]>;
  seoTitle?: Maybe<Scalars["String"]["output"]>;
  shop: Shop;
  slug?: Maybe<Scalars["String"]["output"]>;
  status: ShopProductStatus;
  stocks: Array<Stock>;
};

/** Input attributes for an shop product */
export type ShopProductCollectionInput = {
  id: Scalars["ID"]["input"];
};

/** The connection type for ShopProduct. */
export type ShopProductConnection = {
  __typename?: "ShopProductConnection";
  /** A list of nodes. */
  nodes: Array<ShopProduct>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** Autogenerated return type of ShopProductCreate. */
export type ShopProductCreatePayload = {
  __typename?: "ShopProductCreatePayload";
  shopProduct?: Maybe<ShopProduct>;
};

/** Autogenerated return type of ShopProductDelete. */
export type ShopProductDeletePayload = {
  __typename?: "ShopProductDeletePayload";
  shopProduct?: Maybe<ShopProduct>;
};

/** Input attributes for an shop product */
export type ShopProductInput = {
  collections?: InputMaybe<Array<ShopProductCollectionInput>>;
  htmlDescription?: InputMaybe<Scalars["String"]["input"]>;
  jsonDescription?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  productId: Scalars["ID"]["input"];
  salesChannels?: InputMaybe<Array<SalesChannels>>;
  status: ShopProductStatus;
};

/** Input attributes for meta attributes of a shop product */
export type ShopProductMetaInput = {
  ogDescription?: InputMaybe<Scalars["String"]["input"]>;
  ogTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
};

/** Attributes for filtering of shop products */
export type ShopProductSearchInput = {
  salesChannel?: InputMaybe<Array<SalesChannels>>;
  search?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Array<ShopProductStatus>>;
};

export enum ShopProductSortField {
  CreatedAt = "CREATED_AT",
  Name = "NAME",
}

export type ShopProductSorting = {
  direction: SortDirection;
  field: ShopProductSortField;
};

export enum ShopProductStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Draft = "DRAFT",
}

/** Autogenerated return type of ShopProductUpdateMeta. */
export type ShopProductUpdateMetaPayload = {
  __typename?: "ShopProductUpdateMetaPayload";
  shopProduct?: Maybe<ShopProduct>;
};

/** Autogenerated return type of ShopProductUpdate. */
export type ShopProductUpdatePayload = {
  __typename?: "ShopProductUpdatePayload";
  shopProduct?: Maybe<ShopProduct>;
};

export enum ShopStatus {
  Active = "ACTIVE",
  Draft = "DRAFT",
}

/** Autogenerated return type of ShopUpdate. */
export type ShopUpdatePayload = {
  __typename?: "ShopUpdatePayload";
  shop?: Maybe<Shop>;
};

/** Autogenerated return type of SigningComplete. */
export type SigningCompletePayload = {
  __typename?: "SigningCompletePayload";
  maker?: Maybe<Maker>;
};

export enum SortDirection {
  Asc = "ASC",
  Desc = "DESC",
}

export type Stock = {
  __typename?: "Stock";
  id: Scalars["ID"]["output"];
  items: Array<StockItem>;
  quantity: Scalars["Int"]["output"];
  quantityAvailable: Scalars["Int"]["output"];
  quantityReserved: Scalars["Int"]["output"];
  tracked: Scalars["Boolean"]["output"];
  warehouse: Warehouse;
};

/** Input attributes for creating or updating a Products stock */
export type StockInput = {
  quantity?: InputMaybe<Scalars["Int"]["input"]>;
  tracked: Scalars["Boolean"]["input"];
  warehouseId: Scalars["ID"]["input"];
};

export type StockItem = {
  __typename?: "StockItem";
  batchCode: Scalars["String"]["output"];
  createdAt: Scalars["ISO8601DateTime"]["output"];
  expiryDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  id: Scalars["ID"]["output"];
  noExpiry: Scalars["Boolean"]["output"];
  quantity: Scalars["Int"]["output"];
};

export type StockMovement = {
  __typename?: "StockMovement";
  arcNumber?: Maybe<Scalars["String"]["output"]>;
  arrivalDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  carrierName?: Maybe<Scalars["String"]["output"]>;
  displayFromWarehouse: Scalars["String"]["output"];
  displayToWarehouse: Scalars["String"]["output"];
  eadDocUrl?: Maybe<Scalars["String"]["output"]>;
  fromWarehouse?: Maybe<Warehouse>;
  id: Scalars["ID"]["output"];
  items?: Maybe<Array<StockMovementItem>>;
  makerNote?: Maybe<Scalars["String"]["output"]>;
  number: Scalars["String"]["output"];
  numberOfPallets?: Maybe<Scalars["Int"]["output"]>;
  sentDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  status: StockMovementStatus;
  toWarehouse: Warehouse;
  totalConfirmed?: Maybe<Scalars["Int"]["output"]>;
  totalSent?: Maybe<Scalars["Int"]["output"]>;
  trackingId?: Maybe<Scalars["String"]["output"]>;
  useBemakersSku: Scalars["Boolean"]["output"];
};

/** Autogenerated return type of StockMovementCreate. */
export type StockMovementCreatePayload = {
  __typename?: "StockMovementCreatePayload";
  stockMovement?: Maybe<StockMovement>;
};

/** Autogenerated return type of StockMovementDelete. */
export type StockMovementDeletePayload = {
  __typename?: "StockMovementDeletePayload";
  stockMovement?: Maybe<StockMovement>;
};

/** Input attributes for a movement */
export type StockMovementInput = {
  arcNumber?: InputMaybe<Scalars["String"]["input"]>;
  arrivalDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  carrierName?: InputMaybe<Scalars["String"]["input"]>;
  eadFile?: InputMaybe<Scalars["Upload"]["input"]>;
  fromWarehouseId?: InputMaybe<Scalars["ID"]["input"]>;
  items?: InputMaybe<Array<StockMovementItemInput>>;
  makerNote?: InputMaybe<Scalars["String"]["input"]>;
  /** required for internal warehouses */
  numberOfPallets?: InputMaybe<Scalars["Int"]["input"]>;
  sentDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  toWarehouseId: Scalars["ID"]["input"];
  trackingId?: InputMaybe<Scalars["String"]["input"]>;
};

export type StockMovementItem = {
  __typename?: "StockMovementItem";
  batchCode: Scalars["String"]["output"];
  boxHeight?: Maybe<Scalars["Float"]["output"]>;
  boxLength?: Maybe<Scalars["Float"]["output"]>;
  boxWidth?: Maybe<Scalars["Float"]["output"]>;
  expiryDate?: Maybe<Scalars["ISO8601Date"]["output"]>;
  id: Scalars["ID"]["output"];
  noExpiry: Scalars["Boolean"]["output"];
  product: ProductInterface;
  productsPerBox?: Maybe<Scalars["Int"]["output"]>;
  quantityConfirmed?: Maybe<Scalars["Int"]["output"]>;
  quantitySent: Scalars["Int"]["output"];
  warehouseNote?: Maybe<Scalars["String"]["output"]>;
};

/** Input attributes for a movement item */
export type StockMovementItemInput = {
  batchCode: Scalars["String"]["input"];
  /** Required for movements to the German warehouse */
  boxHeight?: InputMaybe<Scalars["Float"]["input"]>;
  /** Required for movements to the German warehouse */
  boxLength?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values should be in centimeters. Required for movements to the German warehouse. */
  boxWidth?: InputMaybe<Scalars["Float"]["input"]>;
  expiryDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  noExpiry?: InputMaybe<Scalars["Boolean"]["input"]>;
  productId: Scalars["ID"]["input"];
  /** Required for movements to the German warehouse */
  productsPerBox?: InputMaybe<Scalars["Int"]["input"]>;
  quantitySent: Scalars["Int"]["input"];
};

export enum StockMovementStatus {
  Confirmed = "CONFIRMED",
  Draft = "DRAFT",
  Submitted = "SUBMITTED",
}

/** Autogenerated return type of StockMovementSubmit. */
export type StockMovementSubmitPayload = {
  __typename?: "StockMovementSubmitPayload";
  stockMovement?: Maybe<StockMovement>;
};

/** Autogenerated return type of StockMovementUpdate. */
export type StockMovementUpdatePayload = {
  __typename?: "StockMovementUpdatePayload";
  stockMovement?: Maybe<StockMovement>;
};

export type StockOption = {
  __typename?: "StockOption";
  batchCodeMode?: Maybe<Scalars["String"]["output"]>;
  batchCodeOptions?: Maybe<Array<Scalars["String"]["output"]>>;
  productId: Scalars["ID"]["output"];
  tracked: Scalars["Boolean"]["output"];
};

export type Store = {
  __typename?: "Store";
  cssStyles?: Maybe<Scalars["String"]["output"]>;
  facebookPixel?: Maybe<Scalars["String"]["output"]>;
  facebookUrl?: Maybe<Scalars["String"]["output"]>;
  fullUrl: Scalars["String"]["output"];
  googleAnalytics?: Maybe<Scalars["String"]["output"]>;
  googleTagManager?: Maybe<Scalars["String"]["output"]>;
  homepageUrl: Scalars["String"]["output"];
  imageAspectRatio?: Maybe<AspectRatioMode>;
  instagramUrl?: Maybe<Scalars["String"]["output"]>;
  klaviyoId?: Maybe<Scalars["String"]["output"]>;
  linkedinUrl?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  seoDescription: Scalars["String"]["output"];
  seoTitle: Scalars["String"]["output"];
  storeHomepageUrl?: Maybe<Scalars["String"]["output"]>;
  webFonts?: Maybe<Scalars["String"]["output"]>;
};

/** Input attributes for a store */
export type StoreInput = {
  facebookPixel?: InputMaybe<Scalars["String"]["input"]>;
  facebookUrl?: InputMaybe<Scalars["String"]["input"]>;
  googleAnalytics?: InputMaybe<Scalars["String"]["input"]>;
  googleTagManager?: InputMaybe<Scalars["String"]["input"]>;
  homepageUrl: Scalars["String"]["input"];
  imageAspectRatio?: InputMaybe<AspectRatioMode>;
  instagramUrl?: InputMaybe<Scalars["String"]["input"]>;
  klaviyoId?: InputMaybe<Scalars["String"]["input"]>;
  linkedinUrl?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription: Scalars["String"]["input"];
  seoTitle: Scalars["String"]["input"];
  storeHomepageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

/** Autogenerated return type of StoreUpdate. */
export type StoreUpdatePayload = {
  __typename?: "StoreUpdatePayload";
  store?: Maybe<Store>;
};

export type SuggestedProductPrice = {
  __typename?: "SuggestedProductPrice";
  originalPrice: Money;
  price: Money;
  pricingGroup?: Maybe<PricingGroup>;
};

export type Terms = {
  __typename?: "Terms";
  kind: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  updateNotes?: Maybe<Array<Scalars["String"]["output"]>>;
  url: Scalars["String"]["output"];
  version: Scalars["String"]["output"];
};

/** Input attributes for specifying which terms are accepted */
export type TermsInput = {
  kind: Scalars["String"]["input"];
  version: Scalars["String"]["input"];
};

export type TimeOptions = {
  __typename?: "TimeOptions";
  timeFrom?: Maybe<Scalars["String"]["output"]>;
  timeTo?: Maybe<Scalars["String"]["output"]>;
};

/** Autogenerated return type of TracezillaConfirm. */
export type TracezillaConfirmPayload = {
  __typename?: "TracezillaConfirmPayload";
  tracezillaOrder?: Maybe<TracezillaOrder>;
};

export type TracezillaOrder = {
  __typename?: "TracezillaOrder";
  number: Scalars["String"]["output"];
  order: Order;
};

export type Transaction = {
  __typename?: "Transaction";
  amount: Money;
  bookedAt: Scalars["ISO8601DateTime"]["output"];
  id: Scalars["ID"]["output"];
  kind: TransactionKind;
  orderId?: Maybe<Scalars["ID"]["output"]>;
  orderNumber?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

/** The connection type for Transaction. */
export type TransactionConnection = {
  __typename?: "TransactionConnection";
  /** A list of nodes. */
  nodes: Array<Transaction>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TransactionFee = {
  __typename?: "TransactionFee";
  amount: Money;
  kind: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
};

/** Filtering attributes for Transactions */
export type TransactionFilter = {
  fromDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
  kind?: InputMaybe<Array<TransactionKind>>;
  /** Search by order id */
  search?: InputMaybe<Scalars["String"]["input"]>;
  toDate?: InputMaybe<Scalars["ISO8601Date"]["input"]>;
};

export enum TransactionKind {
  Adjustment = "ADJUSTMENT",
  AgeVerification = "AGE_VERIFICATION",
  Channel = "CHANNEL",
  Charge = "CHARGE",
  Commission = "COMMISSION",
  ExciseDuty = "EXCISE_DUTY",
  FulfillmentOrder = "FULFILLMENT_ORDER",
  FulfillmentStock = "FULFILLMENT_STOCK",
  FulfillmentStorage = "FULFILLMENT_STORAGE",
  FxDelta = "FX_DELTA",
  IncludedCommission = "INCLUDED_COMMISSION",
  LabelStock = "LABEL_STOCK",
  ManualShipping = "MANUAL_SHIPPING",
  Payin = "PAYIN",
  Payment = "PAYMENT",
  PaymentProcessing = "PAYMENT_PROCESSING",
  Payout = "PAYOUT",
  Recycling = "RECYCLING",
  Refund = "REFUND",
  ServiceFee = "SERVICE_FEE",
  Shipping = "SHIPPING",
  Subscription = "SUBSCRIPTION",
}

export enum TransactionSortField {
  BookedAt = "BOOKED_AT",
}

/** Sorting attributes for Transactions, including: created_by */
export type TransactionSorting = {
  direction: SortDirection;
  field: TransactionSortField;
};

/** Autogenerated return type of UpdateBeverage. */
export type UpdateBeveragePayload = {
  __typename?: "UpdateBeveragePayload";
  product?: Maybe<Beverage>;
};

/** Autogenerated return type of UpdateProductBundle. */
export type UpdateProductBundlePayload = {
  __typename?: "UpdateProductBundlePayload";
  productBundle?: Maybe<ProductBundle>;
};

/** Input attributes for updating product prices */
export type UpdateProductPricesInput = {
  productPrices: Array<ProductPriceInput>;
};

/** Autogenerated return type of UpdateProductPrices. */
export type UpdateProductPricesPayload = {
  __typename?: "UpdateProductPricesPayload";
  product?: Maybe<ProductInterface>;
};

export type User = {
  __typename?: "User";
  avatar?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["ISO8601DateTime"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  lastSeenAt?: Maybe<Scalars["ISO8601Date"]["output"]>;
  locale?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  status: UserStatus;
  timezone?: Maybe<Scalars["String"]["output"]>;
};

export enum UserStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
  Inactive = "INACTIVE",
}

export type Warehouse = {
  __typename?: "Warehouse";
  address: MailingAddress;
  code?: Maybe<Scalars["String"]["output"]>;
  consignor?: Maybe<Consignor>;
  country: Scalars["String"]["output"];
  dispatchOffice: Scalars["String"]["output"];
  displayName: Scalars["String"]["output"];
  email: Scalars["String"]["output"];
  exciseNumber?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  kind: WarehouseKind;
  options: WarehouseOptions;
  status: WarehouseStatus;
};

/** Input attributes for creating or updating a Maker warehouse */
export type WarehouseInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  options?: InputMaybe<WarehouseOptionsInput>;
};

export enum WarehouseKind {
  Amazon = "AMAZON",
  External = "EXTERNAL",
  Internal = "INTERNAL",
}

export type WarehouseOptions = {
  __typename?: "WarehouseOptions";
  lowQuantityAlert?: Maybe<Scalars["Int"]["output"]>;
};

/** Input attributes for updating warehouse options */
export type WarehouseOptionsInput = {
  lowQuantityAlert?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum WarehouseStatus {
  Active = "ACTIVE",
  Deleted = "DELETED",
}

/** Autogenerated return type of WarehouseUpdate. */
export type WarehouseUpdatePayload = {
  __typename?: "WarehouseUpdatePayload";
  warehouse?: Maybe<Warehouse>;
};

export type UpdateProductPricesMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: UpdateProductPricesInput;
}>;

export type UpdateProductPricesMutation = {
  __typename?: "Mutation";
  updateProductPrices?: {
    __typename?: "UpdateProductPricesPayload";
    product?:
      | {
          __typename?: "Beverage";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "Extra";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | null;
  } | null;
};

export type SearchCustomerByNameQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}>;

export type SearchCustomerByNameQuery = {
  __typename?: "Query";
  customersByName?: Array<{
    __typename?: "Customer";
    id: string;
    currency: string;
    name: string;
    email: string;
    billingEmail?: string | null;
    ccInvoices: boolean;
    country: string;
    kind: CustomerKind;
    paymentTerms?: string | null;
    taxId?: string | null;
    exciseNumber?: string | null;
    exciseTaxWarehouse?: string | null;
    exciseWarehouseKeeper?: string | null;
    exciseDestinationKind?: ExciseDestinationKind | null;
    legalDrinkingAge?: boolean | null;
    ordersCount: number;
    tags: Array<string>;
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
    mailingAddresses: Array<{
      __typename?: "MailingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }>;
    billingAddresses?: Array<{
      __typename?: "BillingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }> | null;
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  }> | null;
};

export type GetCustomerTagsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetCustomerTagsQuery = {__typename?: "Query"; customerTags?: Array<string> | null};

export type SearchCustomersWithoutPricingGroupQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}>;

export type SearchCustomersWithoutPricingGroupQuery = {
  __typename?: "Query";
  customersWithoutPricingGroup: Array<{
    __typename?: "Customer";
    id: string;
    currency: string;
    name: string;
    email: string;
    billingEmail?: string | null;
    ccInvoices: boolean;
    country: string;
    kind: CustomerKind;
    paymentTerms?: string | null;
    taxId?: string | null;
    exciseNumber?: string | null;
    exciseTaxWarehouse?: string | null;
    exciseWarehouseKeeper?: string | null;
    exciseDestinationKind?: ExciseDestinationKind | null;
    legalDrinkingAge?: boolean | null;
    ordersCount: number;
    tags: Array<string>;
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
    mailingAddresses: Array<{
      __typename?: "MailingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }>;
    billingAddresses?: Array<{
      __typename?: "BillingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }> | null;
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  }>;
};

export type PaginatedAllBeveragesForSearchQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<BeverageSorting>;
  filterBy?: InputMaybe<BeverageFilter>;
}>;

export type PaginatedAllBeveragesForSearchQuery = {
  __typename?: "Query";
  allBeverages?: {
    __typename?: "BeverageConnection";
    nodes: Array<{
      __typename?: "Beverage";
      id: string;
      sku: string;
      name: string;
      grossWeight?: number | null;
      kind: ProductKind;
      organic: boolean;
      shopCountries: Array<string>;
      shopGlobalUrl?: string | null;
      prepacked: boolean;
      ogImageUrl?: string | null;
      caseQuantityRetail: number;
      caseQuantityWholesale: number;
      minOrderQuantityRetail: number;
      minOrderQuantityWholesale: number;
      status: ProductStatus;
      alcoholPercent?: number | null;
      netWeight?: number | null;
      cnCode?: string | null;
      exciseProductCode?: string | null;
      volume?: number | null;
      packagingCode?: string | null;
      degreePlato?: number | null;
      gtin?: string | null;
      premix?: PremixKind | null;
      sugarPerLiter?: number | null;
      stocks: Array<{
        __typename?: "Stock";
        id: string;
        quantity: number;
        quantityAvailable: number;
        quantityReserved: number;
        tracked: boolean;
        items: Array<{
          __typename?: "StockItem";
          id: string;
          batchCode: string;
          expiryDate?: any | null;
          quantity: number;
          createdAt: any;
        }>;
        warehouse: {
          __typename?: "Warehouse";
          code?: string | null;
          dispatchOffice: string;
          displayName: string;
          email: string;
          exciseNumber?: string | null;
          id: string;
          kind: WarehouseKind;
          status: WarehouseStatus;
          country: string;
          address: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          };
          options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
          consignor?: {
            __typename?: "Consignor";
            exciseNumber?: string | null;
            address?: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            } | null;
          } | null;
        };
      }>;
      productPrices: Array<{
        __typename?: "ProductPrice";
        price: {__typename?: "Money"; amount: any; currency: string};
        market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
      }>;
      images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      brand?: {__typename?: "Brand"; id: string; name: string} | null;
      allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
    }>;
  } | null;
};

export type PaginatedAllProductBundlesForSearchQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<ProductBundleSorting>;
  filterBy?: InputMaybe<ProductBundleFilter>;
}>;

export type PaginatedAllProductBundlesForSearchQuery = {
  __typename?: "Query";
  allProductBundles?: {
    __typename?: "ProductBundleConnection";
    nodes: Array<{
      __typename?: "ProductBundle";
      id: string;
      sku: string;
      name: string;
      grossWeight?: number | null;
      kind: ProductKind;
      organic: boolean;
      shopCountries: Array<string>;
      shopGlobalUrl?: string | null;
      prepacked: boolean;
      ogImageUrl?: string | null;
      caseQuantityRetail: number;
      caseQuantityWholesale: number;
      minOrderQuantityRetail: number;
      minOrderQuantityWholesale: number;
      status: ProductStatus;
      stocks: Array<{
        __typename?: "Stock";
        id: string;
        quantity: number;
        quantityAvailable: number;
        quantityReserved: number;
        tracked: boolean;
        items: Array<{
          __typename?: "StockItem";
          id: string;
          batchCode: string;
          expiryDate?: any | null;
          quantity: number;
          createdAt: any;
        }>;
        warehouse: {
          __typename?: "Warehouse";
          code?: string | null;
          dispatchOffice: string;
          displayName: string;
          email: string;
          exciseNumber?: string | null;
          id: string;
          kind: WarehouseKind;
          status: WarehouseStatus;
          country: string;
          address: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          };
          options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
          consignor?: {
            __typename?: "Consignor";
            exciseNumber?: string | null;
            address?: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            } | null;
          } | null;
        };
      }>;
      productPrices: Array<{
        __typename?: "ProductPrice";
        price: {__typename?: "Money"; amount: any; currency: string};
        market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
      }>;
      images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      brand?: {__typename?: "Brand"; id: string; name: string} | null;
      allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      items: Array<{
        __typename?: "ProductBundleItem";
        id: string;
        quantity: number;
        product:
          | {__typename: "Beverage"; id: string; sku: string; name: string}
          | {__typename: "Extra"; id: string; sku: string; name: string}
          | {__typename: "ProductBundle"; id: string; sku: string; name: string};
      }>;
    }>;
  } | null;
};

export type PaginatedAllProductExtrasForSearchQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<ExtraSorting>;
  filterBy?: InputMaybe<ExtraFilter>;
}>;

export type PaginatedAllProductExtrasForSearchQuery = {
  __typename?: "Query";
  allExtras?: {
    __typename?: "ExtraConnection";
    nodes: Array<{
      __typename?: "Extra";
      id: string;
      sku: string;
      name: string;
      grossWeight?: number | null;
      kind: ProductKind;
      organic: boolean;
      shopCountries: Array<string>;
      shopGlobalUrl?: string | null;
      prepacked: boolean;
      ogImageUrl?: string | null;
      caseQuantityRetail: number;
      caseQuantityWholesale: number;
      minOrderQuantityRetail: number;
      minOrderQuantityWholesale: number;
      status: ProductStatus;
      extraKind?: ExtraKind | null;
      physicalProduct: boolean;
      cnCode?: string | null;
      stocks: Array<{
        __typename?: "Stock";
        id: string;
        quantity: number;
        quantityAvailable: number;
        quantityReserved: number;
        tracked: boolean;
        items: Array<{
          __typename?: "StockItem";
          id: string;
          batchCode: string;
          expiryDate?: any | null;
          quantity: number;
          createdAt: any;
        }>;
        warehouse: {
          __typename?: "Warehouse";
          code?: string | null;
          dispatchOffice: string;
          displayName: string;
          email: string;
          exciseNumber?: string | null;
          id: string;
          kind: WarehouseKind;
          status: WarehouseStatus;
          country: string;
          address: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          };
          options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
          consignor?: {
            __typename?: "Consignor";
            exciseNumber?: string | null;
            address?: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            } | null;
          } | null;
        };
      }>;
      productPrices: Array<{
        __typename?: "ProductPrice";
        price: {__typename?: "Money"; amount: any; currency: string};
        market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
      }>;
      images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      brand?: {__typename?: "Brand"; id: string; name: string} | null;
      allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
    }>;
  } | null;
};

export type SearchProductsByNameQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  filterBy?: InputMaybe<ProductFilter>;
}>;

export type SearchProductsByNameQuery = {
  __typename?: "Query";
  findProducts?: Array<
    | {
        __typename?: "Beverage";
        alcoholPercent?: number | null;
        netWeight?: number | null;
        cnCode?: string | null;
        exciseProductCode?: string | null;
        volume?: number | null;
        packagingCode?: string | null;
        degreePlato?: number | null;
        gtin?: string | null;
        premix?: PremixKind | null;
        sugarPerLiter?: number | null;
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "Extra";
        extraKind?: ExtraKind | null;
        physicalProduct: boolean;
        cnCode?: string | null;
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        items: Array<{
          __typename?: "ProductBundleItem";
          id: string;
          quantity: number;
          product:
            | {__typename: "Beverage"; id: string; sku: string; name: string}
            | {__typename: "Extra"; id: string; sku: string; name: string}
            | {__typename: "ProductBundle"; id: string; sku: string; name: string};
        }>;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
  > | null;
};

export type SearchShopProductsForCollectionQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}>;

export type SearchShopProductsForCollectionQuery = {
  __typename?: "Query";
  selectForCollection?: Array<{
    __typename?: "ShopProduct";
    id: string;
    name: string;
    status: ShopProductStatus;
    stocks: Array<{
      __typename?: "Stock";
      id: string;
      quantityAvailable: number;
      tracked: boolean;
      warehouse: {__typename?: "Warehouse"; displayName: string};
    }>;
    product:
      | {
          __typename?: "Beverage";
          id: string;
          sku: string;
          kind: ProductKind;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
        }
      | {
          __typename?: "Extra";
          id: string;
          sku: string;
          kind: ProductKind;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          kind: ProductKind;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
        };
  }> | null;
};

export type SearchShopProductsQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}>;

export type SearchShopProductsQuery = {
  __typename?: "Query";
  beverages?: Array<
    | {
        __typename?: "Beverage";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        alcoholPercent?: number | null;
        netWeight?: number | null;
        cnCode?: string | null;
        exciseProductCode?: string | null;
        volume?: number | null;
        packagingCode?: string | null;
        degreePlato?: number | null;
        gtin?: string | null;
        premix?: PremixKind | null;
        sugarPerLiter?: number | null;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {__typename?: "Extra"}
    | {__typename?: "ProductBundle"}
  > | null;
  productBundles?: Array<
    | {__typename?: "Beverage"}
    | {__typename?: "Extra"}
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        items: Array<{
          __typename?: "ProductBundleItem";
          id: string;
          quantity: number;
          product:
            | {__typename: "Beverage"; id: string; sku: string; name: string}
            | {__typename: "Extra"; id: string; sku: string; name: string}
            | {__typename: "ProductBundle"; id: string; sku: string; name: string};
        }>;
      }
  > | null;
  extras?: Array<
    | {__typename?: "Beverage"}
    | {
        __typename?: "Extra";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        extraKind?: ExtraKind | null;
        physicalProduct: boolean;
        cnCode?: string | null;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {__typename?: "ProductBundle"}
  > | null;
};

export type CreateBannerMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: ShopBannerInput;
}>;

export type CreateBannerMutation = {
  __typename?: "Mutation";
  shopBannerCreate?: {__typename?: "ShopBannerCreatePayload"; shopBanner?: {__typename: "ShopBanner"} | null} | null;
};

export type UpdateBannerMutationVariables = Exact<{
  shopBannerId: Scalars["ID"]["input"];
  input: ShopBannerInput;
}>;

export type UpdateBannerMutation = {
  __typename?: "Mutation";
  shopBannerUpdate?: {__typename?: "ShopBannerUpdatePayload"; shopBanner?: {__typename: "ShopBanner"} | null} | null;
};

export type DeleteBannerMutationVariables = Exact<{
  shopBannerId: Scalars["ID"]["input"];
}>;

export type DeleteBannerMutation = {
  __typename?: "Mutation";
  shopBannerDelete?: {__typename?: "ShopBannerDeletePayload"; shopBanner?: {__typename: "ShopBanner"} | null} | null;
};

export type CreateAnnouncementBarMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: AnnouncementInput;
}>;

export type CreateAnnouncementBarMutation = {
  __typename?: "Mutation";
  announcementCreate?: {
    __typename?: "AnnouncementCreatePayload";
    announcement?: {__typename: "Announcement"} | null;
  } | null;
};

export type UpdateAnnouncementBarMutationVariables = Exact<{
  announcementId: Scalars["ID"]["input"];
  input: AnnouncementInput;
}>;

export type UpdateAnnouncementBarMutation = {
  __typename?: "Mutation";
  announcementUpdate?: {
    __typename?: "AnnouncementUpdatePayload";
    announcement?: {__typename: "Announcement"} | null;
  } | null;
};

export type DeleteAnnouncementBarMutationVariables = Exact<{
  announcementId: Scalars["ID"]["input"];
}>;

export type DeleteAnnouncementBarMutation = {
  __typename?: "Mutation";
  announcementDelete?: {
    __typename?: "AnnouncementDeletePayload";
    announcement?: {__typename: "Announcement"} | null;
  } | null;
};

export type CreateBrandMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: BrandInput;
}>;

export type CreateBrandMutation = {
  __typename?: "Mutation";
  brandCreate?: {__typename?: "BrandCreatePayload"; brand?: {__typename: "Brand"} | null} | null;
};

export type UpdateBrandMutationVariables = Exact<{
  brandId: Scalars["ID"]["input"];
  input: BrandInput;
}>;

export type UpdateBrandMutation = {
  __typename?: "Mutation";
  brandUpdate?: {__typename?: "BrandUpdatePayload"; brand?: {__typename: "Brand"} | null} | null;
};

export type CreateBusinessMemberInvitationMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  input: BusinessMemberInput;
}>;

export type CreateBusinessMemberInvitationMutation = {
  __typename?: "Mutation";
  businessMemberCreate?: {__typename: "BusinessMemberCreatePayload"} | null;
};

export type UpdateBusinessMemberInfoMutationVariables = Exact<{
  businessMemberId: Scalars["ID"]["input"];
  input: BusinessMemberInput;
}>;

export type UpdateBusinessMemberInfoMutation = {
  __typename?: "Mutation";
  businessMemberUpdate?: {__typename: "BusinessMemberUpdatePayload"} | null;
};

export type ResendBusinessMemberInvitationMutationVariables = Exact<{
  businessMemberId: Scalars["ID"]["input"];
}>;

export type ResendBusinessMemberInvitationMutation = {
  __typename?: "Mutation";
  businessMemberResend?: {__typename: "BusinessMemberResendPayload"} | null;
};

export type DeleteBusinessMemberInvitationMutationVariables = Exact<{
  businessMemberId: Scalars["ID"]["input"];
}>;

export type DeleteBusinessMemberInvitationMutation = {
  __typename?: "Mutation";
  businessMemberDelete?: {__typename: "BusinessMemberDeletePayload"} | null;
};

export type CreateCustomerMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: CustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  customerCreate?: {
    __typename?: "CustomerCreatePayload";
    customer?: {
      __typename?: "Customer";
      id: string;
      currency: string;
      name: string;
      email: string;
      billingEmail?: string | null;
      ccInvoices: boolean;
      country: string;
      kind: CustomerKind;
      paymentTerms?: string | null;
      taxId?: string | null;
      exciseNumber?: string | null;
      exciseTaxWarehouse?: string | null;
      exciseWarehouseKeeper?: string | null;
      exciseDestinationKind?: ExciseDestinationKind | null;
      legalDrinkingAge?: boolean | null;
      ordersCount: number;
      tags: Array<string>;
      consents: Array<{
        __typename?: "Consent";
        acceptedAt: any;
        description: string;
        kind: ConsentKind;
        version: string;
      }>;
      mailingAddresses: Array<{
        __typename?: "MailingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }>;
      billingAddresses?: Array<{
        __typename?: "BillingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }> | null;
      pricingGroup?: {
        __typename?: "PricingGroup";
        id: string;
        name: string;
        description?: string | null;
        priceBase: PricingGroupPriceBase;
        customersCount?: number | null;
        discount: number;
        lastActivityAt?: any | null;
        createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateCustomerMutationVariables = Exact<{
  customerId: Scalars["ID"]["input"];
  input: CustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  customerUpdate?: {__typename?: "CustomerUpdatePayload"; customer?: {__typename: "Customer"} | null} | null;
};

export type CreateLocationMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: LocationInput;
}>;

export type CreateLocationMutation = {
  __typename?: "Mutation";
  locationCreate?: {__typename?: "LocationCreatePayload"; location?: {__typename: "Location"} | null} | null;
};

export type UpdateLocationMutationVariables = Exact<{
  locationId: Scalars["ID"]["input"];
  input: LocationInput;
}>;

export type UpdateLocationMutation = {
  __typename?: "Mutation";
  locationUpdate?: {__typename?: "LocationUpdatePayload"; location?: {__typename: "Location"} | null} | null;
};

export type CreateCompanyMutationVariables = Exact<{
  input: MakerInput;
}>;

export type CreateCompanyMutation = {
  __typename?: "Mutation";
  makerCreate?: {__typename?: "MakerCreatePayload"; maker?: {__typename: "Maker"} | null} | null;
};

export type UpdateCompanyMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: MakerInput;
}>;

export type UpdateCompanyMutation = {
  __typename?: "Mutation";
  makerUpdate?: {__typename?: "MakerUpdatePayload"; maker?: {__typename: "Maker"} | null} | null;
};

export type CreateBankAccountMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: BankAccountInput;
}>;

export type CreateBankAccountMutation = {
  __typename?: "Mutation";
  bankAccountCreate?: {
    __typename?: "BankAccountCreatePayload";
    bankAccount?: {__typename: "BankAccount"} | null;
  } | null;
};

export type UpdateBankAccountMutationVariables = Exact<{
  bankAccountId: Scalars["ID"]["input"];
  input: BankAccountInput;
}>;

export type UpdateBankAccountMutation = {
  __typename?: "Mutation";
  bankAccountUpdate?: {
    __typename?: "BankAccountUpdatePayload";
    bankAccount?: {__typename: "BankAccount"} | null;
  } | null;
};

export type MakerAcceptTermsMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: AcceptTermsInput;
}>;

export type MakerAcceptTermsMutation = {
  __typename?: "Mutation";
  makerAcceptTerms?: {__typename?: "MakerAcceptTermsPayload"; maker?: {__typename: "Maker"} | null} | null;
};

export type MakerUpdateTermsMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: TermsInput;
}>;

export type MakerUpdateTermsMutation = {
  __typename?: "Mutation";
  makerUpdateTerms?: {__typename?: "MakerUpdateTermsPayload"; maker?: {__typename: "Maker"} | null} | null;
};

export type ChargeMethodSetupMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: ChargeMethodSetupInput;
}>;

export type ChargeMethodSetupMutation = {
  __typename?: "Mutation";
  chargeMethodSetup?: {
    __typename?: "ChargeMethodSetupPayload";
    chargeMethodSetup?: {__typename?: "ChargeMethodSetup"; setupUrl: string} | null;
  } | null;
};

export type OnboardingCompleteMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type OnboardingCompleteMutation = {
  __typename?: "Mutation";
  signingComplete?: {__typename?: "SigningCompletePayload"; maker?: {__typename: "Maker"} | null} | null;
};

export type CreateOrderMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: OrderInput;
}>;

export type CreateOrderMutation = {
  __typename?: "Mutation";
  orderCreate?: {__typename?: "OrderCreatePayload"; order?: {__typename?: "Order"; id: string} | null} | null;
};

export type UpdateOrderMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
  input: OrderInput;
}>;

export type UpdateOrderMutation = {
  __typename?: "Mutation";
  orderUpdate?: {__typename?: "OrderUpdatePayload"; order?: {__typename?: "Order"; id: string} | null} | null;
};

export type SubmitOrderMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type SubmitOrderMutation = {
  __typename?: "Mutation";
  orderSubmit?: {__typename?: "OrderSubmitPayload"; order?: {__typename: "Order"} | null} | null;
};

export type DeleteOrderMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type DeleteOrderMutation = {
  __typename?: "Mutation";
  orderDelete?: {__typename?: "OrderDeletePayload"; order?: {__typename: "Order"} | null} | null;
};

export type MarkOrderAwaitingPickupMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type MarkOrderAwaitingPickupMutation = {
  __typename?: "Mutation";
  orderMarkAwaitingPickup?: {
    __typename?: "OrderMarkAwaitingPickupPayload";
    order?: {__typename: "Order"} | null;
  } | null;
};

export type MarkOrderDeliveredMutationVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type MarkOrderDeliveredMutation = {
  __typename?: "Mutation";
  orderMarkDelivered?: {__typename?: "OrderMarkDeliveredPayload"; order?: {__typename: "Order"} | null} | null;
};

export type ConfirmPickingListMutationVariables = Exact<{
  signedId?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
  trackingUrl?: InputMaybe<Scalars["String"]["input"]>;
  orderItems: Array<PickingListItemInput> | PickingListItemInput;
}>;

export type ConfirmPickingListMutation = {
  __typename?: "Mutation";
  pickingListConfirm?: {
    __typename?: "PickingListConfirmPayload";
    pickingList?: {
      __typename?: "PickingList";
      contactEmail: string;
      fulfillmentEmail?: string | null;
      order: {__typename?: "Order"; number: string};
    } | null;
  } | null;
};

export type CreatePricingGroupMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: PricingGroupInput;
}>;

export type CreatePricingGroupMutation = {
  __typename?: "Mutation";
  pricingGroupCreate?: {
    __typename?: "PricingGroupCreatePayload";
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  } | null;
};

export type UpdatePricingGroupMutationVariables = Exact<{
  pricingGroupId: Scalars["ID"]["input"];
  input: PricingGroupInput;
}>;

export type UpdatePricingGroupMutation = {
  __typename?: "Mutation";
  pricingGroupUpdate?: {
    __typename?: "PricingGroupUpdatePayload";
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  } | null;
};

export type DeletePricingGroupMutationVariables = Exact<{
  pricingGroupId: Scalars["ID"]["input"];
}>;

export type DeletePricingGroupMutation = {
  __typename?: "Mutation";
  pricingGroupDelete?: {
    __typename?: "PricingGroupDeletePayload";
    pricingGroup?: {__typename?: "PricingGroup"; id: string} | null;
  } | null;
};

export type AssignPricingGroupMutationVariables = Exact<{
  pricingGroupId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
}>;

export type AssignPricingGroupMutation = {
  __typename?: "Mutation";
  pricingGroupAssign?: {
    __typename?: "PricingGroupAssignPayload";
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  } | null;
};

export type CreateBeverageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: BeverageInput;
}>;

export type CreateBeverageMutation = {
  __typename?: "Mutation";
  createBeverage?: {
    __typename?: "CreateBeveragePayload";
    product?: {__typename?: "Beverage"; id: string} | null;
  } | null;
};

export type UpdateBeverageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: BeverageInput;
}>;

export type UpdateBeverageMutation = {
  __typename?: "Mutation";
  updateBeverage?: {__typename?: "UpdateBeveragePayload"; product?: {__typename: "Beverage"} | null} | null;
};

export type CreateBundleProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: ProductBundleInput;
}>;

export type CreateBundleProductMutation = {
  __typename?: "Mutation";
  createProductBundle?: {
    __typename?: "CreateProductBundlePayload";
    productBundle?: {__typename?: "ProductBundle"; id: string} | null;
  } | null;
};

export type UpdateBundleProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: ProductBundleInput;
}>;

export type UpdateBundleProductMutation = {
  __typename?: "Mutation";
  updateProductBundle?: {
    __typename?: "UpdateProductBundlePayload";
    productBundle?: {__typename: "ProductBundle"} | null;
  } | null;
};

export type CreateExtraProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: ExtraInput;
}>;

export type CreateExtraProductMutation = {
  __typename?: "Mutation";
  extraCreate?: {__typename?: "ExtraCreatePayload"; extra?: {__typename?: "Extra"; id: string} | null} | null;
};

export type UpdateExtraProductMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  input: ExtraInput;
}>;

export type UpdateExtraProductMutation = {
  __typename?: "Mutation";
  extraUpdate?: {__typename?: "ExtraUpdatePayload"; extra?: {__typename: "Extra"} | null} | null;
};

export type DeleteProductMutationVariables = Exact<{
  productId: Scalars["ID"]["input"];
}>;

export type DeleteProductMutation = {
  __typename?: "Mutation";
  deleteProduct?: {
    __typename?: "DeleteProductPayload";
    product?: {__typename: "Beverage"} | {__typename: "Extra"} | {__typename: "ProductBundle"} | null;
  } | null;
};

export type UpdateShippingZoneMutationVariables = Exact<{
  shippingZoneId: Scalars["ID"]["input"];
  input: ShippingZoneInput;
}>;

export type UpdateShippingZoneMutation = {
  __typename?: "Mutation";
  shippingZoneUpdate?: {
    __typename?: "ShippingZoneUpdatePayload";
    shippingZone?: {__typename: "ShippingZone"} | null;
  } | null;
};

export type CreateStockMovementMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: StockMovementInput;
}>;

export type CreateStockMovementMutation = {
  __typename?: "Mutation";
  stockMovementCreate?: {
    __typename?: "StockMovementCreatePayload";
    stockMovement?: {__typename?: "StockMovement"; id: string} | null;
  } | null;
};

export type SubmitStockMovementMutationVariables = Exact<{
  stockMovementId: Scalars["ID"]["input"];
}>;

export type SubmitStockMovementMutation = {
  __typename?: "Mutation";
  stockMovementSubmit?: {
    __typename?: "StockMovementSubmitPayload";
    stockMovement?: {__typename: "StockMovement"} | null;
  } | null;
};

export type UpdateStockMovementMutationVariables = Exact<{
  stockMovementId: Scalars["ID"]["input"];
  input: StockMovementInput;
}>;

export type UpdateStockMovementMutation = {
  __typename?: "Mutation";
  stockMovementUpdate?: {
    __typename?: "StockMovementUpdatePayload";
    stockMovement?: {__typename: "StockMovement"} | null;
  } | null;
};

export type ConfirmTracezillaOrderMutationVariables = Exact<{
  tracezillaOrderId?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ConfirmTracezillaOrderMutation = {
  __typename?: "Mutation";
  tracezillaConfirm?: {
    __typename?: "TracezillaConfirmPayload";
    tracezillaOrder?: {
      __typename?: "TracezillaOrder";
      number: string;
      order: {__typename?: "Order"; id: string};
    } | null;
  } | null;
};

export type ResendInvitationMutationVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
}>;

export type ResendInvitationMutation = {
  __typename?: "Mutation";
  invitationResend?: {__typename: "InvitationResendPayload"} | null;
};

export type CreateInvitationMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: InvitationInput;
}>;

export type CreateInvitationMutation = {
  __typename?: "Mutation";
  invitationCreate?: {__typename: "InvitationCreatePayload"} | null;
};

export type UpdateWarehouseMutationVariables = Exact<{
  warehouseId: Scalars["ID"]["input"];
  input: WarehouseInput;
}>;

export type UpdateWarehouseMutation = {
  __typename?: "Mutation";
  warehouseUpdate?: {__typename?: "WarehouseUpdatePayload"; warehouse?: {__typename: "Warehouse"} | null} | null;
};

export type UpdateStoreMutationVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: StoreInput;
}>;

export type UpdateStoreMutation = {
  __typename?: "Mutation";
  storeUpdate?: {__typename?: "StoreUpdatePayload"; store?: {__typename: "Store"} | null} | null;
};

export type UpdateShopMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: ShopInput;
}>;

export type UpdateShopMutation = {
  __typename?: "Mutation";
  shopUpdate?: {__typename?: "ShopUpdatePayload"; shop?: {__typename: "Shop"} | null} | null;
};

export type ImportShopProductsMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: ShopCopyProductsInput;
}>;

export type ImportShopProductsMutation = {
  __typename?: "Mutation";
  shopCopyProducts?: {
    __typename?: "ShopCopyProductsPayload";
    data?: {
      __typename?: "ShopCopyProducts";
      collectionsMigrated: number;
      collectionsSkipped: number;
      collectionsTotal: number;
      productsMigrated: number;
      productsSkipped: number;
      productsTotal: number;
    } | null;
  } | null;
};

export type CreateCollectionMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: CollectionInput;
}>;

export type CreateCollectionMutation = {
  __typename?: "Mutation";
  collectionCreate?: {
    __typename?: "CollectionCreatePayload";
    collection?: {__typename?: "Collection"; id: string} | null;
  } | null;
};

export type DeleteCollectionMutationVariables = Exact<{
  collectionId: Scalars["ID"]["input"];
}>;

export type DeleteCollectionMutation = {
  __typename?: "Mutation";
  collectionDelete?: {__typename?: "CollectionDeletePayload"; collection?: {__typename: "Collection"} | null} | null;
};

export type UpdateCollectionMutationVariables = Exact<{
  collectionId: Scalars["ID"]["input"];
  input: CollectionInput;
}>;

export type UpdateCollectionMutation = {
  __typename?: "Mutation";
  collectionUpdate?: {__typename?: "CollectionUpdatePayload"; collection?: {__typename: "Collection"} | null} | null;
};

export type ReorderCollectionsMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: Array<CollectionReorderInput> | CollectionReorderInput;
}>;

export type ReorderCollectionsMutation = {
  __typename?: "Mutation";
  collectionReorder?: {
    __typename?: "CollectionReorderPayload";
    collections?: Array<{__typename: "Collection"}> | null;
  } | null;
};

export type UpdateCollectionMetaMutationVariables = Exact<{
  collectionId: Scalars["ID"]["input"];
  input: CollectionMetaInput;
}>;

export type UpdateCollectionMetaMutation = {
  __typename?: "Mutation";
  collectionUpdateMeta?: {
    __typename?: "CollectionUpdateMetaPayload";
    collection?: {__typename: "Collection"} | null;
  } | null;
};

export type CreateDiscountAmountMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: DiscountAmountInput;
}>;

export type CreateDiscountAmountMutation = {
  __typename?: "Mutation";
  discountAmountCreate?: {
    __typename?: "DiscountAmountCreatePayload";
    discount?: {__typename: "DiscountAmount"} | null;
  } | null;
};

export type UpdateDiscountAmountMutationVariables = Exact<{
  discountId: Scalars["ID"]["input"];
  input: DiscountAmountInput;
}>;

export type UpdateDiscountAmountMutation = {
  __typename?: "Mutation";
  discountAmountUpdate?: {
    __typename?: "DiscountAmountUpdatePayload";
    discount?: {__typename: "DiscountAmount"} | null;
  } | null;
};

export type CreateDiscountPercentageMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: DiscountPercentageInput;
}>;

export type CreateDiscountPercentageMutation = {
  __typename?: "Mutation";
  discountPercentageCreate?: {
    __typename?: "DiscountPercentageCreatePayload";
    discount?: {__typename: "DiscountPercentage"} | null;
  } | null;
};

export type UpdateDiscountPercentageMutationVariables = Exact<{
  discountId: Scalars["ID"]["input"];
  input: DiscountPercentageInput;
}>;

export type UpdateDiscountPercentageMutation = {
  __typename?: "Mutation";
  discountPercentageUpdate?: {
    __typename?: "DiscountPercentageUpdatePayload";
    discount?: {__typename: "DiscountPercentage"} | null;
  } | null;
};

export type DeleteDiscountMutationVariables = Exact<{
  discountId: Scalars["ID"]["input"];
}>;

export type DeleteDiscountMutation = {
  __typename?: "Mutation";
  discountDelete?: {
    __typename?: "DiscountDeletePayload";
    discount?: {__typename?: "DiscountAmount"; code: string} | {__typename?: "DiscountPercentage"; code: string} | null;
  } | null;
};

export type CreateWebshopProductMutationVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  input: ShopProductInput;
}>;

export type CreateWebshopProductMutation = {
  __typename?: "Mutation";
  shopProductCreate?: {
    __typename?: "ShopProductCreatePayload";
    shopProduct?: {__typename?: "ShopProduct"; name: string; id: string} | null;
  } | null;
};

export type DeleteWebshopProductMutationVariables = Exact<{
  shopProductId: Scalars["ID"]["input"];
}>;

export type DeleteWebshopProductMutation = {
  __typename?: "Mutation";
  shopProductDelete?: {
    __typename?: "ShopProductDeletePayload";
    shopProduct?: {__typename: "ShopProduct"} | null;
  } | null;
};

export type UpdateWebshopProductMutationVariables = Exact<{
  shopProductId: Scalars["ID"]["input"];
  input: ShopProductInput;
}>;

export type UpdateWebshopProductMutation = {
  __typename?: "Mutation";
  shopProductUpdate?: {
    __typename?: "ShopProductUpdatePayload";
    shopProduct?: {__typename: "ShopProduct"} | null;
  } | null;
};

export type UpdateWebshopProductMetaMutationVariables = Exact<{
  shopProductId: Scalars["ID"]["input"];
  input: ShopProductMetaInput;
}>;

export type UpdateWebshopProductMetaMutation = {
  __typename?: "Mutation";
  shopProductUpdateMeta?: {
    __typename?: "ShopProductUpdateMetaPayload";
    shopProduct?: {__typename: "ShopProduct"} | null;
  } | null;
};

export type PriceSummaryFragment = {__typename?: "Money"; amount: any; currency: string};

export type PageInfoSummaryFragment = {
  __typename?: "PageInfo";
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  startCursor?: string | null;
  endCursor?: string | null;
};

export type CommonMailingAddressSummaryFragment = {
  __typename?: "MailingAddress";
  city: string;
  country: string;
  company?: string | null;
  line1: string;
  line2?: string | null;
  name?: string | null;
  phone?: string | null;
  postcode: string;
};

export type CommonBillingAddressSummaryFragment = {
  __typename?: "BillingAddress";
  city: string;
  country: string;
  company?: string | null;
  line1: string;
  line2?: string | null;
  name?: string | null;
  phone?: string | null;
  postcode: string;
};

export type PaginatedAllTransactionsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<TransactionFilter>;
}>;

export type PaginatedAllTransactionsQuery = {
  __typename?: "Query";
  transactions?: {
    __typename?: "TransactionConnection";
    nodes: Array<{
      __typename?: "Transaction";
      id: string;
      kind: TransactionKind;
      orderId?: string | null;
      orderNumber?: string | null;
      text?: string | null;
      bookedAt: any;
      amount: {__typename?: "Money"; amount: any; currency: string};
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetFinanceOverviewQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetFinanceOverviewQuery = {
  __typename?: "Query";
  financeOverview?: {
    __typename?: "FinanceOverview";
    balanceOverview?: {
      __typename?: "BalanceOverview";
      period: string;
      balance: {__typename?: "Money"; amount: any; currency: string};
      fees: Array<{
        __typename?: "TransactionFee";
        kind: string;
        text: string;
        amount: {__typename?: "Money"; amount: any; currency: string};
      }>;
      totalFees: {__typename?: "Money"; amount: any; currency: string};
      totalPayments: {__typename?: "Money"; amount: any; currency: string};
    } | null;
    outstandingPayments?: {
      __typename?: "OutstandingPayments";
      overdueCount: number;
      unpaidCount: number;
      outstandingAmount: {__typename?: "Money"; amount: any; currency: string};
      overdueAmount: {__typename?: "Money"; amount: any; currency: string};
      unpaidAmount: {__typename?: "Money"; amount: any; currency: string};
    } | null;
  } | null;
};

export type FinanceOverviewSummaryFragment = {
  __typename?: "FinanceOverview";
  balanceOverview?: {
    __typename?: "BalanceOverview";
    period: string;
    balance: {__typename?: "Money"; amount: any; currency: string};
    fees: Array<{
      __typename?: "TransactionFee";
      kind: string;
      text: string;
      amount: {__typename?: "Money"; amount: any; currency: string};
    }>;
    totalFees: {__typename?: "Money"; amount: any; currency: string};
    totalPayments: {__typename?: "Money"; amount: any; currency: string};
  } | null;
  outstandingPayments?: {
    __typename?: "OutstandingPayments";
    overdueCount: number;
    unpaidCount: number;
    outstandingAmount: {__typename?: "Money"; amount: any; currency: string};
    overdueAmount: {__typename?: "Money"; amount: any; currency: string};
    unpaidAmount: {__typename?: "Money"; amount: any; currency: string};
  } | null;
};

export type BalanceOverviewSummaryFragment = {
  __typename?: "BalanceOverview";
  period: string;
  balance: {__typename?: "Money"; amount: any; currency: string};
  fees: Array<{
    __typename?: "TransactionFee";
    kind: string;
    text: string;
    amount: {__typename?: "Money"; amount: any; currency: string};
  }>;
  totalFees: {__typename?: "Money"; amount: any; currency: string};
  totalPayments: {__typename?: "Money"; amount: any; currency: string};
};

export type OutstandingPaymentsSummaryFragment = {
  __typename?: "OutstandingPayments";
  overdueCount: number;
  unpaidCount: number;
  outstandingAmount: {__typename?: "Money"; amount: any; currency: string};
  overdueAmount: {__typename?: "Money"; amount: any; currency: string};
  unpaidAmount: {__typename?: "Money"; amount: any; currency: string};
};

export type TransactionsSummaryFragment = {
  __typename?: "Transaction";
  id: string;
  kind: TransactionKind;
  orderId?: string | null;
  orderNumber?: string | null;
  text?: string | null;
  bookedAt: any;
  amount: {__typename?: "Money"; amount: any; currency: string};
};

export type TransactionFeeSummaryFragment = {
  __typename?: "TransactionFee";
  kind: string;
  text: string;
  amount: {__typename?: "Money"; amount: any; currency: string};
};

export type GetShopBannerQueryVariables = Exact<{
  bannerId: Scalars["ID"]["input"];
}>;

export type GetShopBannerQuery = {
  __typename?: "Query";
  shopBanner?: {
    __typename?: "ShopBanner";
    id: string;
    status: ShopBannerStatus;
    name: string;
    headingText?: string | null;
    subheadingText?: string | null;
    buttonLink?: string | null;
    buttonLabel?: string | null;
    backgroundImageUrl?: string | null;
    backgroundVideoUrl?: string | null;
    aspectRatio: AspectRatioMode;
    overlayBg: boolean;
    collections: Array<{
      __typename?: "Collection";
      id: string;
      name: string;
      htmlDescription?: string | null;
      jsonDescription?: string | null;
      status: CollectionStatus;
      position: number;
      ogImageUrl?: string | null;
      salesChannels?: Array<SalesChannels> | null;
      shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
      items: Array<{
        __typename?: "CollectionItem";
        position: number;
        createdAt: any;
        shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
      }>;
    }>;
  } | null;
};

export type GetShopBannersQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
}>;

export type GetShopBannersQuery = {
  __typename?: "Query";
  shopBanners: Array<{
    __typename?: "ShopBanner";
    id: string;
    status: ShopBannerStatus;
    name: string;
    headingText?: string | null;
    subheadingText?: string | null;
    buttonLink?: string | null;
    buttonLabel?: string | null;
    backgroundImageUrl?: string | null;
    backgroundVideoUrl?: string | null;
    aspectRatio: AspectRatioMode;
    overlayBg: boolean;
    collections: Array<{
      __typename?: "Collection";
      id: string;
      name: string;
      htmlDescription?: string | null;
      jsonDescription?: string | null;
      status: CollectionStatus;
      position: number;
      ogImageUrl?: string | null;
      salesChannels?: Array<SalesChannels> | null;
      shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
      items: Array<{
        __typename?: "CollectionItem";
        position: number;
        createdAt: any;
        shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
      }>;
    }>;
  }>;
};

export type GetAnnouncementBarsQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
}>;

export type GetAnnouncementBarsQuery = {
  __typename?: "Query";
  announcements?: Array<{
    __typename?: "Announcement";
    id: string;
    status: AnnouncementStatus;
    text: string;
    salesChannels: Array<SalesChannels>;
  }> | null;
};

export type GetAnnouncementBarByIdQueryVariables = Exact<{
  announcementId: Scalars["ID"]["input"];
}>;

export type GetAnnouncementBarByIdQuery = {
  __typename?: "Query";
  announcement?: {
    __typename?: "Announcement";
    id: string;
    status: AnnouncementStatus;
    text: string;
    salesChannels: Array<SalesChannels>;
  } | null;
};

export type PromoBannerSummaryFragment = {
  __typename?: "ShopBanner";
  id: string;
  status: ShopBannerStatus;
  name: string;
  headingText?: string | null;
  subheadingText?: string | null;
  buttonLink?: string | null;
  buttonLabel?: string | null;
  backgroundImageUrl?: string | null;
  backgroundVideoUrl?: string | null;
  aspectRatio: AspectRatioMode;
  overlayBg: boolean;
  collections: Array<{
    __typename?: "Collection";
    id: string;
    name: string;
    htmlDescription?: string | null;
    jsonDescription?: string | null;
    status: CollectionStatus;
    position: number;
    ogImageUrl?: string | null;
    salesChannels?: Array<SalesChannels> | null;
    shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
    items: Array<{
      __typename?: "CollectionItem";
      position: number;
      createdAt: any;
      shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
    }>;
  }>;
};

export type AnnouncementBarSummaryFragment = {
  __typename?: "Announcement";
  id: string;
  status: AnnouncementStatus;
  text: string;
  salesChannels: Array<SalesChannels>;
};

export type GetBrandByIdQueryVariables = Exact<{
  brandId: Scalars["ID"]["input"];
}>;

export type GetBrandByIdQuery = {
  __typename?: "Query";
  brand?: {
    __typename?: "Brand";
    slug: string;
    coverUrl?: string | null;
    descriptionHtml: any;
    descriptionJson: any;
    facebookUrl?: string | null;
    homepageUrl?: string | null;
    instagramUrl?: string | null;
    linkedinUrl?: string | null;
    tagline: any;
    id: string;
    name: string;
    logoUrl?: string | null;
  } | null;
};

export type GetAllBrandsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllBrandsQuery = {
  __typename?: "Query";
  brands: Array<{__typename?: "Brand"; id: string; name: string; logoUrl?: string | null}>;
};

export type BrandShortSummaryFragment = {__typename?: "Brand"; id: string; name: string; logoUrl?: string | null};

export type BrandSummaryFragment = {
  __typename?: "Brand";
  slug: string;
  coverUrl?: string | null;
  descriptionHtml: any;
  descriptionJson: any;
  facebookUrl?: string | null;
  homepageUrl?: string | null;
  instagramUrl?: string | null;
  linkedinUrl?: string | null;
  tagline: any;
  id: string;
  name: string;
  logoUrl?: string | null;
};

export type GetCarriersByOrderQueryVariables = Exact<{
  order: OrderInput;
}>;

export type GetCarriersByOrderQuery = {
  __typename?: "Query";
  findCarriers: Array<{
    __typename?: "Carrier";
    name: string;
    kind: CarrierKind;
    parcel: boolean;
    palletQuarter: boolean;
    palletHalf: boolean;
    palletFull: boolean;
    deliveryCollect: boolean;
    deliveryHome: boolean;
    deliveryTime: boolean;
    pickupTime: boolean;
    estimatedPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
    deliveryTimeOptions?: Array<{__typename?: "TimeOptions"; timeFrom?: string | null; timeTo?: string | null}> | null;
    pickupTimeOptions?: Array<{__typename?: "TimeOptions"; timeFrom?: string | null; timeTo?: string | null}> | null;
  }>;
};

export type CarrierSummaryFragment = {
  __typename?: "Carrier";
  name: string;
  kind: CarrierKind;
  parcel: boolean;
  palletQuarter: boolean;
  palletHalf: boolean;
  palletFull: boolean;
  deliveryCollect: boolean;
  deliveryHome: boolean;
  deliveryTime: boolean;
  pickupTime: boolean;
  estimatedPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
  deliveryTimeOptions?: Array<{__typename?: "TimeOptions"; timeFrom?: string | null; timeTo?: string | null}> | null;
  pickupTimeOptions?: Array<{__typename?: "TimeOptions"; timeFrom?: string | null; timeTo?: string | null}> | null;
};

export type CurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type CurrentUserQuery = {
  __typename?: "Query";
  current?: {
    __typename?: "Current";
    impersonation: boolean;
    currentMaker?: {
      __typename?: "CurrentMaker";
      id: string;
      name: string;
      addons: Array<Addon>;
      countryConstraints: Array<string>;
      features: Array<string>;
      rights: Array<AuthRight>;
      trade: boolean;
      tradeName?: string | null;
      onboarding?: {
        __typename?: "Onboarding";
        contractUrl?: string | null;
        termsToAccept: Array<{
          __typename?: "Terms";
          kind: string;
          name: string;
          url: string;
          version: string;
          updateNotes?: Array<string> | null;
        }>;
        termsToUpdate: Array<{
          __typename?: "Terms";
          kind: string;
          name: string;
          url: string;
          version: string;
          updateNotes?: Array<string> | null;
        }>;
        chargeMethods: Array<{__typename?: "ChargeMethod"; id: string; kind: string; details: any; status: string}>;
      } | null;
      markets?: Array<{__typename?: "MakerMarket"; id: string; kind: MakerMarketKind; country: string}> | null;
      csm?: {__typename?: "CsmUser"; name: string; email: string} | null;
    } | null;
    currentUser: {
      __typename?: "CurrentUser";
      email: string;
      name?: string | null;
      locale?: string | null;
      timezone?: string | null;
    };
  } | null;
};

export type GetActivePlanQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetActivePlanQuery = {
  __typename?: "Query";
  activePlan?: {
    __typename?: "Plan";
    kind: string;
    name: string;
    activeFrom: any;
    terms: Array<{
      __typename?: "Terms";
      kind: string;
      name: string;
      url: string;
      version: string;
      updateNotes?: Array<string> | null;
    }>;
  } | null;
};

export type GetCompanyInfoQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetCompanyInfoQuery = {
  __typename?: "Query";
  maker?: {
    __typename?: "Maker";
    name: string;
    trade: boolean;
    tradeName?: string | null;
    country: string;
    taxId?: string | null;
    exciseNumber?: string | null;
    invoiceEmail?: string | null;
    logoUrl?: string | null;
    printLogoUrl?: string | null;
    faviconUrl?: string | null;
    contactName?: string | null;
    contactEmail?: string | null;
    contactPhone?: string | null;
    contractUrl?: string | null;
    address?: {__typename?: "Address"; city: string; line1: string; line2?: string | null; postcode: string} | null;
    ecoCertificate?: {
      __typename?: "MakerCertificate";
      activeTo: any;
      documentUrl: string;
      id: string;
      kind: MakerCertificateKind;
      name: string;
    } | null;
    chargeMethods?: Array<{__typename?: "ChargeMethod"; id: string; kind: string; details: any; status: string}> | null;
    bankAccounts?: Array<{__typename?: "BankAccount"; id: string; currency: string; details: any}> | null;
  } | null;
};

export type CurrentMakerSummaryFragment = {
  __typename?: "CurrentMaker";
  id: string;
  name: string;
  addons: Array<Addon>;
  countryConstraints: Array<string>;
  features: Array<string>;
  rights: Array<AuthRight>;
  trade: boolean;
  tradeName?: string | null;
  onboarding?: {
    __typename?: "Onboarding";
    contractUrl?: string | null;
    termsToAccept: Array<{
      __typename?: "Terms";
      kind: string;
      name: string;
      url: string;
      version: string;
      updateNotes?: Array<string> | null;
    }>;
    termsToUpdate: Array<{
      __typename?: "Terms";
      kind: string;
      name: string;
      url: string;
      version: string;
      updateNotes?: Array<string> | null;
    }>;
    chargeMethods: Array<{__typename?: "ChargeMethod"; id: string; kind: string; details: any; status: string}>;
  } | null;
  markets?: Array<{__typename?: "MakerMarket"; id: string; kind: MakerMarketKind; country: string}> | null;
  csm?: {__typename?: "CsmUser"; name: string; email: string} | null;
};

export type CurrentUserSummaryFragment = {
  __typename?: "CurrentUser";
  email: string;
  name?: string | null;
  locale?: string | null;
  timezone?: string | null;
};

export type CompanySummaryFragment = {
  __typename?: "Maker";
  name: string;
  trade: boolean;
  tradeName?: string | null;
  country: string;
  taxId?: string | null;
  exciseNumber?: string | null;
  invoiceEmail?: string | null;
  logoUrl?: string | null;
  printLogoUrl?: string | null;
  faviconUrl?: string | null;
  contactName?: string | null;
  contactEmail?: string | null;
  contactPhone?: string | null;
  contractUrl?: string | null;
  address?: {__typename?: "Address"; city: string; line1: string; line2?: string | null; postcode: string} | null;
  ecoCertificate?: {
    __typename?: "MakerCertificate";
    activeTo: any;
    documentUrl: string;
    id: string;
    kind: MakerCertificateKind;
    name: string;
  } | null;
  chargeMethods?: Array<{__typename?: "ChargeMethod"; id: string; kind: string; details: any; status: string}> | null;
  bankAccounts?: Array<{__typename?: "BankAccount"; id: string; currency: string; details: any}> | null;
};

export type MakerMarketSummaryFragment = {
  __typename?: "MakerMarket";
  id: string;
  kind: MakerMarketKind;
  country: string;
};

export type PlanSummaryFragment = {
  __typename?: "Plan";
  kind: string;
  name: string;
  activeFrom: any;
  terms: Array<{
    __typename?: "Terms";
    kind: string;
    name: string;
    url: string;
    version: string;
    updateNotes?: Array<string> | null;
  }>;
};

export type TermsSummaryFragment = {
  __typename?: "Terms";
  kind: string;
  name: string;
  url: string;
  version: string;
  updateNotes?: Array<string> | null;
};

export type MakerCertificateSummaryFragment = {
  __typename?: "MakerCertificate";
  activeTo: any;
  documentUrl: string;
  id: string;
  kind: MakerCertificateKind;
  name: string;
};

export type ChargeMethodSummaryFragment = {
  __typename?: "ChargeMethod";
  id: string;
  kind: string;
  details: any;
  status: string;
};

export type BankAccountSummaryFragment = {__typename?: "BankAccount"; id: string; currency: string; details: any};

export type CsmUserSummaryFragment = {__typename?: "CsmUser"; name: string; email: string};

export type PaginatedAllCustomersQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<CustomerSorting>;
  filterBy?: InputMaybe<CustomerFilter>;
}>;

export type PaginatedAllCustomersQuery = {
  __typename?: "Query";
  allCustomers: {
    __typename?: "CustomerConnection";
    nodes: Array<{
      __typename?: "Customer";
      id: string;
      currency: string;
      name: string;
      email: string;
      billingEmail?: string | null;
      ccInvoices: boolean;
      country: string;
      kind: CustomerKind;
      paymentTerms?: string | null;
      taxId?: string | null;
      exciseNumber?: string | null;
      exciseTaxWarehouse?: string | null;
      exciseWarehouseKeeper?: string | null;
      exciseDestinationKind?: ExciseDestinationKind | null;
      legalDrinkingAge?: boolean | null;
      ordersCount: number;
      tags: Array<string>;
      consents: Array<{
        __typename?: "Consent";
        acceptedAt: any;
        description: string;
        kind: ConsentKind;
        version: string;
      }>;
      mailingAddresses: Array<{
        __typename?: "MailingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }>;
      billingAddresses?: Array<{
        __typename?: "BillingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }> | null;
      pricingGroup?: {
        __typename?: "PricingGroup";
        id: string;
        name: string;
        description?: string | null;
        priceBase: PricingGroupPriceBase;
        customersCount?: number | null;
        discount: number;
        lastActivityAt?: any | null;
        createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type GetCustomerByIdQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetCustomerByIdQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: string;
    currency: string;
    name: string;
    email: string;
    billingEmail?: string | null;
    ccInvoices: boolean;
    country: string;
    kind: CustomerKind;
    paymentTerms?: string | null;
    taxId?: string | null;
    exciseNumber?: string | null;
    exciseTaxWarehouse?: string | null;
    exciseWarehouseKeeper?: string | null;
    exciseDestinationKind?: ExciseDestinationKind | null;
    legalDrinkingAge?: boolean | null;
    ordersCount: number;
    tags: Array<string>;
    lastOrders: Array<{
      __typename?: "Order";
      id: string;
      number: string;
      createdAt: any;
      status: OrderStatus;
      netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
    }>;
    stats: {
      __typename?: "CustomerStats";
      ordersCount: number;
      averageOrderValue: {__typename?: "Money"; amount: any; currency: string};
      totalOrdersValue: {__typename?: "Money"; amount: any; currency: string};
    };
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
    mailingAddresses: Array<{
      __typename?: "MailingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }>;
    billingAddresses?: Array<{
      __typename?: "BillingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }> | null;
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  } | null;
};

export type ExportCustomersQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  filterBy?: InputMaybe<CustomerFilter>;
}>;

export type ExportCustomersQuery = {
  __typename?: "Query";
  exportCustomers: {__typename?: "CustomerExport"; exportUrl: string};
};

export type ExportSubscribersQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  filterBy: CustomerFilter;
  format?: InputMaybe<ExportFileTypes>;
}>;

export type ExportSubscribersQuery = {
  __typename?: "Query";
  exportSubscribers: {__typename?: "CustomerExport"; exportUrl: string};
};

export type GetBusinessMembersQueryVariables = Exact<{
  customerId: Scalars["ID"]["input"];
}>;

export type GetBusinessMembersQuery = {
  __typename?: "Query";
  businessMembers?: Array<{
    __typename?: "BusinessMember";
    acceptedAt?: any | null;
    email: string;
    id: string;
    name?: string | null;
    sentAt?: any | null;
    status: InvitationStatus;
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
  }> | null;
};

export type GetBusinessMemberByIdQueryVariables = Exact<{
  businessMemberId: Scalars["ID"]["input"];
}>;

export type GetBusinessMemberByIdQuery = {
  __typename?: "Query";
  businessMember?: {
    __typename?: "BusinessMember";
    acceptedAt?: any | null;
    email: string;
    id: string;
    name?: string | null;
    sentAt?: any | null;
    status: InvitationStatus;
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
  } | null;
};

export type CustomerShortSummaryFragment = {
  __typename?: "Customer";
  id: string;
  currency: string;
  name: string;
  email: string;
  billingEmail?: string | null;
  ccInvoices: boolean;
  country: string;
  kind: CustomerKind;
  paymentTerms?: string | null;
  taxId?: string | null;
  exciseNumber?: string | null;
  exciseTaxWarehouse?: string | null;
  exciseWarehouseKeeper?: string | null;
  exciseDestinationKind?: ExciseDestinationKind | null;
  legalDrinkingAge?: boolean | null;
  ordersCount: number;
  tags: Array<string>;
  consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
  mailingAddresses: Array<{
    __typename?: "MailingAddress";
    city: string;
    company?: string | null;
    country: string;
    default: boolean;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
    state?: string | null;
  }>;
  billingAddresses?: Array<{
    __typename?: "BillingAddress";
    city: string;
    company?: string | null;
    country: string;
    default: boolean;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
    state?: string | null;
  }> | null;
  pricingGroup?: {
    __typename?: "PricingGroup";
    id: string;
    name: string;
    description?: string | null;
    priceBase: PricingGroupPriceBase;
    customersCount?: number | null;
    discount: number;
    lastActivityAt?: any | null;
    createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
  } | null;
};

export type CustomerSummaryFragment = {
  __typename?: "Customer";
  id: string;
  currency: string;
  name: string;
  email: string;
  billingEmail?: string | null;
  ccInvoices: boolean;
  country: string;
  kind: CustomerKind;
  paymentTerms?: string | null;
  taxId?: string | null;
  exciseNumber?: string | null;
  exciseTaxWarehouse?: string | null;
  exciseWarehouseKeeper?: string | null;
  exciseDestinationKind?: ExciseDestinationKind | null;
  legalDrinkingAge?: boolean | null;
  ordersCount: number;
  tags: Array<string>;
  lastOrders: Array<{
    __typename?: "Order";
    id: string;
    number: string;
    createdAt: any;
    status: OrderStatus;
    netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
  }>;
  stats: {
    __typename?: "CustomerStats";
    ordersCount: number;
    averageOrderValue: {__typename?: "Money"; amount: any; currency: string};
    totalOrdersValue: {__typename?: "Money"; amount: any; currency: string};
  };
  consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
  mailingAddresses: Array<{
    __typename?: "MailingAddress";
    city: string;
    company?: string | null;
    country: string;
    default: boolean;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
    state?: string | null;
  }>;
  billingAddresses?: Array<{
    __typename?: "BillingAddress";
    city: string;
    company?: string | null;
    country: string;
    default: boolean;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
    state?: string | null;
  }> | null;
  pricingGroup?: {
    __typename?: "PricingGroup";
    id: string;
    name: string;
    description?: string | null;
    priceBase: PricingGroupPriceBase;
    customersCount?: number | null;
    discount: number;
    lastActivityAt?: any | null;
    createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
  } | null;
};

export type CustomersMetaDataSummaryFragment = {__typename?: "CustomerExport"; exportUrl: string};

export type ShippingAddressSummaryFragment = {
  __typename?: "MailingAddress";
  city: string;
  company?: string | null;
  country: string;
  default: boolean;
  line1: string;
  line2?: string | null;
  name?: string | null;
  phone?: string | null;
  postcode: string;
  state?: string | null;
};

export type BillingAddressSummaryFragment = {
  __typename?: "BillingAddress";
  city: string;
  company?: string | null;
  country: string;
  default: boolean;
  line1: string;
  line2?: string | null;
  name?: string | null;
  phone?: string | null;
  postcode: string;
  state?: string | null;
};

export type CustomerStatsSummaryFragment = {
  __typename?: "CustomerStats";
  ordersCount: number;
  averageOrderValue: {__typename?: "Money"; amount: any; currency: string};
  totalOrdersValue: {__typename?: "Money"; amount: any; currency: string};
};

export type BusinessMemberSummaryFragment = {
  __typename?: "BusinessMember";
  acceptedAt?: any | null;
  email: string;
  id: string;
  name?: string | null;
  sentAt?: any | null;
  status: InvitationStatus;
  consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
};

export type ConsentsSummaryFragment = {
  __typename?: "Consent";
  acceptedAt: any;
  description: string;
  kind: ConsentKind;
  version: string;
};

export type PaginatedAllStatementsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  filterBy?: InputMaybe<SettlementFilter>;
}>;

export type PaginatedAllStatementsQuery = {
  __typename?: "Query";
  settlements?: {
    __typename?: "SettlementConnection";
    nodes: Array<{
      __typename?: "Settlement";
      id: string;
      period: string;
      settledAt: any;
      serviceInvoiceDocUrl?: string | null;
      settlementDocUrl?: string | null;
      balance: {__typename?: "Money"; amount: any; currency: string};
      fees: {__typename?: "Money"; amount: any; currency: string};
      payments: {__typename?: "Money"; amount: any; currency: string};
      payouts: {__typename?: "Money"; amount: any; currency: string};
      refunds: {__typename?: "Money"; amount: any; currency: string};
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type StatementsSummaryFragment = {
  __typename?: "Settlement";
  id: string;
  period: string;
  settledAt: any;
  serviceInvoiceDocUrl?: string | null;
  settlementDocUrl?: string | null;
  balance: {__typename?: "Money"; amount: any; currency: string};
  fees: {__typename?: "Money"; amount: any; currency: string};
  payments: {__typename?: "Money"; amount: any; currency: string};
  payouts: {__typename?: "Money"; amount: any; currency: string};
  refunds: {__typename?: "Money"; amount: any; currency: string};
};

export type GetAllFulfillmentRulesQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllFulfillmentRulesQuery = {
  __typename?: "Query";
  fulfillmentRules: Array<{
    __typename?: "FulfillmentRule";
    destinationCountry: string;
    orderSource: OrderSource;
    orderKind: OrderKind;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
};

export type FulfillmentRulesSummaryFragment = {
  __typename?: "FulfillmentRule";
  destinationCountry: string;
  orderSource: OrderSource;
  orderKind: OrderKind;
  warehouse: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    consignor?: {
      __typename?: "Consignor";
      exciseNumber?: string | null;
      address?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
    } | null;
  };
};

export type GetAllLocationsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllLocationsQuery = {
  __typename?: "Query";
  locations: Array<{
    __typename?: "Location";
    id: string;
    city: string;
    country: string;
    email: string;
    line1: string;
    line2?: string | null;
    mapLink?: string | null;
    name: string;
    phone?: string | null;
    postcode: string;
    status: LocationStatus;
  }>;
};

export type GetLocationByIdQueryVariables = Exact<{
  locationId: Scalars["ID"]["input"];
}>;

export type GetLocationByIdQuery = {
  __typename?: "Query";
  location?: {
    __typename?: "Location";
    id: string;
    city: string;
    country: string;
    email: string;
    line1: string;
    line2?: string | null;
    mapLink?: string | null;
    name: string;
    phone?: string | null;
    postcode: string;
    status: LocationStatus;
  } | null;
};

export type LocationSummaryFragment = {
  __typename?: "Location";
  id: string;
  city: string;
  country: string;
  email: string;
  line1: string;
  line2?: string | null;
  mapLink?: string | null;
  name: string;
  phone?: string | null;
  postcode: string;
  status: LocationStatus;
};

export type InvoicesMetaDataQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
}>;

export type InvoicesMetaDataQuery = {
  __typename?: "Query";
  invoiceSummary?: {
    __typename?: "InvoiceSummary";
    exportCreditNotesUrl: string;
    exportOrderInvoicesUrl: string;
    totalGrossSales: {__typename?: "Money"; amount: any; currency: string};
    totalNetSales: {__typename?: "Money"; amount: any; currency: string};
  } | null;
};

export type PaginatedAllInvoicesQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<OrderInvoiceSorting>;
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
}>;

export type PaginatedAllInvoicesQuery = {
  __typename?: "Query";
  allOrderInvoices?: {
    __typename?: "OrderInvoiceDetailsConnection";
    nodes: Array<{
      __typename?: "OrderInvoiceDetails";
      id: string;
      invoicedAt: any;
      number: string;
      reverseInvoiceDocUrl?: string | null;
      settlementNumber?: string | null;
      order: {__typename?: "Order"; id: string; number: string};
      grossSales: {__typename?: "Money"; amount: any; currency: string};
      exciseDuty: {__typename?: "Money"; amount: any; currency: string};
      shipping: {__typename?: "Money"; amount: any; currency: string};
      recycling: {__typename?: "Money"; amount: any; currency: string};
      paymentProcessing: {__typename?: "Money"; amount: any; currency: string};
      commission: {__typename?: "Money"; amount: any; currency: string};
      netSales: {__typename?: "Money"; amount: any; currency: string};
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type PaginatedAllCreditNotesQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<OrderInvoiceSorting>;
  filterBy?: InputMaybe<OrderInvoiceSearchInput>;
}>;

export type PaginatedAllCreditNotesQuery = {
  __typename?: "Query";
  allCreditNotes?: {
    __typename?: "OrderInvoiceDetailsConnection";
    nodes: Array<{
      __typename?: "OrderInvoiceDetails";
      id: string;
      invoicedAt: any;
      number: string;
      reverseInvoiceDocUrl?: string | null;
      settlementNumber?: string | null;
      order: {__typename?: "Order"; id: string; number: string};
      grossSales: {__typename?: "Money"; amount: any; currency: string};
      exciseDuty: {__typename?: "Money"; amount: any; currency: string};
      shipping: {__typename?: "Money"; amount: any; currency: string};
      recycling: {__typename?: "Money"; amount: any; currency: string};
      paymentProcessing: {__typename?: "Money"; amount: any; currency: string};
      commission: {__typename?: "Money"; amount: any; currency: string};
      netSales: {__typename?: "Money"; amount: any; currency: string};
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type InvoiceSummaryFragment = {
  __typename?: "OrderInvoiceDetails";
  id: string;
  invoicedAt: any;
  number: string;
  reverseInvoiceDocUrl?: string | null;
  settlementNumber?: string | null;
  order: {__typename?: "Order"; id: string; number: string};
  grossSales: {__typename?: "Money"; amount: any; currency: string};
  exciseDuty: {__typename?: "Money"; amount: any; currency: string};
  shipping: {__typename?: "Money"; amount: any; currency: string};
  recycling: {__typename?: "Money"; amount: any; currency: string};
  paymentProcessing: {__typename?: "Money"; amount: any; currency: string};
  commission: {__typename?: "Money"; amount: any; currency: string};
  netSales: {__typename?: "Money"; amount: any; currency: string};
};

export type InvoiceMetaDataSummaryFragment = {
  __typename?: "InvoiceSummary";
  exportCreditNotesUrl: string;
  exportOrderInvoicesUrl: string;
  totalGrossSales: {__typename?: "Money"; amount: any; currency: string};
  totalNetSales: {__typename?: "Money"; amount: any; currency: string};
};

export type PaginatedAllOrdersQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<OrderSorting>;
  filterBy?: InputMaybe<OrderFilter>;
}>;

export type PaginatedAllOrdersQuery = {
  __typename?: "Query";
  allOrders: {
    __typename?: "OrderConnection";
    nodes: Array<{
      __typename?: "Order";
      id: string;
      number: string;
      createdAt: any;
      currency: string;
      kind: OrderKind;
      status: OrderStatus;
      source: OrderSource;
      paymentStatus: OrderPaymentStatus;
      shippingPackage?: ShippingPackage | null;
      shippingTrackingUrl?: string | null;
      shippingId?: string | null;
      packagingDate?: any | null;
      registeredAt?: any | null;
      verificationFailed: boolean;
      overdueDays?: number | null;
      createdBy?: {
        __typename?: "CreatedBy";
        id?: string | null;
        email: string;
        impersonation: boolean;
        kind: CreatedByKind;
        name: string;
      } | null;
      customer: {__typename?: "Customer"; id: string; name: string; country: string};
      orderItems?: Array<{
        __typename?: "OrderItem";
        product:
          | {__typename?: "Beverage"; status: ProductStatus}
          | {__typename?: "Extra"; status: ProductStatus}
          | {__typename?: "ProductBundle"; status: ProductStatus};
      }> | null;
      netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
      warehouse?: {__typename?: "Warehouse"; id: string; kind: WarehouseKind; displayName: string} | null;
      externalReferences?: Array<{__typename?: "ExternalReference"; externalId: string; system: ExternalSystem}> | null;
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  };
};

export type GetOrderByIdQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type GetOrderByIdQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    id: string;
    number: string;
    createdAt: any;
    submittedAt?: any | null;
    registeredAt?: any | null;
    shippedAt?: any | null;
    deliveredAt?: any | null;
    paidAt?: any | null;
    settledAt?: any | null;
    writtenOffAt?: any | null;
    status: OrderStatus;
    source: OrderSource;
    paymentStatus: OrderPaymentStatus;
    internalNote?: string | null;
    attentionNote?: string | null;
    shippingPackage?: ShippingPackage | null;
    shippingWeight?: number | null;
    shippingTrackingUrl?: string | null;
    shippingId?: string | null;
    shippingCarrier?: CarrierKind | null;
    deliveryMethod?: DeliveryMethod | null;
    packagingDate?: any | null;
    overdueDays?: number | null;
    waybillDocUrl?: string | null;
    createdBy?: {
      __typename?: "CreatedBy";
      id?: string | null;
      email: string;
      impersonation: boolean;
      kind: CreatedByKind;
      name: string;
    } | null;
    externalReferences?: Array<{__typename?: "ExternalReference"; externalId: string; system: ExternalSystem}> | null;
    shippingLine?: {
      __typename?: "ShippingLine";
      name: string;
      kind: ShippingLineKind;
      price: {__typename?: "Money"; amount: any; currency: string};
    } | null;
    shippingAddress?: {
      __typename?: "MailingAddress";
      default: boolean;
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
    billingAddress?: {
      __typename?: "BillingAddress";
      default: boolean;
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
    deliveryAddress?: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
    netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
    customer: {
      __typename?: "Customer";
      id: string;
      currency: string;
      name: string;
      email: string;
      billingEmail?: string | null;
      ccInvoices: boolean;
      country: string;
      kind: CustomerKind;
      paymentTerms?: string | null;
      taxId?: string | null;
      exciseNumber?: string | null;
      exciseTaxWarehouse?: string | null;
      exciseWarehouseKeeper?: string | null;
      exciseDestinationKind?: ExciseDestinationKind | null;
      legalDrinkingAge?: boolean | null;
      ordersCount: number;
      tags: Array<string>;
      consents: Array<{
        __typename?: "Consent";
        acceptedAt: any;
        description: string;
        kind: ConsentKind;
        version: string;
      }>;
      mailingAddresses: Array<{
        __typename?: "MailingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }>;
      billingAddresses?: Array<{
        __typename?: "BillingAddress";
        city: string;
        company?: string | null;
        country: string;
        default: boolean;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
        state?: string | null;
      }> | null;
      pricingGroup?: {
        __typename?: "PricingGroup";
        id: string;
        name: string;
        description?: string | null;
        priceBase: PricingGroupPriceBase;
        customersCount?: number | null;
        discount: number;
        lastActivityAt?: any | null;
        createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
      } | null;
    };
    orderItems?: Array<{
      __typename?: "OrderItem";
      id: string;
      discount?: number | null;
      quantity: number;
      batchCodes: Array<{__typename?: "BatchCode"; productId: string; code: string; quantity: number}>;
      price: {__typename?: "Money"; amount: any; currency: string};
      totalPrice: {__typename?: "Money"; amount: any; currency: string};
      product:
        | {
            __typename?: "Beverage";
            id: string;
            sku: string;
            name: string;
            grossWeight?: number | null;
            kind: ProductKind;
            organic: boolean;
            shopCountries: Array<string>;
            shopGlobalUrl?: string | null;
            prepacked: boolean;
            ogImageUrl?: string | null;
            caseQuantityRetail: number;
            caseQuantityWholesale: number;
            minOrderQuantityRetail: number;
            minOrderQuantityWholesale: number;
            status: ProductStatus;
            stocks: Array<{
              __typename?: "Stock";
              id: string;
              quantity: number;
              quantityAvailable: number;
              quantityReserved: number;
              tracked: boolean;
              items: Array<{
                __typename?: "StockItem";
                id: string;
                batchCode: string;
                expiryDate?: any | null;
                quantity: number;
                createdAt: any;
              }>;
              warehouse: {
                __typename?: "Warehouse";
                code?: string | null;
                dispatchOffice: string;
                displayName: string;
                email: string;
                exciseNumber?: string | null;
                id: string;
                kind: WarehouseKind;
                status: WarehouseStatus;
                country: string;
                address: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                };
                options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
                consignor?: {
                  __typename?: "Consignor";
                  exciseNumber?: string | null;
                  address?: {
                    __typename?: "MailingAddress";
                    city: string;
                    country: string;
                    company?: string | null;
                    line1: string;
                    line2?: string | null;
                    name?: string | null;
                    phone?: string | null;
                    postcode: string;
                  } | null;
                } | null;
              };
            }>;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
            brand?: {__typename?: "Brand"; id: string; name: string} | null;
            allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
          }
        | {
            __typename?: "Extra";
            id: string;
            sku: string;
            name: string;
            grossWeight?: number | null;
            kind: ProductKind;
            organic: boolean;
            shopCountries: Array<string>;
            shopGlobalUrl?: string | null;
            prepacked: boolean;
            ogImageUrl?: string | null;
            caseQuantityRetail: number;
            caseQuantityWholesale: number;
            minOrderQuantityRetail: number;
            minOrderQuantityWholesale: number;
            status: ProductStatus;
            stocks: Array<{
              __typename?: "Stock";
              id: string;
              quantity: number;
              quantityAvailable: number;
              quantityReserved: number;
              tracked: boolean;
              items: Array<{
                __typename?: "StockItem";
                id: string;
                batchCode: string;
                expiryDate?: any | null;
                quantity: number;
                createdAt: any;
              }>;
              warehouse: {
                __typename?: "Warehouse";
                code?: string | null;
                dispatchOffice: string;
                displayName: string;
                email: string;
                exciseNumber?: string | null;
                id: string;
                kind: WarehouseKind;
                status: WarehouseStatus;
                country: string;
                address: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                };
                options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
                consignor?: {
                  __typename?: "Consignor";
                  exciseNumber?: string | null;
                  address?: {
                    __typename?: "MailingAddress";
                    city: string;
                    country: string;
                    company?: string | null;
                    line1: string;
                    line2?: string | null;
                    name?: string | null;
                    phone?: string | null;
                    postcode: string;
                  } | null;
                } | null;
              };
            }>;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
            brand?: {__typename?: "Brand"; id: string; name: string} | null;
            allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
          }
        | {
            __typename?: "ProductBundle";
            id: string;
            sku: string;
            name: string;
            grossWeight?: number | null;
            kind: ProductKind;
            organic: boolean;
            shopCountries: Array<string>;
            shopGlobalUrl?: string | null;
            prepacked: boolean;
            ogImageUrl?: string | null;
            caseQuantityRetail: number;
            caseQuantityWholesale: number;
            minOrderQuantityRetail: number;
            minOrderQuantityWholesale: number;
            status: ProductStatus;
            items: Array<{
              __typename?: "ProductBundleItem";
              id: string;
              quantity: number;
              product:
                | {__typename: "Beverage"; id: string; sku: string; name: string}
                | {__typename: "Extra"; id: string; sku: string; name: string}
                | {__typename: "ProductBundle"; id: string; sku: string; name: string};
            }>;
            stocks: Array<{
              __typename?: "Stock";
              id: string;
              quantity: number;
              quantityAvailable: number;
              quantityReserved: number;
              tracked: boolean;
              items: Array<{
                __typename?: "StockItem";
                id: string;
                batchCode: string;
                expiryDate?: any | null;
                quantity: number;
                createdAt: any;
              }>;
              warehouse: {
                __typename?: "Warehouse";
                code?: string | null;
                dispatchOffice: string;
                displayName: string;
                email: string;
                exciseNumber?: string | null;
                id: string;
                kind: WarehouseKind;
                status: WarehouseStatus;
                country: string;
                address: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                };
                options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
                consignor?: {
                  __typename?: "Consignor";
                  exciseNumber?: string | null;
                  address?: {
                    __typename?: "MailingAddress";
                    city: string;
                    country: string;
                    company?: string | null;
                    line1: string;
                    line2?: string | null;
                    name?: string | null;
                    phone?: string | null;
                    postcode: string;
                  } | null;
                } | null;
              };
            }>;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
            brand?: {__typename?: "Brand"; id: string; name: string} | null;
            allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
          };
    }> | null;
    shippingServicePoint?: {
      __typename?: "ServicePoint";
      id: string;
      name: string;
      typeName?: string | null;
      country: string;
      visitingAddress?: {__typename?: "MailingAddress"; postcode: string; city: string; line1: string} | null;
    } | null;
    location?: {
      __typename?: "Location";
      id: string;
      city: string;
      country: string;
      email: string;
      line1: string;
      line2?: string | null;
      mapLink?: string | null;
      name: string;
      phone?: string | null;
      postcode: string;
      status: LocationStatus;
    } | null;
    discountUsages?: Array<{
      __typename?: "DiscountUsage";
      code: string;
      discountPercentage?: number | null;
      netAmount: {__typename?: "Money"; amount: any; currency: string};
    }> | null;
    carrierOptions?: {
      __typename?: "CarrierOptions";
      deliveryDate?: any | null;
      deliveryTimeFrom?: string | null;
      deliveryTimeTo?: string | null;
      pickupDate?: any | null;
      pickupTimeFrom?: string | null;
      pickupTimeTo?: string | null;
    } | null;
    warehouse?: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    } | null;
  } | null;
};

export type GetDocumentsByIdQueryVariables = Exact<{
  orderId: Scalars["ID"]["input"];
}>;

export type GetDocumentsByIdQuery = {
  __typename?: "Query";
  order?: {
    __typename?: "Order";
    eadDocUrl?: string | null;
    invoiceDocUrl?: string | null;
    sadDocUrl?: string | null;
    shippingDocUrl?: string | null;
    proformaInvoiceDocUrl?: string | null;
    combinedDocsUrl?: string | null;
  } | null;
};

export type GetOrderTabsMetadataQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetOrderTabsMetadataQuery = {
  __typename?: "Query";
  orderTabsMetadata: {
    __typename?: "OrderTabsMetadata";
    allTabCount: number;
    closedTabCount: number;
    newTabCount: number;
    openTabCount: number;
    upcomingTabCount: number;
  };
};

export type CheckStockForOrderQueryVariables = Exact<{
  order: OrderWithIdInput;
  orderItem: OrderItemInput;
}>;

export type CheckStockForOrderQuery = {__typename?: "Query"; checkStockForOrder: boolean};

export type OrdersMetaDataSummaryFragment = {
  __typename?: "OrderTabsMetadata";
  allTabCount: number;
  closedTabCount: number;
  newTabCount: number;
  openTabCount: number;
  upcomingTabCount: number;
};

export type RecentOrderSummaryFragment = {
  __typename?: "Order";
  id: string;
  number: string;
  createdAt: any;
  status: OrderStatus;
  netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
};

export type OrderShortSummaryFragment = {
  __typename?: "Order";
  id: string;
  number: string;
  createdAt: any;
  currency: string;
  kind: OrderKind;
  status: OrderStatus;
  source: OrderSource;
  paymentStatus: OrderPaymentStatus;
  shippingPackage?: ShippingPackage | null;
  shippingTrackingUrl?: string | null;
  shippingId?: string | null;
  packagingDate?: any | null;
  registeredAt?: any | null;
  verificationFailed: boolean;
  overdueDays?: number | null;
  createdBy?: {
    __typename?: "CreatedBy";
    id?: string | null;
    email: string;
    impersonation: boolean;
    kind: CreatedByKind;
    name: string;
  } | null;
  customer: {__typename?: "Customer"; id: string; name: string; country: string};
  orderItems?: Array<{
    __typename?: "OrderItem";
    product:
      | {__typename?: "Beverage"; status: ProductStatus}
      | {__typename?: "Extra"; status: ProductStatus}
      | {__typename?: "ProductBundle"; status: ProductStatus};
  }> | null;
  netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
  warehouse?: {__typename?: "Warehouse"; id: string; kind: WarehouseKind; displayName: string} | null;
  externalReferences?: Array<{__typename?: "ExternalReference"; externalId: string; system: ExternalSystem}> | null;
};

export type OrderSummaryFragment = {
  __typename?: "Order";
  id: string;
  number: string;
  createdAt: any;
  submittedAt?: any | null;
  registeredAt?: any | null;
  shippedAt?: any | null;
  deliveredAt?: any | null;
  paidAt?: any | null;
  settledAt?: any | null;
  writtenOffAt?: any | null;
  status: OrderStatus;
  source: OrderSource;
  paymentStatus: OrderPaymentStatus;
  internalNote?: string | null;
  attentionNote?: string | null;
  shippingPackage?: ShippingPackage | null;
  shippingWeight?: number | null;
  shippingTrackingUrl?: string | null;
  shippingId?: string | null;
  shippingCarrier?: CarrierKind | null;
  deliveryMethod?: DeliveryMethod | null;
  packagingDate?: any | null;
  overdueDays?: number | null;
  waybillDocUrl?: string | null;
  createdBy?: {
    __typename?: "CreatedBy";
    id?: string | null;
    email: string;
    impersonation: boolean;
    kind: CreatedByKind;
    name: string;
  } | null;
  externalReferences?: Array<{__typename?: "ExternalReference"; externalId: string; system: ExternalSystem}> | null;
  shippingLine?: {
    __typename?: "ShippingLine";
    name: string;
    kind: ShippingLineKind;
    price: {__typename?: "Money"; amount: any; currency: string};
  } | null;
  shippingAddress?: {
    __typename?: "MailingAddress";
    default: boolean;
    city: string;
    country: string;
    company?: string | null;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
  } | null;
  billingAddress?: {
    __typename?: "BillingAddress";
    default: boolean;
    city: string;
    country: string;
    company?: string | null;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
  } | null;
  deliveryAddress?: {
    __typename?: "MailingAddress";
    city: string;
    country: string;
    company?: string | null;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
  } | null;
  netTotal?: {__typename?: "Money"; amount: any; currency: string} | null;
  customer: {
    __typename?: "Customer";
    id: string;
    currency: string;
    name: string;
    email: string;
    billingEmail?: string | null;
    ccInvoices: boolean;
    country: string;
    kind: CustomerKind;
    paymentTerms?: string | null;
    taxId?: string | null;
    exciseNumber?: string | null;
    exciseTaxWarehouse?: string | null;
    exciseWarehouseKeeper?: string | null;
    exciseDestinationKind?: ExciseDestinationKind | null;
    legalDrinkingAge?: boolean | null;
    ordersCount: number;
    tags: Array<string>;
    consents: Array<{__typename?: "Consent"; acceptedAt: any; description: string; kind: ConsentKind; version: string}>;
    mailingAddresses: Array<{
      __typename?: "MailingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }>;
    billingAddresses?: Array<{
      __typename?: "BillingAddress";
      city: string;
      company?: string | null;
      country: string;
      default: boolean;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
      state?: string | null;
    }> | null;
    pricingGroup?: {
      __typename?: "PricingGroup";
      id: string;
      name: string;
      description?: string | null;
      priceBase: PricingGroupPriceBase;
      customersCount?: number | null;
      discount: number;
      lastActivityAt?: any | null;
      createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
    } | null;
  };
  orderItems?: Array<{
    __typename?: "OrderItem";
    id: string;
    discount?: number | null;
    quantity: number;
    batchCodes: Array<{__typename?: "BatchCode"; productId: string; code: string; quantity: number}>;
    price: {__typename?: "Money"; amount: any; currency: string};
    totalPrice: {__typename?: "Money"; amount: any; currency: string};
    product:
      | {
          __typename?: "Beverage";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "Extra";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          items: Array<{
            __typename?: "ProductBundleItem";
            id: string;
            quantity: number;
            product:
              | {__typename: "Beverage"; id: string; sku: string; name: string}
              | {__typename: "Extra"; id: string; sku: string; name: string}
              | {__typename: "ProductBundle"; id: string; sku: string; name: string};
          }>;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        };
  }> | null;
  shippingServicePoint?: {
    __typename?: "ServicePoint";
    id: string;
    name: string;
    typeName?: string | null;
    country: string;
    visitingAddress?: {__typename?: "MailingAddress"; postcode: string; city: string; line1: string} | null;
  } | null;
  location?: {
    __typename?: "Location";
    id: string;
    city: string;
    country: string;
    email: string;
    line1: string;
    line2?: string | null;
    mapLink?: string | null;
    name: string;
    phone?: string | null;
    postcode: string;
    status: LocationStatus;
  } | null;
  discountUsages?: Array<{
    __typename?: "DiscountUsage";
    code: string;
    discountPercentage?: number | null;
    netAmount: {__typename?: "Money"; amount: any; currency: string};
  }> | null;
  carrierOptions?: {
    __typename?: "CarrierOptions";
    deliveryDate?: any | null;
    deliveryTimeFrom?: string | null;
    deliveryTimeTo?: string | null;
    pickupDate?: any | null;
    pickupTimeFrom?: string | null;
    pickupTimeTo?: string | null;
  } | null;
  warehouse?: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
  } | null;
};

export type OrderItemSummaryFragment = {
  __typename?: "OrderItem";
  id: string;
  discount?: number | null;
  quantity: number;
  batchCodes: Array<{__typename?: "BatchCode"; productId: string; code: string; quantity: number}>;
  price: {__typename?: "Money"; amount: any; currency: string};
  totalPrice: {__typename?: "Money"; amount: any; currency: string};
  product:
    | {
        __typename?: "Beverage";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "Extra";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        items: Array<{
          __typename?: "ProductBundleItem";
          id: string;
          quantity: number;
          product:
            | {__typename: "Beverage"; id: string; sku: string; name: string}
            | {__typename: "Extra"; id: string; sku: string; name: string}
            | {__typename: "ProductBundle"; id: string; sku: string; name: string};
        }>;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      };
};

export type OrderAddressSummaryFragment = {
  __typename?: "MailingAddress";
  city: string;
  country: string;
  company?: string | null;
  line1: string;
  line2?: string | null;
  name?: string | null;
  phone?: string | null;
  postcode: string;
};

export type ExternalReferenceShortFragment = {
  __typename?: "ExternalReference";
  externalId: string;
  system: ExternalSystem;
};

export type GetPickingListQueryVariables = Exact<{
  signedId?: InputMaybe<Scalars["String"]["input"]>;
  orderId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type GetPickingListQuery = {
  __typename?: "Query";
  pickingList?: {
    __typename?: "PickingList";
    contactEmail: string;
    fulfillmentEmail?: string | null;
    pickupType?: string | null;
    useBemakersSku: boolean;
    order: {
      __typename?: "Order";
      id: string;
      deliveryMethod?: DeliveryMethod | null;
      number: string;
      status: OrderStatus;
      shippingCarrier?: CarrierKind | null;
      shippingTrackingUrl?: string | null;
      internalNote?: string | null;
      shippingAddress?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
      deliveryAddress?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
      customer: {__typename?: "Customer"; name: string; country: string; kind: CustomerKind};
      orderItems?: Array<{
        __typename?: "OrderItem";
        id: string;
        quantity: number;
        batchCodes: Array<{__typename?: "BatchCode"; code: string; productId: string; quantity: number}>;
        product:
          | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
          | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
          | {
              __typename?: "ProductBundle";
              id: string;
              name: string;
              sku: string;
              bemakersSku: string;
              prepacked: boolean;
            };
        bundledItems?: Array<{
          __typename?: "ProductBundleItem";
          id: string;
          quantity: number;
          product:
            | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
            | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
            | {
                __typename?: "ProductBundle";
                id: string;
                name: string;
                sku: string;
                bemakersSku: string;
                prepacked: boolean;
              };
        }> | null;
        orderLabels?: Array<{
          __typename?: "OrderLabel";
          displayKind: string;
          kind: LabelStockKind;
          productId?: string | null;
          productName?: string | null;
        }> | null;
      }> | null;
      stockOptions: Array<{
        __typename?: "StockOption";
        batchCodeMode?: string | null;
        batchCodeOptions?: Array<string> | null;
        productId: string;
        tracked: boolean;
      }>;
      warehouse?: {
        __typename?: "Warehouse";
        displayName: string;
        id: string;
        kind: WarehouseKind;
        country: string;
      } | null;
      orderLabels?: Array<{
        __typename?: "OrderLabel";
        displayKind: string;
        kind: LabelStockKind;
        productId?: string | null;
        productName?: string | null;
      }> | null;
    };
  } | null;
};

export type PickingListSummaryFragment = {
  __typename?: "PickingList";
  contactEmail: string;
  fulfillmentEmail?: string | null;
  pickupType?: string | null;
  useBemakersSku: boolean;
  order: {
    __typename?: "Order";
    id: string;
    deliveryMethod?: DeliveryMethod | null;
    number: string;
    status: OrderStatus;
    shippingCarrier?: CarrierKind | null;
    shippingTrackingUrl?: string | null;
    internalNote?: string | null;
    shippingAddress?: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
    deliveryAddress?: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
    customer: {__typename?: "Customer"; name: string; country: string; kind: CustomerKind};
    orderItems?: Array<{
      __typename?: "OrderItem";
      id: string;
      quantity: number;
      batchCodes: Array<{__typename?: "BatchCode"; code: string; productId: string; quantity: number}>;
      product:
        | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
        | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
        | {
            __typename?: "ProductBundle";
            id: string;
            name: string;
            sku: string;
            bemakersSku: string;
            prepacked: boolean;
          };
      bundledItems?: Array<{
        __typename?: "ProductBundleItem";
        id: string;
        quantity: number;
        product:
          | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
          | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
          | {
              __typename?: "ProductBundle";
              id: string;
              name: string;
              sku: string;
              bemakersSku: string;
              prepacked: boolean;
            };
      }> | null;
      orderLabels?: Array<{
        __typename?: "OrderLabel";
        displayKind: string;
        kind: LabelStockKind;
        productId?: string | null;
        productName?: string | null;
      }> | null;
    }> | null;
    stockOptions: Array<{
      __typename?: "StockOption";
      batchCodeMode?: string | null;
      batchCodeOptions?: Array<string> | null;
      productId: string;
      tracked: boolean;
    }>;
    warehouse?: {
      __typename?: "Warehouse";
      displayName: string;
      id: string;
      kind: WarehouseKind;
      country: string;
    } | null;
    orderLabels?: Array<{
      __typename?: "OrderLabel";
      displayKind: string;
      kind: LabelStockKind;
      productId?: string | null;
      productName?: string | null;
    }> | null;
  };
};

export type PickingListOrderItemSummaryFragment = {
  __typename?: "OrderItem";
  id: string;
  quantity: number;
  batchCodes: Array<{__typename?: "BatchCode"; code: string; productId: string; quantity: number}>;
  product:
    | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
    | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
    | {__typename?: "ProductBundle"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean};
  bundledItems?: Array<{
    __typename?: "ProductBundleItem";
    id: string;
    quantity: number;
    product:
      | {__typename?: "Beverage"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
      | {__typename?: "Extra"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean}
      | {__typename?: "ProductBundle"; id: string; name: string; sku: string; bemakersSku: string; prepacked: boolean};
  }> | null;
  orderLabels?: Array<{
    __typename?: "OrderLabel";
    displayKind: string;
    kind: LabelStockKind;
    productId?: string | null;
    productName?: string | null;
  }> | null;
};

type PickingListProductSummary_Beverage_Fragment = {
  __typename?: "Beverage";
  id: string;
  name: string;
  sku: string;
  bemakersSku: string;
  prepacked: boolean;
};

type PickingListProductSummary_Extra_Fragment = {
  __typename?: "Extra";
  id: string;
  name: string;
  sku: string;
  bemakersSku: string;
  prepacked: boolean;
};

type PickingListProductSummary_ProductBundle_Fragment = {
  __typename?: "ProductBundle";
  id: string;
  name: string;
  sku: string;
  bemakersSku: string;
  prepacked: boolean;
};

export type PickingListProductSummaryFragment =
  | PickingListProductSummary_Beverage_Fragment
  | PickingListProductSummary_Extra_Fragment
  | PickingListProductSummary_ProductBundle_Fragment;

export type OrderLabelFragment = {
  __typename?: "OrderLabel";
  displayKind: string;
  kind: LabelStockKind;
  productId?: string | null;
  productName?: string | null;
};

export type CalculatePriceQueryVariables = Exact<{
  calculatorInput: PricingCalculatorInput;
}>;

export type CalculatePriceQuery = {
  __typename?: "Query";
  calculatePrice: {
    __typename?: "PricingCalculation";
    breakdown: Array<
      Array<{
        __typename?: "PricingCalculationItem";
        style: PricingCalculatorItemStyle;
        kind: string;
        percentage?: number | null;
        text?: string | null;
        priceCustomer: {__typename?: "Money"; amount: any; currency: string};
        priceMaker: {__typename?: "Money"; amount: any; currency: string};
      }>
    >;
    summary: Array<{
      __typename?: "PricingCalculationItem";
      style: PricingCalculatorItemStyle;
      kind: string;
      percentage?: number | null;
      text?: string | null;
      priceCustomer: {__typename?: "Money"; amount: any; currency: string};
      priceMaker: {__typename?: "Money"; amount: any; currency: string};
    }>;
  };
};

export type CalculatePriceSeedsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type CalculatePriceSeedsQuery = {
  __typename?: "Query";
  calculatePriceSeeds: {
    __typename?: "PricingCalculatorSeeds";
    pricingPlans: Array<{__typename?: "PricingPlan"; name: string; commissionInt: number; key: string}>;
    warehouses: Array<{
      __typename?: "Warehouse";
      id: string;
      code?: string | null;
      country: string;
      displayName: string;
    }>;
  };
};

export type PricingCalculationSummaryFragment = {
  __typename?: "PricingCalculation";
  breakdown: Array<
    Array<{
      __typename?: "PricingCalculationItem";
      style: PricingCalculatorItemStyle;
      kind: string;
      percentage?: number | null;
      text?: string | null;
      priceCustomer: {__typename?: "Money"; amount: any; currency: string};
      priceMaker: {__typename?: "Money"; amount: any; currency: string};
    }>
  >;
  summary: Array<{
    __typename?: "PricingCalculationItem";
    style: PricingCalculatorItemStyle;
    kind: string;
    percentage?: number | null;
    text?: string | null;
    priceCustomer: {__typename?: "Money"; amount: any; currency: string};
    priceMaker: {__typename?: "Money"; amount: any; currency: string};
  }>;
};

export type PricingCalculationItemSummaryFragment = {
  __typename?: "PricingCalculationItem";
  style: PricingCalculatorItemStyle;
  kind: string;
  percentage?: number | null;
  text?: string | null;
  priceCustomer: {__typename?: "Money"; amount: any; currency: string};
  priceMaker: {__typename?: "Money"; amount: any; currency: string};
};

export type CalculatePriceSeedsSummaryFragment = {
  __typename?: "PricingCalculatorSeeds";
  pricingPlans: Array<{__typename?: "PricingPlan"; name: string; commissionInt: number; key: string}>;
  warehouses: Array<{__typename?: "Warehouse"; id: string; code?: string | null; country: string; displayName: string}>;
};

export type PricingPlanSummaryForPriceCalculatorFragment = {
  __typename?: "PricingPlan";
  name: string;
  commissionInt: number;
  key: string;
};

export type WarehouseSummaryForPriceCalculatorFragment = {
  __typename?: "Warehouse";
  id: string;
  code?: string | null;
  country: string;
  displayName: string;
};

export type GetAllPricingGroupsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllPricingGroupsQuery = {
  __typename?: "Query";
  pricingGroups: Array<{
    __typename?: "PricingGroup";
    id: string;
    name: string;
    description?: string | null;
    priceBase: PricingGroupPriceBase;
    customersCount?: number | null;
    discount: number;
    lastActivityAt?: any | null;
    createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
  }>;
};

export type GetPreviewPricingGroupByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetPreviewPricingGroupByIdQuery = {
  __typename?: "Query";
  pricingGroup?: {
    __typename?: "PricingGroup";
    id: string;
    name: string;
    description?: string | null;
    priceBase: PricingGroupPriceBase;
    customersCount?: number | null;
    discount: number;
    lastActivityAt?: any | null;
    createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
  } | null;
};

export type GetPricingGroupByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetPricingGroupByIdQuery = {
  __typename?: "Query";
  pricingGroup?: {
    __typename?: "PricingGroup";
    id: string;
    name: string;
    description?: string | null;
    priceBase: PricingGroupPriceBase;
    customersCount?: number | null;
    discount: number;
    lastActivityAt?: any | null;
    customers?: Array<{__typename?: "Customer"; id: string; name: string; kind: CustomerKind; country: string}> | null;
    createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
  } | null;
};

export type GetSuggestedProductPriceQueryVariables = Exact<{
  productId: Scalars["ID"]["input"];
  customerId: Scalars["ID"]["input"];
  country: Scalars["String"]["input"];
}>;

export type GetSuggestedProductPriceQuery = {
  __typename?: "Query";
  suggestedProductPrice?: {
    __typename?: "SuggestedProductPrice";
    originalPrice: {__typename?: "Money"; amount: any; currency: string};
    price: {__typename?: "Money"; amount: any; currency: string};
  } | null;
};

export type PricingGroupShortSummaryFragment = {
  __typename?: "PricingGroup";
  id: string;
  name: string;
  description?: string | null;
  priceBase: PricingGroupPriceBase;
  customersCount?: number | null;
  discount: number;
  lastActivityAt?: any | null;
  createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
};

export type PricingGroupSummaryFragment = {
  __typename?: "PricingGroup";
  id: string;
  name: string;
  description?: string | null;
  priceBase: PricingGroupPriceBase;
  customersCount?: number | null;
  discount: number;
  lastActivityAt?: any | null;
  customers?: Array<{__typename?: "Customer"; id: string; name: string; kind: CustomerKind; country: string}> | null;
  createdBy?: {__typename?: "CurrentUser"; name?: string | null} | null;
};

export type GetDigitalProductsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
}>;

export type GetDigitalProductsQuery = {
  __typename?: "Query";
  findProducts?: Array<
    | {
        __typename?: "Beverage";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "Extra";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
  > | null;
};

export type PaginatedAllProductsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<ProductSorting>;
  filterBy?: InputMaybe<ProductFilter>;
}>;

export type PaginatedAllProductsQuery = {
  __typename?: "Query";
  allProducts?: {
    __typename?: "ProductInterfaceConnection";
    nodes: Array<
      | {
          __typename?: "Beverage";
          id: string;
          sku: string;
          name: string;
          kind: ProductKind;
          prepacked: boolean;
          shopCountries: Array<string>;
          volume?: number | null;
          alcoholPercent?: number | null;
          stocks: Array<{
            __typename?: "Stock";
            quantityAvailable: number;
            tracked: boolean;
            warehouse: {__typename?: "Warehouse"; displayName: string};
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
        }
      | {
          __typename?: "Extra";
          id: string;
          sku: string;
          name: string;
          kind: ProductKind;
          prepacked: boolean;
          shopCountries: Array<string>;
          stocks: Array<{
            __typename?: "Stock";
            quantityAvailable: number;
            tracked: boolean;
            warehouse: {__typename?: "Warehouse"; displayName: string};
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          name: string;
          kind: ProductKind;
          prepacked: boolean;
          shopCountries: Array<string>;
          grossWeight?: number | null;
          stocks: Array<{
            __typename?: "Stock";
            quantityAvailable: number;
            tracked: boolean;
            warehouse: {__typename?: "Warehouse"; displayName: string};
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          items: Array<{__typename?: "ProductBundleItem"; id: string; quantity: number}>;
        }
    >;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type GetBeverageByIdQueryVariables = Exact<{
  productId: Scalars["ID"]["input"];
}>;

export type GetBeverageByIdQuery = {
  __typename?: "Query";
  beverage?: {
    __typename?: "Beverage";
    id: string;
    sku: string;
    name: string;
    grossWeight?: number | null;
    kind: ProductKind;
    organic: boolean;
    shopCountries: Array<string>;
    shopGlobalUrl?: string | null;
    prepacked: boolean;
    ogImageUrl?: string | null;
    caseQuantityRetail: number;
    caseQuantityWholesale: number;
    minOrderQuantityRetail: number;
    minOrderQuantityWholesale: number;
    status: ProductStatus;
    alcoholPercent?: number | null;
    netWeight?: number | null;
    cnCode?: string | null;
    exciseProductCode?: string | null;
    volume?: number | null;
    packagingCode?: string | null;
    degreePlato?: number | null;
    gtin?: string | null;
    premix?: PremixKind | null;
    sugarPerLiter?: number | null;
    stocks: Array<{
      __typename?: "Stock";
      id: string;
      quantity: number;
      quantityAvailable: number;
      quantityReserved: number;
      tracked: boolean;
      items: Array<{
        __typename?: "StockItem";
        id: string;
        batchCode: string;
        expiryDate?: any | null;
        quantity: number;
        createdAt: any;
      }>;
      warehouse: {
        __typename?: "Warehouse";
        code?: string | null;
        dispatchOffice: string;
        displayName: string;
        email: string;
        exciseNumber?: string | null;
        id: string;
        kind: WarehouseKind;
        status: WarehouseStatus;
        country: string;
        address: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        };
        options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
        consignor?: {
          __typename?: "Consignor";
          exciseNumber?: string | null;
          address?: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          } | null;
        } | null;
      };
    }>;
    productPrices: Array<{
      __typename?: "ProductPrice";
      price: {__typename?: "Money"; amount: any; currency: string};
      market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
    }>;
    images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
    brand?: {__typename?: "Brand"; id: string; name: string} | null;
    allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
  } | null;
};

export type GetProductExtraByIdQueryVariables = Exact<{
  productId: Scalars["ID"]["input"];
}>;

export type GetProductExtraByIdQuery = {
  __typename?: "Query";
  extra?: {
    __typename?: "Extra";
    id: string;
    sku: string;
    name: string;
    grossWeight?: number | null;
    kind: ProductKind;
    organic: boolean;
    shopCountries: Array<string>;
    shopGlobalUrl?: string | null;
    prepacked: boolean;
    ogImageUrl?: string | null;
    caseQuantityRetail: number;
    caseQuantityWholesale: number;
    minOrderQuantityRetail: number;
    minOrderQuantityWholesale: number;
    status: ProductStatus;
    extraKind?: ExtraKind | null;
    physicalProduct: boolean;
    cnCode?: string | null;
    stocks: Array<{
      __typename?: "Stock";
      id: string;
      quantity: number;
      quantityAvailable: number;
      quantityReserved: number;
      tracked: boolean;
      items: Array<{
        __typename?: "StockItem";
        id: string;
        batchCode: string;
        expiryDate?: any | null;
        quantity: number;
        createdAt: any;
      }>;
      warehouse: {
        __typename?: "Warehouse";
        code?: string | null;
        dispatchOffice: string;
        displayName: string;
        email: string;
        exciseNumber?: string | null;
        id: string;
        kind: WarehouseKind;
        status: WarehouseStatus;
        country: string;
        address: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        };
        options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
        consignor?: {
          __typename?: "Consignor";
          exciseNumber?: string | null;
          address?: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          } | null;
        } | null;
      };
    }>;
    productPrices: Array<{
      __typename?: "ProductPrice";
      price: {__typename?: "Money"; amount: any; currency: string};
      market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
    }>;
    images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
    brand?: {__typename?: "Brand"; id: string; name: string} | null;
    allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
  } | null;
};

export type GetProductBundleByIdQueryVariables = Exact<{
  productId: Scalars["ID"]["input"];
}>;

export type GetProductBundleByIdQuery = {
  __typename?: "Query";
  productBundle?: {
    __typename?: "ProductBundle";
    id: string;
    sku: string;
    name: string;
    grossWeight?: number | null;
    kind: ProductKind;
    organic: boolean;
    shopCountries: Array<string>;
    shopGlobalUrl?: string | null;
    prepacked: boolean;
    ogImageUrl?: string | null;
    caseQuantityRetail: number;
    caseQuantityWholesale: number;
    minOrderQuantityRetail: number;
    minOrderQuantityWholesale: number;
    status: ProductStatus;
    stocks: Array<{
      __typename?: "Stock";
      id: string;
      quantity: number;
      quantityAvailable: number;
      quantityReserved: number;
      tracked: boolean;
      items: Array<{
        __typename?: "StockItem";
        id: string;
        batchCode: string;
        expiryDate?: any | null;
        quantity: number;
        createdAt: any;
      }>;
      warehouse: {
        __typename?: "Warehouse";
        code?: string | null;
        dispatchOffice: string;
        displayName: string;
        email: string;
        exciseNumber?: string | null;
        id: string;
        kind: WarehouseKind;
        status: WarehouseStatus;
        country: string;
        address: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        };
        options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
        consignor?: {
          __typename?: "Consignor";
          exciseNumber?: string | null;
          address?: {
            __typename?: "MailingAddress";
            city: string;
            country: string;
            company?: string | null;
            line1: string;
            line2?: string | null;
            name?: string | null;
            phone?: string | null;
            postcode: string;
          } | null;
        } | null;
      };
    }>;
    productPrices: Array<{
      __typename?: "ProductPrice";
      price: {__typename?: "Money"; amount: any; currency: string};
      market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
    }>;
    images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
    brand?: {__typename?: "Brand"; id: string; name: string} | null;
    allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
    items: Array<{
      __typename?: "ProductBundleItem";
      id: string;
      quantity: number;
      product:
        | {__typename: "Beverage"; id: string; sku: string; name: string}
        | {__typename: "Extra"; id: string; sku: string; name: string}
        | {__typename: "ProductBundle"; id: string; sku: string; name: string};
    }>;
  } | null;
};

export type GetProductDeletionDetailsQueryVariables = Exact<{
  productId: Scalars["ID"]["input"];
}>;

export type GetProductDeletionDetailsQuery = {
  __typename?: "Query";
  productDeletionDetails?: {
    __typename?: "ProductDeletionDetails";
    canBeDeleted: boolean;
    deletionBlockers: Array<{
      __typename?: "ProductDeletionItem";
      kind: ProductDeletionItemKind;
      title: string;
      subtitle?: string | null;
    }>;
    relatedItems: Array<{
      __typename?: "ProductDeletionItem";
      kind: ProductDeletionItemKind;
      title: string;
      subtitle?: string | null;
    }>;
  } | null;
};

export type GetProductSeedsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetProductSeedsQuery = {
  __typename?: "Query";
  productSeeds: {
    __typename?: "ProductSeeds";
    allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
    brands: Array<{
      __typename?: "Brand";
      slug: string;
      coverUrl?: string | null;
      descriptionHtml: any;
      descriptionJson: any;
      facebookUrl?: string | null;
      homepageUrl?: string | null;
      instagramUrl?: string | null;
      linkedinUrl?: string | null;
      tagline: any;
      id: string;
      name: string;
      logoUrl?: string | null;
    }>;
  };
};

type AllProductsShortSummary_Beverage_Fragment = {
  __typename?: "Beverage";
  id: string;
  sku: string;
  name: string;
  kind: ProductKind;
  prepacked: boolean;
  shopCountries: Array<string>;
  volume?: number | null;
  alcoholPercent?: number | null;
  stocks: Array<{
    __typename?: "Stock";
    quantityAvailable: number;
    tracked: boolean;
    warehouse: {__typename?: "Warehouse"; displayName: string};
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
};

type AllProductsShortSummary_Extra_Fragment = {
  __typename?: "Extra";
  id: string;
  sku: string;
  name: string;
  kind: ProductKind;
  prepacked: boolean;
  shopCountries: Array<string>;
  stocks: Array<{
    __typename?: "Stock";
    quantityAvailable: number;
    tracked: boolean;
    warehouse: {__typename?: "Warehouse"; displayName: string};
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
};

type AllProductsShortSummary_ProductBundle_Fragment = {
  __typename?: "ProductBundle";
  id: string;
  sku: string;
  name: string;
  kind: ProductKind;
  prepacked: boolean;
  shopCountries: Array<string>;
  grossWeight?: number | null;
  stocks: Array<{
    __typename?: "Stock";
    quantityAvailable: number;
    tracked: boolean;
    warehouse: {__typename?: "Warehouse"; displayName: string};
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  items: Array<{__typename?: "ProductBundleItem"; id: string; quantity: number}>;
};

export type AllProductsShortSummaryFragment =
  | AllProductsShortSummary_Beverage_Fragment
  | AllProductsShortSummary_Extra_Fragment
  | AllProductsShortSummary_ProductBundle_Fragment;

type ProductSummary_Beverage_Fragment = {
  __typename?: "Beverage";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
};

type ProductSummary_Extra_Fragment = {
  __typename?: "Extra";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
};

type ProductSummary_ProductBundle_Fragment = {
  __typename?: "ProductBundle";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
};

export type ProductSummaryFragment =
  | ProductSummary_Beverage_Fragment
  | ProductSummary_Extra_Fragment
  | ProductSummary_ProductBundle_Fragment;

export type BeverageUniqFieldsFragment = {
  __typename?: "Beverage";
  alcoholPercent?: number | null;
  netWeight?: number | null;
  cnCode?: string | null;
  exciseProductCode?: string | null;
  volume?: number | null;
  packagingCode?: string | null;
  degreePlato?: number | null;
  gtin?: string | null;
  premix?: PremixKind | null;
  sugarPerLiter?: number | null;
};

export type ProductBundleUniqFieldsFragment = {
  __typename?: "ProductBundle";
  items: Array<{
    __typename?: "ProductBundleItem";
    id: string;
    quantity: number;
    product:
      | {__typename: "Beverage"; id: string; sku: string; name: string}
      | {__typename: "Extra"; id: string; sku: string; name: string}
      | {__typename: "ProductBundle"; id: string; sku: string; name: string};
  }>;
};

export type ExtraUniqFieldsFragment = {
  __typename?: "Extra";
  extraKind?: ExtraKind | null;
  physicalProduct: boolean;
  cnCode?: string | null;
};

export type BeverageShortSummaryFragment = {
  __typename?: "Beverage";
  volume?: number | null;
  alcoholPercent?: number | null;
};

export type BeverageSummaryFragment = {
  __typename?: "Beverage";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  alcoholPercent?: number | null;
  netWeight?: number | null;
  cnCode?: string | null;
  exciseProductCode?: string | null;
  volume?: number | null;
  packagingCode?: string | null;
  degreePlato?: number | null;
  gtin?: string | null;
  premix?: PremixKind | null;
  sugarPerLiter?: number | null;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
};

export type ProductBundleShortSummaryFragment = {
  __typename?: "ProductBundle";
  grossWeight?: number | null;
  items: Array<{__typename?: "ProductBundleItem"; id: string; quantity: number}>;
};

export type ProductBundleSummaryFragment = {
  __typename?: "ProductBundle";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
  items: Array<{
    __typename?: "ProductBundleItem";
    id: string;
    quantity: number;
    product:
      | {__typename: "Beverage"; id: string; sku: string; name: string}
      | {__typename: "Extra"; id: string; sku: string; name: string}
      | {__typename: "ProductBundle"; id: string; sku: string; name: string};
  }>;
};

export type ExtraSummaryFragment = {
  __typename?: "Extra";
  id: string;
  sku: string;
  name: string;
  grossWeight?: number | null;
  kind: ProductKind;
  organic: boolean;
  shopCountries: Array<string>;
  shopGlobalUrl?: string | null;
  prepacked: boolean;
  ogImageUrl?: string | null;
  caseQuantityRetail: number;
  caseQuantityWholesale: number;
  minOrderQuantityRetail: number;
  minOrderQuantityWholesale: number;
  status: ProductStatus;
  extraKind?: ExtraKind | null;
  physicalProduct: boolean;
  cnCode?: string | null;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantity: number;
    quantityAvailable: number;
    quantityReserved: number;
    tracked: boolean;
    items: Array<{
      __typename?: "StockItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      quantity: number;
      createdAt: any;
    }>;
    warehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
      consignor?: {
        __typename?: "Consignor";
        exciseNumber?: string | null;
        address?: {
          __typename?: "MailingAddress";
          city: string;
          country: string;
          company?: string | null;
          line1: string;
          line2?: string | null;
          name?: string | null;
          phone?: string | null;
          postcode: string;
        } | null;
      } | null;
    };
  }>;
  productPrices: Array<{
    __typename?: "ProductPrice";
    price: {__typename?: "Money"; amount: any; currency: string};
    market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
  }>;
  images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
  brand?: {__typename?: "Brand"; id: string; name: string} | null;
  allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
};

export type BundleItemBasicsFragment = {
  __typename?: "ProductBundleItem";
  id: string;
  quantity: number;
  product:
    | {__typename: "Beverage"; id: string; sku: string; name: string}
    | {__typename: "Extra"; id: string; sku: string; name: string}
    | {__typename: "ProductBundle"; id: string; sku: string; name: string};
};

export type ProductPriceSummaryFragment = {
  __typename?: "ProductPrice";
  price: {__typename?: "Money"; amount: any; currency: string};
  market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
};

export type ProductImageFragment = {__typename?: "Image"; id: string; srcUrl: string; alt?: string | null};

export type ProductStockFragment = {
  __typename?: "Stock";
  id: string;
  quantity: number;
  quantityAvailable: number;
  quantityReserved: number;
  tracked: boolean;
  items: Array<{
    __typename?: "StockItem";
    id: string;
    batchCode: string;
    expiryDate?: any | null;
    quantity: number;
    createdAt: any;
  }>;
  warehouse: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    consignor?: {
      __typename?: "Consignor";
      exciseNumber?: string | null;
      address?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
    } | null;
  };
};

export type ProductStockItemFragment = {
  __typename?: "StockItem";
  id: string;
  batchCode: string;
  expiryDate?: any | null;
  quantity: number;
  createdAt: any;
};

export type ProductBrandSummaryFragment = {__typename?: "Brand"; id: string; name: string};

export type ProductDeletionSummaryFragment = {
  __typename?: "ProductDeletionDetails";
  canBeDeleted: boolean;
  deletionBlockers: Array<{
    __typename?: "ProductDeletionItem";
    kind: ProductDeletionItemKind;
    title: string;
    subtitle?: string | null;
  }>;
  relatedItems: Array<{
    __typename?: "ProductDeletionItem";
    kind: ProductDeletionItemKind;
    title: string;
    subtitle?: string | null;
  }>;
};

export type ProductDeletionBlockersSummaryFragment = {
  __typename?: "ProductDeletionItem";
  kind: ProductDeletionItemKind;
  title: string;
  subtitle?: string | null;
};

export type ProductAllergenFragment = {
  __typename?: "Allergen";
  code: AllergenCode;
  description?: string | null;
  name: string;
};

export type CreateReportQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  reportKind: ReportKind;
  filterBy?: InputMaybe<ReportFilter>;
}>;

export type CreateReportQuery = {
  __typename?: "Query";
  generateReport: {__typename?: "ReportGenerate"; exportUrl: string};
};

export type GetNearestServicePointsQueryVariables = Exact<{
  address: FindServicePointInput;
  shippingCarrier: CarrierKind;
}>;

export type GetNearestServicePointsQuery = {
  __typename?: "Query";
  findServicePoints: Array<{
    __typename?: "ServicePoint";
    id: string;
    name: string;
    typeName?: string | null;
    country: string;
    visitingAddress?: {__typename?: "MailingAddress"; postcode: string; city: string; line1: string} | null;
  }>;
};

export type ServicePointSummaryFragment = {
  __typename?: "ServicePoint";
  id: string;
  name: string;
  typeName?: string | null;
  country: string;
  visitingAddress?: {__typename?: "MailingAddress"; postcode: string; city: string; line1: string} | null;
};

export type AllShippingZonesQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type AllShippingZonesQuery = {
  __typename?: "Query";
  shippingZones?: Array<{
    __typename?: "ShippingZone";
    id: string;
    carrier: CarrierKind;
    country: string;
    currency: string;
    shippingRates: Array<{
      __typename?: "ShippingRate";
      id: string;
      name: string;
      kind: ShippingRateKind;
      status: ShippingRateStatus;
      marketKind: MakerMarketKind;
      price: {__typename?: "Money"; amount: any; currency: string};
      conditionPriceMax?: {__typename?: "Money"; amount: any; currency: string} | null;
      conditionPriceMin?: {__typename?: "Money"; amount: any; currency: string} | null;
    }>;
  }> | null;
};

export type CalculateShippingPriceQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
  input: ShippingCalculatorInput;
}>;

export type CalculateShippingPriceQuery = {
  __typename?: "Query";
  calculateShipping: Array<{
    __typename?: "ShippingCalculatorOffer";
    carrierKind: CarrierKind;
    packages: Array<{
      __typename?: "ShippingCalculatorPackage";
      name: string;
      price: {__typename?: "Money"; amount: any; currency: string};
    }>;
  }>;
};

export type ShippingZoneSummaryFragment = {
  __typename?: "ShippingZone";
  id: string;
  carrier: CarrierKind;
  country: string;
  currency: string;
  shippingRates: Array<{
    __typename?: "ShippingRate";
    id: string;
    name: string;
    kind: ShippingRateKind;
    status: ShippingRateStatus;
    marketKind: MakerMarketKind;
    price: {__typename?: "Money"; amount: any; currency: string};
    conditionPriceMax?: {__typename?: "Money"; amount: any; currency: string} | null;
    conditionPriceMin?: {__typename?: "Money"; amount: any; currency: string} | null;
  }>;
};

export type ShippingRateSummaryFragment = {
  __typename?: "ShippingRate";
  id: string;
  name: string;
  kind: ShippingRateKind;
  status: ShippingRateStatus;
  marketKind: MakerMarketKind;
  price: {__typename?: "Money"; amount: any; currency: string};
  conditionPriceMax?: {__typename?: "Money"; amount: any; currency: string} | null;
  conditionPriceMin?: {__typename?: "Money"; amount: any; currency: string} | null;
};

export type ShippingCalculatorOfferSummaryFragment = {
  __typename?: "ShippingCalculatorOffer";
  carrierKind: CarrierKind;
  packages: Array<{
    __typename?: "ShippingCalculatorPackage";
    name: string;
    price: {__typename?: "Money"; amount: any; currency: string};
  }>;
};

export type GetAllStockMovementsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllStockMovementsQuery = {
  __typename?: "Query";
  stockMovements: Array<{
    __typename?: "StockMovement";
    id: string;
    number: string;
    sentDate?: any | null;
    displayFromWarehouse: string;
    displayToWarehouse: string;
    totalSent?: number | null;
    totalConfirmed?: number | null;
    status: StockMovementStatus;
    items?: Array<{
      __typename?: "StockMovementItem";
      product:
        | {__typename?: "Beverage"; status: ProductStatus}
        | {__typename?: "Extra"; status: ProductStatus}
        | {__typename?: "ProductBundle"; status: ProductStatus};
    }> | null;
  }>;
};

export type GetStockMovementByIdQueryVariables = Exact<{
  stockMovementId: Scalars["ID"]["input"];
}>;

export type GetStockMovementByIdQuery = {
  __typename?: "Query";
  stockMovement: {
    __typename?: "StockMovement";
    id: string;
    number: string;
    sentDate?: any | null;
    displayFromWarehouse: string;
    displayToWarehouse: string;
    arcNumber?: string | null;
    eadDocUrl?: string | null;
    makerNote?: string | null;
    arrivalDate?: any | null;
    totalSent?: number | null;
    totalConfirmed?: number | null;
    status: StockMovementStatus;
    carrierName?: string | null;
    trackingId?: string | null;
    useBemakersSku: boolean;
    numberOfPallets?: number | null;
    items?: Array<{
      __typename?: "StockMovementItem";
      id: string;
      batchCode: string;
      expiryDate?: any | null;
      noExpiry: boolean;
      quantitySent: number;
      quantityConfirmed?: number | null;
      boxWidth?: number | null;
      boxHeight?: number | null;
      boxLength?: number | null;
      productsPerBox?: number | null;
      product:
        | {
            __typename?: "Beverage";
            id: string;
            sku: string;
            bemakersSku: string;
            name: string;
            status: ProductStatus;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          }
        | {
            __typename?: "Extra";
            id: string;
            sku: string;
            bemakersSku: string;
            name: string;
            status: ProductStatus;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          }
        | {
            __typename?: "ProductBundle";
            id: string;
            sku: string;
            bemakersSku: string;
            name: string;
            status: ProductStatus;
            images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          };
    }> | null;
    fromWarehouse?: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    } | null;
    toWarehouse: {
      __typename?: "Warehouse";
      code?: string | null;
      dispatchOffice: string;
      displayName: string;
      email: string;
      exciseNumber?: string | null;
      id: string;
      kind: WarehouseKind;
      status: WarehouseStatus;
      country: string;
      address: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      };
      options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    };
  };
};

export type StockMovementShortSummaryFragment = {
  __typename?: "StockMovement";
  id: string;
  number: string;
  sentDate?: any | null;
  displayFromWarehouse: string;
  displayToWarehouse: string;
  totalSent?: number | null;
  totalConfirmed?: number | null;
  status: StockMovementStatus;
  items?: Array<{
    __typename?: "StockMovementItem";
    product:
      | {__typename?: "Beverage"; status: ProductStatus}
      | {__typename?: "Extra"; status: ProductStatus}
      | {__typename?: "ProductBundle"; status: ProductStatus};
  }> | null;
};

export type StockMovementSummaryFragment = {
  __typename?: "StockMovement";
  id: string;
  number: string;
  sentDate?: any | null;
  displayFromWarehouse: string;
  displayToWarehouse: string;
  arcNumber?: string | null;
  eadDocUrl?: string | null;
  makerNote?: string | null;
  arrivalDate?: any | null;
  totalSent?: number | null;
  totalConfirmed?: number | null;
  status: StockMovementStatus;
  carrierName?: string | null;
  trackingId?: string | null;
  useBemakersSku: boolean;
  numberOfPallets?: number | null;
  items?: Array<{
    __typename?: "StockMovementItem";
    id: string;
    batchCode: string;
    expiryDate?: any | null;
    noExpiry: boolean;
    quantitySent: number;
    quantityConfirmed?: number | null;
    boxWidth?: number | null;
    boxHeight?: number | null;
    boxLength?: number | null;
    productsPerBox?: number | null;
    product:
      | {
          __typename?: "Beverage";
          id: string;
          sku: string;
          bemakersSku: string;
          name: string;
          status: ProductStatus;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        }
      | {
          __typename?: "Extra";
          id: string;
          sku: string;
          bemakersSku: string;
          name: string;
          status: ProductStatus;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          bemakersSku: string;
          name: string;
          status: ProductStatus;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        };
  }> | null;
  fromWarehouse?: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
  } | null;
  toWarehouse: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
  };
};

export type StockMovementItemSummaryFragment = {
  __typename?: "StockMovementItem";
  id: string;
  batchCode: string;
  expiryDate?: any | null;
  noExpiry: boolean;
  quantitySent: number;
  quantityConfirmed?: number | null;
  boxWidth?: number | null;
  boxHeight?: number | null;
  boxLength?: number | null;
  productsPerBox?: number | null;
  product:
    | {
        __typename?: "Beverage";
        id: string;
        sku: string;
        bemakersSku: string;
        name: string;
        status: ProductStatus;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      }
    | {
        __typename?: "Extra";
        id: string;
        sku: string;
        bemakersSku: string;
        name: string;
        status: ProductStatus;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        bemakersSku: string;
        name: string;
        status: ProductStatus;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
      };
};

export type StockMovementWarehouseSummaryFragment = {
  __typename?: "Warehouse";
  code?: string | null;
  dispatchOffice: string;
  displayName: string;
  email: string;
  exciseNumber?: string | null;
  id: string;
  kind: WarehouseKind;
  status: WarehouseStatus;
  country: string;
  address: {
    __typename?: "MailingAddress";
    city: string;
    country: string;
    company?: string | null;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
  };
  options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
};

export type GetIntegrationCredentialsInfoQueryVariables = Exact<{[key: string]: never}>;

export type GetIntegrationCredentialsInfoQuery = {
  __typename?: "Query";
  current?: {
    __typename?: "Current";
    currentMaker?: {__typename?: "CurrentMaker"; integrations?: Array<IntegrationCredentialKind> | null} | null;
  } | null;
};

export type GetTracezillaOrderQueryVariables = Exact<{
  tracezillaOrderId: Scalars["ID"]["input"];
}>;

export type GetTracezillaOrderQuery = {
  __typename?: "Query";
  tracezillaOrder?: {
    __typename?: "TracezillaOrder";
    number: string;
    order: {__typename?: "Order"; id: string; number: string; combinedDocsUrl?: string | null; status: OrderStatus};
  } | null;
};

export type GetMembershipListQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetMembershipListQuery = {
  __typename?: "Query";
  memberships?: Array<{
    __typename?: "Membership";
    id: string;
    constraintKind: MembershipConstraintKind;
    countryConstraints: Array<string>;
    role: MembershipRole;
    user: {
      __typename?: "User";
      id: string;
      name?: string | null;
      email: string;
      status: UserStatus;
      lastSeenAt?: any | null;
    };
  }> | null;
};

export type GetInvitationListQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetInvitationListQuery = {
  __typename?: "Query";
  invitations?: Array<{
    __typename?: "Invitation";
    id: string;
    name?: string | null;
    email: string;
    acceptedAt?: any | null;
    status: InvitationStatus;
    sentAt?: any | null;
    constraintKind: MembershipConstraintKind;
    countryConstraints: Array<string>;
    role: MembershipRole;
    invitedBy?: {__typename?: "User"; name?: string | null} | null;
  }> | null;
};

export type GetUserListQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetUserListQuery = {
  __typename?: "Query";
  users?: Array<{
    __typename?: "User";
    id: string;
    name?: string | null;
    email: string;
    status: UserStatus;
    lastSeenAt?: any | null;
  }> | null;
};

export type GetInvitationByIdQueryVariables = Exact<{
  invitationId: Scalars["ID"]["input"];
}>;

export type GetInvitationByIdQuery = {
  __typename?: "Query";
  invitation?: {
    __typename?: "Invitation";
    id: string;
    name?: string | null;
    email: string;
    acceptedAt?: any | null;
    status: InvitationStatus;
    sentAt?: any | null;
    constraintKind: MembershipConstraintKind;
    countryConstraints: Array<string>;
    role: MembershipRole;
    invitedBy?: {__typename?: "User"; name?: string | null} | null;
  } | null;
};

export type UserSummaryFragment = {
  __typename?: "User";
  id: string;
  name?: string | null;
  email: string;
  status: UserStatus;
  lastSeenAt?: any | null;
};

export type MembershipSummaryFragment = {
  __typename?: "Membership";
  id: string;
  constraintKind: MembershipConstraintKind;
  countryConstraints: Array<string>;
  role: MembershipRole;
  user: {
    __typename?: "User";
    id: string;
    name?: string | null;
    email: string;
    status: UserStatus;
    lastSeenAt?: any | null;
  };
};

export type InvitationSummaryFragment = {
  __typename?: "Invitation";
  id: string;
  name?: string | null;
  email: string;
  acceptedAt?: any | null;
  status: InvitationStatus;
  sentAt?: any | null;
  constraintKind: MembershipConstraintKind;
  countryConstraints: Array<string>;
  role: MembershipRole;
  invitedBy?: {__typename?: "User"; name?: string | null} | null;
};

export type GetAllWarehousesQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetAllWarehousesQuery = {
  __typename?: "Query";
  warehouses: Array<{
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    consignor?: {
      __typename?: "Consignor";
      exciseNumber?: string | null;
      address?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
    } | null;
  }>;
};

export type GetWarehouseByOrderQueryVariables = Exact<{
  order: OrderInput;
}>;

export type GetWarehouseByOrderQuery = {
  __typename?: "Query";
  findWarehouse?: {
    __typename?: "Warehouse";
    code?: string | null;
    dispatchOffice: string;
    displayName: string;
    email: string;
    exciseNumber?: string | null;
    id: string;
    kind: WarehouseKind;
    status: WarehouseStatus;
    country: string;
    address: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    };
    options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
    consignor?: {
      __typename?: "Consignor";
      exciseNumber?: string | null;
      address?: {
        __typename?: "MailingAddress";
        city: string;
        country: string;
        company?: string | null;
        line1: string;
        line2?: string | null;
        name?: string | null;
        phone?: string | null;
        postcode: string;
      } | null;
    } | null;
  } | null;
};

export type WarehouseSummaryFragment = {
  __typename?: "Warehouse";
  code?: string | null;
  dispatchOffice: string;
  displayName: string;
  email: string;
  exciseNumber?: string | null;
  id: string;
  kind: WarehouseKind;
  status: WarehouseStatus;
  country: string;
  address: {
    __typename?: "MailingAddress";
    city: string;
    country: string;
    company?: string | null;
    line1: string;
    line2?: string | null;
    name?: string | null;
    phone?: string | null;
    postcode: string;
  };
  options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
  consignor?: {
    __typename?: "Consignor";
    exciseNumber?: string | null;
    address?: {
      __typename?: "MailingAddress";
      city: string;
      country: string;
      company?: string | null;
      line1: string;
      line2?: string | null;
      name?: string | null;
      phone?: string | null;
      postcode: string;
    } | null;
  } | null;
};

export type AllShopsQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type AllShopsQuery = {
  __typename?: "Query";
  shops?: Array<{
    __typename?: "Shop";
    id: string;
    fullUrl?: string | null;
    country: string;
    shopProductsCount: number;
    status: ShopStatus;
    seoTitle?: string | null;
    seoDescription?: string | null;
    paymentMethods?: Array<CartPaymentMethod> | null;
    paymentMethodsMode: PaymentMethodsMode;
    validCustomPaymentMethods: Array<CartPaymentMethod>;
    automaticPaymentMethods: Array<CartPaymentMethod>;
    collections: Array<{
      __typename?: "Collection";
      id: string;
      position: number;
      name: string;
      salesChannels?: Array<SalesChannels> | null;
      status: CollectionStatus;
      shopBanner?: {__typename?: "ShopBanner"; name: string} | null;
      items: Array<{__typename?: "CollectionItem"; position: number}>;
    }>;
    store: {__typename?: "Store"; seoDescription: string; seoTitle: string; cssStyles?: string | null};
  }> | null;
};

export type GetStoreQueryVariables = Exact<{
  makerId: Scalars["ID"]["input"];
}>;

export type GetStoreQuery = {
  __typename?: "Query";
  store?: {
    __typename?: "Store";
    cssStyles?: string | null;
    facebookPixel?: string | null;
    facebookUrl?: string | null;
    googleAnalytics?: string | null;
    googleTagManager?: string | null;
    klaviyoId?: string | null;
    homepageUrl: string;
    storeHomepageUrl?: string | null;
    instagramUrl?: string | null;
    linkedinUrl?: string | null;
    fullUrl: string;
    name: string;
    seoDescription: string;
    seoTitle: string;
    imageAspectRatio?: AspectRatioMode | null;
  } | null;
};

export type WebshopSummaryFragment = {
  __typename?: "Shop";
  id: string;
  fullUrl?: string | null;
  country: string;
  shopProductsCount: number;
  status: ShopStatus;
  seoTitle?: string | null;
  seoDescription?: string | null;
  paymentMethods?: Array<CartPaymentMethod> | null;
  paymentMethodsMode: PaymentMethodsMode;
  validCustomPaymentMethods: Array<CartPaymentMethod>;
  automaticPaymentMethods: Array<CartPaymentMethod>;
  collections: Array<{
    __typename?: "Collection";
    id: string;
    position: number;
    name: string;
    salesChannels?: Array<SalesChannels> | null;
    status: CollectionStatus;
    shopBanner?: {__typename?: "ShopBanner"; name: string} | null;
    items: Array<{__typename?: "CollectionItem"; position: number}>;
  }>;
  store: {__typename?: "Store"; seoDescription: string; seoTitle: string; cssStyles?: string | null};
};

export type ShopConfigurationSummaryFragment = {
  __typename?: "Shop";
  status: ShopStatus;
  seoTitle?: string | null;
  seoDescription?: string | null;
  paymentMethods?: Array<CartPaymentMethod> | null;
  paymentMethodsMode: PaymentMethodsMode;
  validCustomPaymentMethods: Array<CartPaymentMethod>;
  automaticPaymentMethods: Array<CartPaymentMethod>;
};

export type StoreSummaryFragment = {
  __typename?: "Store";
  cssStyles?: string | null;
  facebookPixel?: string | null;
  facebookUrl?: string | null;
  googleAnalytics?: string | null;
  googleTagManager?: string | null;
  klaviyoId?: string | null;
  homepageUrl: string;
  storeHomepageUrl?: string | null;
  instagramUrl?: string | null;
  linkedinUrl?: string | null;
  fullUrl: string;
  name: string;
  seoDescription: string;
  seoTitle: string;
  imageAspectRatio?: AspectRatioMode | null;
};

export type GetAllCollectionsQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
}>;

export type GetAllCollectionsQuery = {
  __typename?: "Query";
  collections?: Array<{
    __typename?: "Collection";
    id: string;
    position: number;
    name: string;
    salesChannels?: Array<SalesChannels> | null;
    status: CollectionStatus;
    shopBanner?: {__typename?: "ShopBanner"; name: string} | null;
    items: Array<{__typename?: "CollectionItem"; position: number}>;
  }> | null;
};

export type GetCollectionByIdQueryVariables = Exact<{
  collectionId: Scalars["ID"]["input"];
}>;

export type GetCollectionByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "Collection";
    id: string;
    name: string;
    htmlDescription?: string | null;
    jsonDescription?: string | null;
    status: CollectionStatus;
    position: number;
    ogImageUrl?: string | null;
    salesChannels?: Array<SalesChannels> | null;
    shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
    items: Array<{
      __typename?: "CollectionItem";
      position: number;
      createdAt: any;
      shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
    }>;
  } | null;
};

export type GetExtendedCollectionByIdQueryVariables = Exact<{
  collectionId: Scalars["ID"]["input"];
}>;

export type GetExtendedCollectionByIdQuery = {
  __typename?: "Query";
  collection?: {
    __typename?: "Collection";
    id: string;
    name: string;
    htmlDescription?: string | null;
    jsonDescription?: string | null;
    status: CollectionStatus;
    position: number;
    ogImageUrl?: string | null;
    ogDescription?: string | null;
    ogTitle?: string | null;
    seoDescription?: string | null;
    seoTitle?: string | null;
    slug?: string | null;
    fullUrl?: string | null;
    defaultTitle: string;
    defaultDescription?: string | null;
    salesChannels?: Array<SalesChannels> | null;
    items: Array<{
      __typename?: "CollectionItem";
      position: number;
      createdAt: any;
      shopProduct: {
        __typename?: "ShopProduct";
        id: string;
        name: string;
        status: ShopProductStatus;
        product:
          | {
              __typename?: "Beverage";
              sku: string;
              productPrices: Array<{
                __typename?: "ProductPrice";
                price: {__typename?: "Money"; amount: any; currency: string};
                market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
              }>;
            }
          | {
              __typename?: "Extra";
              sku: string;
              productPrices: Array<{
                __typename?: "ProductPrice";
                price: {__typename?: "Money"; amount: any; currency: string};
                market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
              }>;
            }
          | {
              __typename?: "ProductBundle";
              sku: string;
              productPrices: Array<{
                __typename?: "ProductPrice";
                price: {__typename?: "Money"; amount: any; currency: string};
                market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
              }>;
            };
      };
    }>;
    shopBanner?: {
      __typename?: "ShopBanner";
      id: string;
      status: ShopBannerStatus;
      name: string;
      headingText?: string | null;
      subheadingText?: string | null;
      buttonLink?: string | null;
      buttonLabel?: string | null;
      backgroundImageUrl?: string | null;
      backgroundVideoUrl?: string | null;
      aspectRatio: AspectRatioMode;
      overlayBg: boolean;
      collections: Array<{
        __typename?: "Collection";
        id: string;
        name: string;
        htmlDescription?: string | null;
        jsonDescription?: string | null;
        status: CollectionStatus;
        position: number;
        ogImageUrl?: string | null;
        salesChannels?: Array<SalesChannels> | null;
        shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
        items: Array<{
          __typename?: "CollectionItem";
          position: number;
          createdAt: any;
          shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
        }>;
      }>;
    } | null;
  } | null;
};

export type CollectionShortSummaryFragment = {
  __typename?: "Collection";
  id: string;
  position: number;
  name: string;
  salesChannels?: Array<SalesChannels> | null;
  status: CollectionStatus;
  shopBanner?: {__typename?: "ShopBanner"; name: string} | null;
  items: Array<{__typename?: "CollectionItem"; position: number}>;
};

export type CollectionSummaryFragment = {
  __typename?: "Collection";
  id: string;
  name: string;
  htmlDescription?: string | null;
  jsonDescription?: string | null;
  status: CollectionStatus;
  position: number;
  ogImageUrl?: string | null;
  salesChannels?: Array<SalesChannels> | null;
  shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
  items: Array<{
    __typename?: "CollectionItem";
    position: number;
    createdAt: any;
    shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
  }>;
};

export type ExtendedCollectionSummaryFragment = {
  __typename?: "Collection";
  id: string;
  name: string;
  htmlDescription?: string | null;
  jsonDescription?: string | null;
  status: CollectionStatus;
  position: number;
  ogImageUrl?: string | null;
  ogDescription?: string | null;
  ogTitle?: string | null;
  seoDescription?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  fullUrl?: string | null;
  defaultTitle: string;
  defaultDescription?: string | null;
  salesChannels?: Array<SalesChannels> | null;
  items: Array<{
    __typename?: "CollectionItem";
    position: number;
    createdAt: any;
    shopProduct: {
      __typename?: "ShopProduct";
      id: string;
      name: string;
      status: ShopProductStatus;
      product:
        | {
            __typename?: "Beverage";
            sku: string;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          }
        | {
            __typename?: "Extra";
            sku: string;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          }
        | {
            __typename?: "ProductBundle";
            sku: string;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          };
    };
  }>;
  shopBanner?: {
    __typename?: "ShopBanner";
    id: string;
    status: ShopBannerStatus;
    name: string;
    headingText?: string | null;
    subheadingText?: string | null;
    buttonLink?: string | null;
    buttonLabel?: string | null;
    backgroundImageUrl?: string | null;
    backgroundVideoUrl?: string | null;
    aspectRatio: AspectRatioMode;
    overlayBg: boolean;
    collections: Array<{
      __typename?: "Collection";
      id: string;
      name: string;
      htmlDescription?: string | null;
      jsonDescription?: string | null;
      status: CollectionStatus;
      position: number;
      ogImageUrl?: string | null;
      salesChannels?: Array<SalesChannels> | null;
      shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
      items: Array<{
        __typename?: "CollectionItem";
        position: number;
        createdAt: any;
        shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
      }>;
    }>;
  } | null;
};

export type GetDiscountPercentageByIdQueryVariables = Exact<{
  discountId: Scalars["ID"]["input"];
}>;

export type GetDiscountPercentageByIdQuery = {
  __typename?: "Query";
  discount?:
    | {__typename?: "DiscountAmount"}
    | {
        __typename?: "DiscountPercentage";
        amountPercentage: number;
        activeFrom?: any | null;
        activeTo?: any | null;
        code: string;
        conditionOnQuantity?: number | null;
        conditionType: DiscountCondition;
        createdAt: any;
        displayStatus: DiscountDisplayStatus;
        id: string;
        kind: DiscountKind;
        limitPerUser?: number | null;
        limitTotal?: number | null;
        status: DiscountStatus;
        salesChannels: Array<SalesChannels>;
        conditionPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
      }
    | null;
};

export type GetAllShopDiscountsQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
}>;

export type GetAllShopDiscountsQuery = {
  __typename?: "Query";
  discounts: Array<
    | {__typename?: "DiscountAmount"}
    | {
        __typename?: "DiscountPercentage";
        id: string;
        code: string;
        salesChannels: Array<SalesChannels>;
        displayStatus: DiscountDisplayStatus;
        amountPercentage: number;
      }
  >;
};

type DiscountCommonSummary_DiscountAmount_Fragment = {
  __typename?: "DiscountAmount";
  activeFrom?: any | null;
  activeTo?: any | null;
  code: string;
  conditionOnQuantity?: number | null;
  conditionType: DiscountCondition;
  createdAt: any;
  displayStatus: DiscountDisplayStatus;
  id: string;
  kind: DiscountKind;
  limitPerUser?: number | null;
  limitTotal?: number | null;
  status: DiscountStatus;
  salesChannels: Array<SalesChannels>;
  conditionPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
};

type DiscountCommonSummary_DiscountPercentage_Fragment = {
  __typename?: "DiscountPercentage";
  activeFrom?: any | null;
  activeTo?: any | null;
  code: string;
  conditionOnQuantity?: number | null;
  conditionType: DiscountCondition;
  createdAt: any;
  displayStatus: DiscountDisplayStatus;
  id: string;
  kind: DiscountKind;
  limitPerUser?: number | null;
  limitTotal?: number | null;
  status: DiscountStatus;
  salesChannels: Array<SalesChannels>;
  conditionPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
};

export type DiscountCommonSummaryFragment =
  | DiscountCommonSummary_DiscountAmount_Fragment
  | DiscountCommonSummary_DiscountPercentage_Fragment;

export type DiscountPercentageSummaryFragment = {
  __typename?: "DiscountPercentage";
  amountPercentage: number;
  activeFrom?: any | null;
  activeTo?: any | null;
  code: string;
  conditionOnQuantity?: number | null;
  conditionType: DiscountCondition;
  createdAt: any;
  displayStatus: DiscountDisplayStatus;
  id: string;
  kind: DiscountKind;
  limitPerUser?: number | null;
  limitTotal?: number | null;
  status: DiscountStatus;
  salesChannels: Array<SalesChannels>;
  conditionPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
};

export type DiscountPercentageShortSummaryFragment = {
  __typename?: "DiscountPercentage";
  id: string;
  code: string;
  salesChannels: Array<SalesChannels>;
  displayStatus: DiscountDisplayStatus;
  amountPercentage: number;
};

export type DiscountAmountSummaryFragment = {
  __typename?: "DiscountAmount";
  activeFrom?: any | null;
  activeTo?: any | null;
  code: string;
  conditionOnQuantity?: number | null;
  conditionType: DiscountCondition;
  createdAt: any;
  displayStatus: DiscountDisplayStatus;
  id: string;
  kind: DiscountKind;
  limitPerUser?: number | null;
  limitTotal?: number | null;
  status: DiscountStatus;
  salesChannels: Array<SalesChannels>;
  amountValue: {__typename?: "Money"; amount: any; currency: string};
  conditionPrice?: {__typename?: "Money"; amount: any; currency: string} | null;
};

export type GetWebshopProductByIdQueryVariables = Exact<{
  shopProductId: Scalars["ID"]["input"];
}>;

export type GetWebshopProductByIdQuery = {
  __typename?: "Query";
  shopProduct?: {
    __typename?: "ShopProduct";
    id: string;
    name: string;
    htmlDescription?: string | null;
    jsonDescription?: string | null;
    status: ShopProductStatus;
    defaultOgImageUrl?: string | null;
    ogDescription?: string | null;
    ogTitle?: string | null;
    salesChannels: Array<SalesChannels>;
    seoDescription?: string | null;
    seoTitle?: string | null;
    slug?: string | null;
    fullUrl?: string | null;
    defaultTitle: string;
    defaultDescription: string;
    collections: Array<{
      __typename?: "Collection";
      id: string;
      name: string;
      htmlDescription?: string | null;
      jsonDescription?: string | null;
      status: CollectionStatus;
      position: number;
      ogImageUrl?: string | null;
      salesChannels?: Array<SalesChannels> | null;
      shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
      items: Array<{
        __typename?: "CollectionItem";
        position: number;
        createdAt: any;
        shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
      }>;
    }>;
    stocks: Array<{
      __typename?: "Stock";
      id: string;
      quantityAvailable: number;
      tracked: boolean;
      warehouse: {__typename?: "Warehouse"; displayName: string};
    }>;
    product:
      | {
          __typename?: "Beverage";
          alcoholPercent?: number | null;
          netWeight?: number | null;
          cnCode?: string | null;
          exciseProductCode?: string | null;
          volume?: number | null;
          packagingCode?: string | null;
          degreePlato?: number | null;
          gtin?: string | null;
          premix?: PremixKind | null;
          sugarPerLiter?: number | null;
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "Extra";
          extraKind?: ExtraKind | null;
          physicalProduct: boolean;
          cnCode?: string | null;
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        }
      | {
          __typename?: "ProductBundle";
          id: string;
          sku: string;
          name: string;
          grossWeight?: number | null;
          kind: ProductKind;
          organic: boolean;
          shopCountries: Array<string>;
          shopGlobalUrl?: string | null;
          prepacked: boolean;
          ogImageUrl?: string | null;
          caseQuantityRetail: number;
          caseQuantityWholesale: number;
          minOrderQuantityRetail: number;
          minOrderQuantityWholesale: number;
          status: ProductStatus;
          items: Array<{
            __typename?: "ProductBundleItem";
            id: string;
            quantity: number;
            product:
              | {__typename: "Beverage"; id: string; sku: string; name: string}
              | {__typename: "Extra"; id: string; sku: string; name: string}
              | {__typename: "ProductBundle"; id: string; sku: string; name: string};
          }>;
          stocks: Array<{
            __typename?: "Stock";
            id: string;
            quantity: number;
            quantityAvailable: number;
            quantityReserved: number;
            tracked: boolean;
            items: Array<{
              __typename?: "StockItem";
              id: string;
              batchCode: string;
              expiryDate?: any | null;
              quantity: number;
              createdAt: any;
            }>;
            warehouse: {
              __typename?: "Warehouse";
              code?: string | null;
              dispatchOffice: string;
              displayName: string;
              email: string;
              exciseNumber?: string | null;
              id: string;
              kind: WarehouseKind;
              status: WarehouseStatus;
              country: string;
              address: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              };
              options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
              consignor?: {
                __typename?: "Consignor";
                exciseNumber?: string | null;
                address?: {
                  __typename?: "MailingAddress";
                  city: string;
                  country: string;
                  company?: string | null;
                  line1: string;
                  line2?: string | null;
                  name?: string | null;
                  phone?: string | null;
                  postcode: string;
                } | null;
              } | null;
            };
          }>;
          productPrices: Array<{
            __typename?: "ProductPrice";
            price: {__typename?: "Money"; amount: any; currency: string};
            market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
          }>;
          images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
          brand?: {__typename?: "Brand"; id: string; name: string} | null;
          allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
        };
  } | null;
};

export type PaginatedAllWebshopProductsQueryVariables = Exact<{
  shopId: Scalars["ID"]["input"];
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  after?: InputMaybe<Scalars["String"]["input"]>;
  sortBy?: InputMaybe<ShopProductSorting>;
  filterBy?: InputMaybe<ShopProductSearchInput>;
}>;

export type PaginatedAllWebshopProductsQuery = {
  __typename?: "Query";
  allShopProducts?: {
    __typename?: "ShopProductConnection";
    nodes: Array<{
      __typename?: "ShopProduct";
      id: string;
      name: string;
      status: ShopProductStatus;
      stocks: Array<{
        __typename?: "Stock";
        id: string;
        quantityAvailable: number;
        tracked: boolean;
        warehouse: {__typename?: "Warehouse"; displayName: string};
      }>;
      product:
        | {
            __typename?: "Beverage";
            id: string;
            sku: string;
            kind: ProductKind;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          }
        | {
            __typename?: "Extra";
            id: string;
            sku: string;
            kind: ProductKind;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          }
        | {
            __typename?: "ProductBundle";
            id: string;
            sku: string;
            kind: ProductKind;
            productPrices: Array<{
              __typename?: "ProductPrice";
              price: {__typename?: "Money"; amount: any; currency: string};
              market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
            }>;
          };
    }>;
    pageInfo: {
      __typename?: "PageInfo";
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: string | null;
      endCursor?: string | null;
    };
  } | null;
};

export type WebshopProductSummaryFragment = {
  __typename?: "ShopProduct";
  id: string;
  name: string;
  htmlDescription?: string | null;
  jsonDescription?: string | null;
  status: ShopProductStatus;
  defaultOgImageUrl?: string | null;
  ogDescription?: string | null;
  ogTitle?: string | null;
  salesChannels: Array<SalesChannels>;
  seoDescription?: string | null;
  seoTitle?: string | null;
  slug?: string | null;
  fullUrl?: string | null;
  defaultTitle: string;
  defaultDescription: string;
  collections: Array<{
    __typename?: "Collection";
    id: string;
    name: string;
    htmlDescription?: string | null;
    jsonDescription?: string | null;
    status: CollectionStatus;
    position: number;
    ogImageUrl?: string | null;
    salesChannels?: Array<SalesChannels> | null;
    shopBanner?: {__typename?: "ShopBanner"; id: string; name: string} | null;
    items: Array<{
      __typename?: "CollectionItem";
      position: number;
      createdAt: any;
      shopProduct: {__typename?: "ShopProduct"; id: string; name: string};
    }>;
  }>;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantityAvailable: number;
    tracked: boolean;
    warehouse: {__typename?: "Warehouse"; displayName: string};
  }>;
  product:
    | {
        __typename?: "Beverage";
        alcoholPercent?: number | null;
        netWeight?: number | null;
        cnCode?: string | null;
        exciseProductCode?: string | null;
        volume?: number | null;
        packagingCode?: string | null;
        degreePlato?: number | null;
        gtin?: string | null;
        premix?: PremixKind | null;
        sugarPerLiter?: number | null;
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "Extra";
        extraKind?: ExtraKind | null;
        physicalProduct: boolean;
        cnCode?: string | null;
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        name: string;
        grossWeight?: number | null;
        kind: ProductKind;
        organic: boolean;
        shopCountries: Array<string>;
        shopGlobalUrl?: string | null;
        prepacked: boolean;
        ogImageUrl?: string | null;
        caseQuantityRetail: number;
        caseQuantityWholesale: number;
        minOrderQuantityRetail: number;
        minOrderQuantityWholesale: number;
        status: ProductStatus;
        items: Array<{
          __typename?: "ProductBundleItem";
          id: string;
          quantity: number;
          product:
            | {__typename: "Beverage"; id: string; sku: string; name: string}
            | {__typename: "Extra"; id: string; sku: string; name: string}
            | {__typename: "ProductBundle"; id: string; sku: string; name: string};
        }>;
        stocks: Array<{
          __typename?: "Stock";
          id: string;
          quantity: number;
          quantityAvailable: number;
          quantityReserved: number;
          tracked: boolean;
          items: Array<{
            __typename?: "StockItem";
            id: string;
            batchCode: string;
            expiryDate?: any | null;
            quantity: number;
            createdAt: any;
          }>;
          warehouse: {
            __typename?: "Warehouse";
            code?: string | null;
            dispatchOffice: string;
            displayName: string;
            email: string;
            exciseNumber?: string | null;
            id: string;
            kind: WarehouseKind;
            status: WarehouseStatus;
            country: string;
            address: {
              __typename?: "MailingAddress";
              city: string;
              country: string;
              company?: string | null;
              line1: string;
              line2?: string | null;
              name?: string | null;
              phone?: string | null;
              postcode: string;
            };
            options: {__typename?: "WarehouseOptions"; lowQuantityAlert?: number | null};
            consignor?: {
              __typename?: "Consignor";
              exciseNumber?: string | null;
              address?: {
                __typename?: "MailingAddress";
                city: string;
                country: string;
                company?: string | null;
                line1: string;
                line2?: string | null;
                name?: string | null;
                phone?: string | null;
                postcode: string;
              } | null;
            } | null;
          };
        }>;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
        images: Array<{__typename?: "Image"; id: string; srcUrl: string; alt?: string | null}>;
        brand?: {__typename?: "Brand"; id: string; name: string} | null;
        allergens: Array<{__typename?: "Allergen"; code: AllergenCode; description?: string | null; name: string}>;
      };
};

export type WebshopProductSmallFragment = {
  __typename?: "ShopProduct";
  id: string;
  name: string;
  status: ShopProductStatus;
  stocks: Array<{
    __typename?: "Stock";
    id: string;
    quantityAvailable: number;
    tracked: boolean;
    warehouse: {__typename?: "Warehouse"; displayName: string};
  }>;
  product:
    | {
        __typename?: "Beverage";
        id: string;
        sku: string;
        kind: ProductKind;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
      }
    | {
        __typename?: "Extra";
        id: string;
        sku: string;
        kind: ProductKind;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
      }
    | {
        __typename?: "ProductBundle";
        id: string;
        sku: string;
        kind: ProductKind;
        productPrices: Array<{
          __typename?: "ProductPrice";
          price: {__typename?: "Money"; amount: any; currency: string};
          market: {__typename?: "MakerMarket"; id: string; country: string; kind: MakerMarketKind};
        }>;
      };
};

export type WebshopProductStockFragment = {
  __typename?: "Stock";
  id: string;
  quantityAvailable: number;
  tracked: boolean;
  warehouse: {__typename?: "Warehouse"; displayName: string};
};

export const PageInfoSummaryFragmentDoc = `
    fragment PageInfoSummary on PageInfo {
  hasNextPage
  hasPreviousPage
  startCursor
  endCursor
}
    `;
export const TransactionFeeSummaryFragmentDoc = `
    fragment TransactionFeeSummary on TransactionFee {
  kind
  text
  amount {
    amount
    currency
  }
}
    `;
export const BalanceOverviewSummaryFragmentDoc = `
    fragment BalanceOverviewSummary on BalanceOverview {
  period
  balance {
    amount
    currency
  }
  fees {
    ...TransactionFeeSummary
  }
  totalFees {
    amount
    currency
  }
  totalPayments {
    amount
    currency
  }
}
    ${TransactionFeeSummaryFragmentDoc}`;
export const OutstandingPaymentsSummaryFragmentDoc = `
    fragment OutstandingPaymentsSummary on OutstandingPayments {
  outstandingAmount {
    amount
    currency
  }
  overdueAmount {
    amount
    currency
  }
  unpaidAmount {
    amount
    currency
  }
  overdueCount
  unpaidCount
}
    `;
export const FinanceOverviewSummaryFragmentDoc = `
    fragment FinanceOverviewSummary on FinanceOverview {
  balanceOverview {
    ...BalanceOverviewSummary
  }
  outstandingPayments {
    ...OutstandingPaymentsSummary
  }
}
    ${BalanceOverviewSummaryFragmentDoc}
${OutstandingPaymentsSummaryFragmentDoc}`;
export const TransactionsSummaryFragmentDoc = `
    fragment TransactionsSummary on Transaction {
  id
  kind
  orderId
  orderNumber
  text
  bookedAt
  amount {
    amount
    currency
  }
}
    `;
export const AnnouncementBarSummaryFragmentDoc = `
    fragment AnnouncementBarSummary on Announcement {
  id
  status
  text
  salesChannels
}
    `;
export const BrandShortSummaryFragmentDoc = `
    fragment BrandShortSummary on Brand {
  id
  name
  logoUrl
}
    `;
export const BrandSummaryFragmentDoc = `
    fragment BrandSummary on Brand {
  ...BrandShortSummary
  slug
  coverUrl
  descriptionHtml
  descriptionJson
  facebookUrl
  homepageUrl
  instagramUrl
  linkedinUrl
  tagline
}
    ${BrandShortSummaryFragmentDoc}`;
export const CarrierSummaryFragmentDoc = `
    fragment CarrierSummary on Carrier {
  name
  kind
  parcel
  palletQuarter
  palletHalf
  palletFull
  deliveryCollect
  deliveryHome
  estimatedPrice {
    amount
    currency
  }
  deliveryTime
  deliveryTimeOptions {
    timeFrom
    timeTo
  }
  pickupTime
  pickupTimeOptions {
    timeFrom
    timeTo
  }
}
    `;
export const TermsSummaryFragmentDoc = `
    fragment TermsSummary on Terms {
  kind
  name
  url
  version
  updateNotes
}
    `;
export const ChargeMethodSummaryFragmentDoc = `
    fragment ChargeMethodSummary on ChargeMethod {
  id
  kind
  details
  status
}
    `;
export const MakerMarketSummaryFragmentDoc = `
    fragment MakerMarketSummary on MakerMarket {
  id
  kind
  country
}
    `;
export const CsmUserSummaryFragmentDoc = `
    fragment CsmUserSummary on CsmUser {
  name
  email
}
    `;
export const CurrentMakerSummaryFragmentDoc = `
    fragment CurrentMakerSummary on CurrentMaker {
  id
  name
  addons
  countryConstraints
  features
  rights
  trade
  tradeName
  onboarding {
    contractUrl
    termsToAccept {
      ...TermsSummary
    }
    termsToUpdate {
      ...TermsSummary
    }
    chargeMethods {
      ...ChargeMethodSummary
    }
  }
  markets {
    ...MakerMarketSummary
  }
  csm {
    ...CsmUserSummary
  }
}
    ${TermsSummaryFragmentDoc}
${ChargeMethodSummaryFragmentDoc}
${MakerMarketSummaryFragmentDoc}
${CsmUserSummaryFragmentDoc}`;
export const CurrentUserSummaryFragmentDoc = `
    fragment CurrentUserSummary on CurrentUser {
  email
  name
  locale
  timezone
}
    `;
export const MakerCertificateSummaryFragmentDoc = `
    fragment MakerCertificateSummary on MakerCertificate {
  activeTo
  documentUrl
  id
  kind
  name
}
    `;
export const BankAccountSummaryFragmentDoc = `
    fragment BankAccountSummary on BankAccount {
  id
  currency
  details
}
    `;
export const CompanySummaryFragmentDoc = `
    fragment CompanySummary on Maker {
  name
  trade
  tradeName
  country
  taxId
  exciseNumber
  invoiceEmail
  logoUrl
  printLogoUrl
  faviconUrl
  contactName
  contactEmail
  contactPhone
  contractUrl
  address {
    city
    line1
    line2
    postcode
  }
  ecoCertificate {
    ...MakerCertificateSummary
  }
  chargeMethods {
    ...ChargeMethodSummary
  }
  bankAccounts {
    ...BankAccountSummary
  }
}
    ${MakerCertificateSummaryFragmentDoc}
${ChargeMethodSummaryFragmentDoc}
${BankAccountSummaryFragmentDoc}`;
export const PlanSummaryFragmentDoc = `
    fragment PlanSummary on Plan {
  kind
  name
  activeFrom
  terms {
    ...TermsSummary
  }
}
    ${TermsSummaryFragmentDoc}`;
export const ConsentsSummaryFragmentDoc = `
    fragment ConsentsSummary on Consent {
  acceptedAt
  description
  kind
  version
}
    `;
export const ShippingAddressSummaryFragmentDoc = `
    fragment ShippingAddressSummary on MailingAddress {
  city
  company
  country
  default
  line1
  line2
  name
  phone
  postcode
  state
}
    `;
export const BillingAddressSummaryFragmentDoc = `
    fragment BillingAddressSummary on BillingAddress {
  city
  company
  country
  default
  line1
  line2
  name
  phone
  postcode
  state
}
    `;
export const PricingGroupShortSummaryFragmentDoc = `
    fragment PricingGroupShortSummary on PricingGroup {
  id
  name
  description
  priceBase
  customersCount
  discount
  lastActivityAt
  createdBy {
    name
  }
}
    `;
export const CustomerShortSummaryFragmentDoc = `
    fragment CustomerShortSummary on Customer {
  id
  currency
  name
  email
  billingEmail
  ccInvoices
  country
  kind
  paymentTerms
  taxId
  exciseNumber
  exciseTaxWarehouse
  exciseWarehouseKeeper
  exciseDestinationKind
  legalDrinkingAge
  ordersCount
  tags
  consents {
    ...ConsentsSummary
  }
  mailingAddresses {
    ...ShippingAddressSummary
  }
  billingAddresses {
    ...BillingAddressSummary
  }
  pricingGroup {
    ...PricingGroupShortSummary
  }
}
    ${ConsentsSummaryFragmentDoc}
${ShippingAddressSummaryFragmentDoc}
${BillingAddressSummaryFragmentDoc}
${PricingGroupShortSummaryFragmentDoc}`;
export const RecentOrderSummaryFragmentDoc = `
    fragment RecentOrderSummary on Order {
  id
  number
  createdAt
  status
  netTotal {
    amount
    currency
  }
}
    `;
export const PriceSummaryFragmentDoc = `
    fragment PriceSummary on Money {
  amount
  currency
}
    `;
export const CustomerStatsSummaryFragmentDoc = `
    fragment CustomerStatsSummary on CustomerStats {
  averageOrderValue {
    ...PriceSummary
  }
  totalOrdersValue {
    ...PriceSummary
  }
  ordersCount
}
    ${PriceSummaryFragmentDoc}`;
export const CustomerSummaryFragmentDoc = `
    fragment CustomerSummary on Customer {
  ...CustomerShortSummary
  lastOrders {
    ...RecentOrderSummary
  }
  stats {
    ...CustomerStatsSummary
  }
}
    ${CustomerShortSummaryFragmentDoc}
${RecentOrderSummaryFragmentDoc}
${CustomerStatsSummaryFragmentDoc}`;
export const CustomersMetaDataSummaryFragmentDoc = `
    fragment CustomersMetaDataSummary on CustomerExport {
  exportUrl
}
    `;
export const BusinessMemberSummaryFragmentDoc = `
    fragment BusinessMemberSummary on BusinessMember {
  acceptedAt
  email
  id
  name
  sentAt
  status
  consents {
    ...ConsentsSummary
  }
}
    ${ConsentsSummaryFragmentDoc}`;
export const StatementsSummaryFragmentDoc = `
    fragment StatementsSummary on Settlement {
  id
  period
  settledAt
  serviceInvoiceDocUrl
  settlementDocUrl
  balance {
    amount
    currency
  }
  fees {
    amount
    currency
  }
  payments {
    amount
    currency
  }
  payouts {
    amount
    currency
  }
  refunds {
    amount
    currency
  }
}
    `;
export const CommonMailingAddressSummaryFragmentDoc = `
    fragment CommonMailingAddressSummary on MailingAddress {
  city
  country
  company
  line1
  line2
  name
  phone
  postcode
}
    `;
export const WarehouseSummaryFragmentDoc = `
    fragment WarehouseSummary on Warehouse {
  address {
    ...CommonMailingAddressSummary
  }
  code
  dispatchOffice
  displayName
  email
  exciseNumber
  id
  kind
  status
  country
  options {
    lowQuantityAlert
  }
  consignor {
    exciseNumber
    address {
      ...CommonMailingAddressSummary
    }
  }
}
    ${CommonMailingAddressSummaryFragmentDoc}`;
export const FulfillmentRulesSummaryFragmentDoc = `
    fragment FulfillmentRulesSummary on FulfillmentRule {
  destinationCountry
  orderSource
  orderKind
  warehouse {
    ...WarehouseSummary
  }
}
    ${WarehouseSummaryFragmentDoc}`;
export const InvoiceSummaryFragmentDoc = `
    fragment InvoiceSummary on OrderInvoiceDetails {
  id
  invoicedAt
  number
  reverseInvoiceDocUrl
  settlementNumber
  order {
    id
    number
  }
  grossSales {
    amount
    currency
  }
  exciseDuty {
    amount
    currency
  }
  shipping {
    amount
    currency
  }
  recycling {
    amount
    currency
  }
  paymentProcessing {
    amount
    currency
  }
  commission {
    amount
    currency
  }
  netSales {
    amount
    currency
  }
}
    `;
export const InvoiceMetaDataSummaryFragmentDoc = `
    fragment InvoiceMetaDataSummary on InvoiceSummary {
  exportCreditNotesUrl
  exportOrderInvoicesUrl
  totalGrossSales {
    amount
    currency
  }
  totalNetSales {
    amount
    currency
  }
}
    `;
export const OrdersMetaDataSummaryFragmentDoc = `
    fragment OrdersMetaDataSummary on OrderTabsMetadata {
  allTabCount
  closedTabCount
  newTabCount
  openTabCount
  upcomingTabCount
}
    `;
export const ExternalReferenceShortFragmentDoc = `
    fragment ExternalReferenceShort on ExternalReference {
  externalId
  system
}
    `;
export const OrderShortSummaryFragmentDoc = `
    fragment OrderShortSummary on Order {
  id
  number
  createdAt
  currency
  kind
  status
  source
  paymentStatus
  shippingPackage
  shippingTrackingUrl
  shippingId
  packagingDate
  registeredAt
  verificationFailed
  overdueDays
  createdBy {
    id
    email
    impersonation
    kind
    name
  }
  customer {
    id
    name
    country
  }
  orderItems {
    product {
      status
    }
  }
  netTotal {
    amount
    currency
  }
  warehouse {
    id
    kind
    displayName
  }
  externalReferences {
    ...ExternalReferenceShort
  }
}
    ${ExternalReferenceShortFragmentDoc}`;
export const OrderAddressSummaryFragmentDoc = `
    fragment OrderAddressSummary on MailingAddress {
  city
  country
  company
  line1
  line2
  name
  phone
  postcode
}
    `;
export const CommonBillingAddressSummaryFragmentDoc = `
    fragment CommonBillingAddressSummary on BillingAddress {
  city
  country
  company
  line1
  line2
  name
  phone
  postcode
}
    `;
export const ProductStockItemFragmentDoc = `
    fragment ProductStockItem on StockItem {
  id
  batchCode
  expiryDate
  quantity
  createdAt
}
    `;
export const ProductStockFragmentDoc = `
    fragment ProductStock on Stock {
  id
  quantity
  quantityAvailable
  quantityReserved
  tracked
  items {
    ...ProductStockItem
  }
  warehouse {
    ...WarehouseSummary
  }
}
    ${ProductStockItemFragmentDoc}
${WarehouseSummaryFragmentDoc}`;
export const ProductPriceSummaryFragmentDoc = `
    fragment ProductPriceSummary on ProductPrice {
  price {
    ...PriceSummary
  }
  market {
    id
    country
    kind
  }
}
    ${PriceSummaryFragmentDoc}`;
export const ProductImageFragmentDoc = `
    fragment ProductImage on Image {
  id
  srcUrl
  alt
}
    `;
export const ProductBrandSummaryFragmentDoc = `
    fragment ProductBrandSummary on Brand {
  id
  name
}
    `;
export const ProductAllergenFragmentDoc = `
    fragment ProductAllergen on Allergen {
  code
  description
  name
}
    `;
export const ProductSummaryFragmentDoc = `
    fragment ProductSummary on ProductInterface {
  id
  sku
  name
  grossWeight
  kind
  organic
  shopCountries
  shopGlobalUrl
  prepacked
  ogImageUrl
  caseQuantityRetail
  caseQuantityWholesale
  minOrderQuantityRetail
  minOrderQuantityWholesale
  status
  stocks {
    ...ProductStock
  }
  productPrices {
    ...ProductPriceSummary
  }
  images {
    ...ProductImage
  }
  brand {
    ...ProductBrandSummary
  }
  allergens {
    ...ProductAllergen
  }
}
    ${ProductStockFragmentDoc}
${ProductPriceSummaryFragmentDoc}
${ProductImageFragmentDoc}
${ProductBrandSummaryFragmentDoc}
${ProductAllergenFragmentDoc}`;
export const BundleItemBasicsFragmentDoc = `
    fragment BundleItemBasics on ProductBundleItem {
  id
  quantity
  product {
    __typename
    id
    sku
    name
  }
}
    `;
export const OrderItemSummaryFragmentDoc = `
    fragment OrderItemSummary on OrderItem {
  id
  discount
  quantity
  batchCodes {
    productId
    code
    quantity
  }
  price {
    amount
    currency
  }
  totalPrice {
    amount
    currency
  }
  product {
    ...ProductSummary
    ... on ProductBundle {
      items {
        ...BundleItemBasics
      }
    }
  }
}
    ${ProductSummaryFragmentDoc}
${BundleItemBasicsFragmentDoc}`;
export const ServicePointSummaryFragmentDoc = `
    fragment ServicePointSummary on ServicePoint {
  id
  name
  typeName
  country
  visitingAddress {
    postcode
    city
    line1
  }
}
    `;
export const LocationSummaryFragmentDoc = `
    fragment LocationSummary on Location {
  id
  city
  country
  email
  line1
  line2
  mapLink
  name
  phone
  postcode
  status
}
    `;
export const OrderSummaryFragmentDoc = `
    fragment OrderSummary on Order {
  id
  number
  createdAt
  submittedAt
  registeredAt
  shippedAt
  deliveredAt
  paidAt
  settledAt
  writtenOffAt
  status
  source
  paymentStatus
  internalNote
  attentionNote
  shippingPackage
  shippingWeight
  shippingTrackingUrl
  shippingId
  shippingCarrier
  deliveryMethod
  packagingDate
  overdueDays
  waybillDocUrl
  createdBy {
    id
    email
    impersonation
    kind
    name
  }
  externalReferences {
    ...ExternalReferenceShort
  }
  shippingLine {
    name
    kind
    price {
      amount
      currency
    }
  }
  shippingAddress {
    ...OrderAddressSummary
    default
  }
  billingAddress {
    ...CommonBillingAddressSummary
    default
  }
  deliveryAddress {
    ...OrderAddressSummary
  }
  netTotal {
    amount
    currency
  }
  customer {
    ...CustomerShortSummary
  }
  orderItems {
    ...OrderItemSummary
  }
  shippingServicePoint {
    ...ServicePointSummary
  }
  location {
    ...LocationSummary
  }
  discountUsages {
    code
    discountPercentage
    netAmount {
      amount
      currency
    }
  }
  carrierOptions {
    deliveryDate
    deliveryTimeFrom
    deliveryTimeTo
    pickupDate
    pickupTimeFrom
    pickupTimeTo
  }
  warehouse {
    address {
      ...OrderAddressSummary
    }
    code
    dispatchOffice
    displayName
    email
    exciseNumber
    id
    kind
    status
    country
    options {
      lowQuantityAlert
    }
  }
}
    ${ExternalReferenceShortFragmentDoc}
${OrderAddressSummaryFragmentDoc}
${CommonBillingAddressSummaryFragmentDoc}
${CustomerShortSummaryFragmentDoc}
${OrderItemSummaryFragmentDoc}
${ServicePointSummaryFragmentDoc}
${LocationSummaryFragmentDoc}`;
export const PickingListProductSummaryFragmentDoc = `
    fragment PickingListProductSummary on ProductInterface {
  id
  name
  sku
  bemakersSku
  prepacked
}
    `;
export const OrderLabelFragmentDoc = `
    fragment OrderLabel on OrderLabel {
  displayKind
  kind
  productId
  productName
}
    `;
export const PickingListOrderItemSummaryFragmentDoc = `
    fragment PickingListOrderItemSummary on OrderItem {
  id
  quantity
  batchCodes {
    code
    productId
    quantity
  }
  product {
    ...PickingListProductSummary
  }
  bundledItems {
    id
    quantity
    product {
      ...PickingListProductSummary
    }
  }
  orderLabels {
    ...OrderLabel
  }
}
    ${PickingListProductSummaryFragmentDoc}
${OrderLabelFragmentDoc}`;
export const PickingListSummaryFragmentDoc = `
    fragment PickingListSummary on PickingList {
  contactEmail
  fulfillmentEmail
  pickupType
  useBemakersSku
  order {
    id
    deliveryMethod
    number
    status
    shippingCarrier
    shippingTrackingUrl
    internalNote
    shippingAddress {
      ...CommonMailingAddressSummary
    }
    deliveryAddress {
      ...CommonMailingAddressSummary
    }
    customer {
      name
      country
      kind
    }
    orderItems {
      ...PickingListOrderItemSummary
    }
    stockOptions {
      batchCodeMode
      batchCodeOptions
      productId
      tracked
    }
    warehouse {
      displayName
      id
      kind
      country
    }
    orderLabels {
      displayKind
      kind
      productId
      productName
    }
  }
}
    ${CommonMailingAddressSummaryFragmentDoc}
${PickingListOrderItemSummaryFragmentDoc}`;
export const PricingCalculationItemSummaryFragmentDoc = `
    fragment PricingCalculationItemSummary on PricingCalculationItem {
  style
  kind
  percentage
  text
  priceCustomer {
    ...PriceSummary
  }
  priceMaker {
    ...PriceSummary
  }
}
    ${PriceSummaryFragmentDoc}`;
export const PricingCalculationSummaryFragmentDoc = `
    fragment PricingCalculationSummary on PricingCalculation {
  breakdown {
    ...PricingCalculationItemSummary
  }
  summary {
    ...PricingCalculationItemSummary
  }
}
    ${PricingCalculationItemSummaryFragmentDoc}`;
export const PricingPlanSummaryForPriceCalculatorFragmentDoc = `
    fragment PricingPlanSummaryForPriceCalculator on PricingPlan {
  name
  commissionInt
  key
}
    `;
export const WarehouseSummaryForPriceCalculatorFragmentDoc = `
    fragment WarehouseSummaryForPriceCalculator on Warehouse {
  id
  code
  country
  displayName
}
    `;
export const CalculatePriceSeedsSummaryFragmentDoc = `
    fragment CalculatePriceSeedsSummary on PricingCalculatorSeeds {
  pricingPlans {
    ...PricingPlanSummaryForPriceCalculator
  }
  warehouses {
    ...WarehouseSummaryForPriceCalculator
  }
}
    ${PricingPlanSummaryForPriceCalculatorFragmentDoc}
${WarehouseSummaryForPriceCalculatorFragmentDoc}`;
export const PricingGroupSummaryFragmentDoc = `
    fragment PricingGroupSummary on PricingGroup {
  ...PricingGroupShortSummary
  customers {
    id
    name
    kind
    country
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const BeverageShortSummaryFragmentDoc = `
    fragment BeverageShortSummary on Beverage {
  volume
  alcoholPercent
}
    `;
export const ProductBundleShortSummaryFragmentDoc = `
    fragment ProductBundleShortSummary on ProductBundle {
  grossWeight
  items {
    id
    quantity
  }
}
    `;
export const AllProductsShortSummaryFragmentDoc = `
    fragment AllProductsShortSummary on ProductInterface {
  id
  sku
  name
  kind
  prepacked
  shopCountries
  stocks {
    quantityAvailable
    tracked
    warehouse {
      displayName
    }
  }
  productPrices {
    ...ProductPriceSummary
  }
  ... on Beverage {
    ...BeverageShortSummary
  }
  ... on ProductBundle {
    ...ProductBundleShortSummary
  }
}
    ${ProductPriceSummaryFragmentDoc}
${BeverageShortSummaryFragmentDoc}
${ProductBundleShortSummaryFragmentDoc}`;
export const BeverageUniqFieldsFragmentDoc = `
    fragment BeverageUniqFields on Beverage {
  alcoholPercent
  netWeight
  cnCode
  exciseProductCode
  volume
  packagingCode
  degreePlato
  gtin
  premix
  sugarPerLiter
}
    `;
export const BeverageSummaryFragmentDoc = `
    fragment BeverageSummary on Beverage {
  ...ProductSummary
  ...BeverageUniqFields
}
    ${ProductSummaryFragmentDoc}
${BeverageUniqFieldsFragmentDoc}`;
export const ProductBundleUniqFieldsFragmentDoc = `
    fragment ProductBundleUniqFields on ProductBundle {
  items {
    ...BundleItemBasics
  }
}
    ${BundleItemBasicsFragmentDoc}`;
export const ProductBundleSummaryFragmentDoc = `
    fragment ProductBundleSummary on ProductBundle {
  ...ProductSummary
  ...ProductBundleUniqFields
}
    ${ProductSummaryFragmentDoc}
${ProductBundleUniqFieldsFragmentDoc}`;
export const ExtraUniqFieldsFragmentDoc = `
    fragment ExtraUniqFields on Extra {
  extraKind
  physicalProduct
  cnCode
}
    `;
export const ExtraSummaryFragmentDoc = `
    fragment ExtraSummary on Extra {
  ...ProductSummary
  ...ExtraUniqFields
}
    ${ProductSummaryFragmentDoc}
${ExtraUniqFieldsFragmentDoc}`;
export const ProductDeletionBlockersSummaryFragmentDoc = `
    fragment ProductDeletionBlockersSummary on ProductDeletionItem {
  kind
  title
  subtitle
}
    `;
export const ProductDeletionSummaryFragmentDoc = `
    fragment ProductDeletionSummary on ProductDeletionDetails {
  canBeDeleted
  deletionBlockers {
    ...ProductDeletionBlockersSummary
  }
  relatedItems {
    ...ProductDeletionBlockersSummary
  }
}
    ${ProductDeletionBlockersSummaryFragmentDoc}`;
export const ShippingRateSummaryFragmentDoc = `
    fragment ShippingRateSummary on ShippingRate {
  id
  name
  kind
  status
  marketKind
  price {
    amount
    currency
  }
  conditionPriceMax {
    amount
    currency
  }
  conditionPriceMin {
    amount
    currency
  }
}
    `;
export const ShippingZoneSummaryFragmentDoc = `
    fragment ShippingZoneSummary on ShippingZone {
  id
  carrier
  country
  currency
  shippingRates {
    ...ShippingRateSummary
  }
}
    ${ShippingRateSummaryFragmentDoc}`;
export const ShippingCalculatorOfferSummaryFragmentDoc = `
    fragment ShippingCalculatorOfferSummary on ShippingCalculatorOffer {
  carrierKind
  packages {
    name
    price {
      ...PriceSummary
    }
  }
}
    ${PriceSummaryFragmentDoc}`;
export const StockMovementShortSummaryFragmentDoc = `
    fragment StockMovementShortSummary on StockMovement {
  id
  number
  sentDate
  displayFromWarehouse
  displayToWarehouse
  totalSent
  totalConfirmed
  status
  items {
    product {
      status
    }
  }
}
    `;
export const StockMovementItemSummaryFragmentDoc = `
    fragment StockMovementItemSummary on StockMovementItem {
  id
  batchCode
  expiryDate
  noExpiry
  quantitySent
  quantityConfirmed
  boxWidth
  boxHeight
  boxLength
  productsPerBox
  product {
    id
    sku
    bemakersSku
    name
    status
    images {
      ...ProductImage
    }
  }
}
    ${ProductImageFragmentDoc}`;
export const StockMovementWarehouseSummaryFragmentDoc = `
    fragment StockMovementWarehouseSummary on Warehouse {
  address {
    ...CommonMailingAddressSummary
  }
  code
  dispatchOffice
  displayName
  email
  exciseNumber
  id
  kind
  status
  country
  options {
    lowQuantityAlert
  }
}
    ${CommonMailingAddressSummaryFragmentDoc}`;
export const StockMovementSummaryFragmentDoc = `
    fragment StockMovementSummary on StockMovement {
  id
  number
  sentDate
  displayFromWarehouse
  displayToWarehouse
  arcNumber
  eadDocUrl
  makerNote
  arrivalDate
  totalSent
  totalConfirmed
  status
  carrierName
  trackingId
  useBemakersSku
  numberOfPallets
  items {
    ...StockMovementItemSummary
  }
  fromWarehouse {
    ...StockMovementWarehouseSummary
  }
  toWarehouse {
    ...StockMovementWarehouseSummary
  }
}
    ${StockMovementItemSummaryFragmentDoc}
${StockMovementWarehouseSummaryFragmentDoc}`;
export const UserSummaryFragmentDoc = `
    fragment UserSummary on User {
  id
  name
  email
  status
  lastSeenAt
}
    `;
export const MembershipSummaryFragmentDoc = `
    fragment MembershipSummary on Membership {
  id
  constraintKind
  countryConstraints
  role
  user {
    ...UserSummary
  }
}
    ${UserSummaryFragmentDoc}`;
export const InvitationSummaryFragmentDoc = `
    fragment InvitationSummary on Invitation {
  id
  name
  email
  acceptedAt
  invitedBy {
    name
  }
  status
  sentAt
  constraintKind
  countryConstraints
  role
}
    `;
export const ShopConfigurationSummaryFragmentDoc = `
    fragment ShopConfigurationSummary on Shop {
  status
  seoTitle
  seoDescription
  paymentMethods
  paymentMethodsMode
  validCustomPaymentMethods
  automaticPaymentMethods
}
    `;
export const CollectionShortSummaryFragmentDoc = `
    fragment CollectionShortSummary on Collection {
  id
  position
  name
  salesChannels
  status
  shopBanner {
    name
  }
  items {
    position
  }
}
    `;
export const WebshopSummaryFragmentDoc = `
    fragment WebshopSummary on Shop {
  id
  fullUrl
  country
  shopProductsCount
  ...ShopConfigurationSummary
  collections {
    ...CollectionShortSummary
  }
  store {
    seoDescription
    seoTitle
    cssStyles
  }
}
    ${ShopConfigurationSummaryFragmentDoc}
${CollectionShortSummaryFragmentDoc}`;
export const StoreSummaryFragmentDoc = `
    fragment StoreSummary on Store {
  cssStyles
  facebookPixel
  facebookUrl
  googleAnalytics
  googleTagManager
  klaviyoId
  homepageUrl
  storeHomepageUrl
  instagramUrl
  linkedinUrl
  fullUrl
  name
  seoDescription
  seoTitle
  imageAspectRatio
}
    `;
export const CollectionSummaryFragmentDoc = `
    fragment CollectionSummary on Collection {
  id
  name
  htmlDescription
  jsonDescription
  status
  position
  ogImageUrl
  salesChannels
  shopBanner {
    id
    name
  }
  items {
    position
    createdAt
    shopProduct {
      id
      name
    }
  }
}
    `;
export const PromoBannerSummaryFragmentDoc = `
    fragment PromoBannerSummary on ShopBanner {
  id
  status
  name
  headingText
  subheadingText
  buttonLink
  buttonLabel
  backgroundImageUrl
  backgroundVideoUrl
  aspectRatio
  collections {
    ...CollectionSummary
  }
  overlayBg
}
    ${CollectionSummaryFragmentDoc}`;
export const ExtendedCollectionSummaryFragmentDoc = `
    fragment ExtendedCollectionSummary on Collection {
  id
  name
  htmlDescription
  jsonDescription
  status
  position
  ogImageUrl
  ogDescription
  ogTitle
  seoDescription
  seoTitle
  slug
  fullUrl
  defaultTitle
  defaultDescription
  salesChannels
  items {
    position
    createdAt
    shopProduct {
      id
      name
      product {
        sku
        productPrices {
          price {
            ...PriceSummary
          }
          market {
            id
            country
            kind
          }
        }
      }
      status
    }
  }
  shopBanner {
    ...PromoBannerSummary
  }
}
    ${PriceSummaryFragmentDoc}
${PromoBannerSummaryFragmentDoc}`;
export const DiscountCommonSummaryFragmentDoc = `
    fragment DiscountCommonSummary on DiscountInterface {
  activeFrom
  activeTo
  code
  conditionOnQuantity
  conditionPrice {
    ...PriceSummary
  }
  conditionType
  createdAt
  displayStatus
  id
  kind
  limitPerUser
  limitTotal
  status
  salesChannels
}
    ${PriceSummaryFragmentDoc}`;
export const DiscountPercentageSummaryFragmentDoc = `
    fragment DiscountPercentageSummary on DiscountPercentage {
  ...DiscountCommonSummary
  amountPercentage
}
    ${DiscountCommonSummaryFragmentDoc}`;
export const DiscountPercentageShortSummaryFragmentDoc = `
    fragment DiscountPercentageShortSummary on DiscountPercentage {
  id
  code
  salesChannels
  displayStatus
  amountPercentage
}
    `;
export const DiscountAmountSummaryFragmentDoc = `
    fragment DiscountAmountSummary on DiscountAmount {
  ...DiscountCommonSummary
  amountValue {
    ...PriceSummary
  }
}
    ${DiscountCommonSummaryFragmentDoc}
${PriceSummaryFragmentDoc}`;
export const WebshopProductStockFragmentDoc = `
    fragment WebshopProductStock on Stock {
  id
  quantityAvailable
  tracked
  warehouse {
    displayName
  }
}
    `;
export const WebshopProductSummaryFragmentDoc = `
    fragment WebshopProductSummary on ShopProduct {
  id
  name
  htmlDescription
  jsonDescription
  status
  defaultOgImageUrl
  ogDescription
  ogTitle
  salesChannels
  seoDescription
  seoTitle
  slug
  fullUrl
  defaultTitle
  defaultDescription
  collections {
    ...CollectionSummary
  }
  stocks {
    ...WebshopProductStock
  }
  product {
    ...ProductSummary
    ... on Beverage {
      ...BeverageUniqFields
    }
    ... on ProductBundle {
      ...ProductBundleUniqFields
    }
    ... on Extra {
      ...ExtraUniqFields
    }
  }
}
    ${CollectionSummaryFragmentDoc}
${WebshopProductStockFragmentDoc}
${ProductSummaryFragmentDoc}
${BeverageUniqFieldsFragmentDoc}
${ProductBundleUniqFieldsFragmentDoc}
${ExtraUniqFieldsFragmentDoc}`;
export const WebshopProductSmallFragmentDoc = `
    fragment WebshopProductSmall on ShopProduct {
  id
  name
  status
  stocks {
    ...WebshopProductStock
  }
  product {
    id
    sku
    kind
    productPrices {
      ...ProductPriceSummary
    }
  }
}
    ${WebshopProductStockFragmentDoc}
${ProductPriceSummaryFragmentDoc}`;
export const UpdateProductPricesDocument = `
    mutation updateProductPrices($id: ID!, $input: UpdateProductPricesInput!) {
  updateProductPrices(productId: $id, input: $input) {
    product {
      ...ProductSummary
    }
  }
}
    ${ProductSummaryFragmentDoc}`;
export const SearchCustomerByNameDocument = `
    query searchCustomerByName($makerId: ID!, $name: String!) {
  customersByName(makerId: $makerId, name: $name) {
    ...CustomerShortSummary
  }
}
    ${CustomerShortSummaryFragmentDoc}`;
export const GetCustomerTagsDocument = `
    query getCustomerTags($makerId: ID!) {
  customerTags(makerId: $makerId)
}
    `;
export const SearchCustomersWithoutPricingGroupDocument = `
    query searchCustomersWithoutPricingGroup($makerId: ID!, $name: String!) {
  customersWithoutPricingGroup(makerId: $makerId, name: $name) {
    ...CustomerShortSummary
  }
}
    ${CustomerShortSummaryFragmentDoc}`;
export const PaginatedAllBeveragesForSearchDocument = `
    query paginatedAllBeveragesForSearch($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: BeverageSorting, $filterBy: BeverageFilter) {
  allBeverages(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...BeverageSummary
    }
  }
}
    ${BeverageSummaryFragmentDoc}`;
export const PaginatedAllProductBundlesForSearchDocument = `
    query paginatedAllProductBundlesForSearch($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ProductBundleSorting, $filterBy: ProductBundleFilter) {
  allProductBundles(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...ProductBundleSummary
    }
  }
}
    ${ProductBundleSummaryFragmentDoc}`;
export const PaginatedAllProductExtrasForSearchDocument = `
    query paginatedAllProductExtrasForSearch($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ExtraSorting, $filterBy: ExtraFilter) {
  allExtras(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...ExtraSummary
    }
  }
}
    ${ExtraSummaryFragmentDoc}`;
export const SearchProductsByNameDocument = `
    query searchProductsByName($makerId: ID!, $filterBy: ProductFilter) {
  findProducts(makerId: $makerId, filterBy: $filterBy) {
    ...ProductSummary
    ... on Beverage {
      ...BeverageUniqFields
    }
    ... on ProductBundle {
      ...ProductBundleUniqFields
    }
    ... on Extra {
      ...ExtraUniqFields
    }
  }
}
    ${ProductSummaryFragmentDoc}
${BeverageUniqFieldsFragmentDoc}
${ProductBundleUniqFieldsFragmentDoc}
${ExtraUniqFieldsFragmentDoc}`;
export const SearchShopProductsForCollectionDocument = `
    query searchShopProductsForCollection($shopId: ID!, $name: String!) {
  selectForCollection(shopId: $shopId, name: $name) {
    ...WebshopProductSmall
  }
}
    ${WebshopProductSmallFragmentDoc}`;
export const SearchShopProductsDocument = `
    query searchShopProducts($shopId: ID!, $name: String!) {
  beverages: selectForShop(shopId: $shopId, name: $name) {
    ... on Beverage {
      ...ProductSummary
      ...BeverageUniqFields
    }
  }
  productBundles: selectForShop(shopId: $shopId, name: $name) {
    ... on ProductBundle {
      ...ProductSummary
      ...ProductBundleUniqFields
    }
  }
  extras: selectForShop(shopId: $shopId, name: $name) {
    ... on Extra {
      ...ProductSummary
      ...ExtraUniqFields
    }
  }
}
    ${ProductSummaryFragmentDoc}
${BeverageUniqFieldsFragmentDoc}
${ProductBundleUniqFieldsFragmentDoc}
${ExtraUniqFieldsFragmentDoc}`;
export const CreateBannerDocument = `
    mutation createBanner($shopId: ID!, $input: ShopBannerInput!) {
  shopBannerCreate(shopId: $shopId, input: $input) {
    shopBanner {
      __typename
    }
  }
}
    `;
export const UpdateBannerDocument = `
    mutation updateBanner($shopBannerId: ID!, $input: ShopBannerInput!) {
  shopBannerUpdate(shopBannerId: $shopBannerId, input: $input) {
    shopBanner {
      __typename
    }
  }
}
    `;
export const DeleteBannerDocument = `
    mutation deleteBanner($shopBannerId: ID!) {
  shopBannerDelete(shopBannerId: $shopBannerId) {
    shopBanner {
      __typename
    }
  }
}
    `;
export const CreateAnnouncementBarDocument = `
    mutation createAnnouncementBar($shopId: ID!, $input: AnnouncementInput!) {
  announcementCreate(shopId: $shopId, input: $input) {
    announcement {
      __typename
    }
  }
}
    `;
export const UpdateAnnouncementBarDocument = `
    mutation updateAnnouncementBar($announcementId: ID!, $input: AnnouncementInput!) {
  announcementUpdate(announcementId: $announcementId, input: $input) {
    announcement {
      __typename
    }
  }
}
    `;
export const DeleteAnnouncementBarDocument = `
    mutation deleteAnnouncementBar($announcementId: ID!) {
  announcementDelete(announcementId: $announcementId) {
    announcement {
      __typename
    }
  }
}
    `;
export const CreateBrandDocument = `
    mutation createBrand($makerId: ID!, $input: BrandInput!) {
  brandCreate(makerId: $makerId, input: $input) {
    brand {
      __typename
    }
  }
}
    `;
export const UpdateBrandDocument = `
    mutation updateBrand($brandId: ID!, $input: BrandInput!) {
  brandUpdate(brandId: $brandId, input: $input) {
    brand {
      __typename
    }
  }
}
    `;
export const CreateBusinessMemberInvitationDocument = `
    mutation createBusinessMemberInvitation($customerId: ID!, $input: BusinessMemberInput!) {
  businessMemberCreate(customerId: $customerId, input: $input) {
    __typename
  }
}
    `;
export const UpdateBusinessMemberInfoDocument = `
    mutation updateBusinessMemberInfo($businessMemberId: ID!, $input: BusinessMemberInput!) {
  businessMemberUpdate(businessMemberId: $businessMemberId, input: $input) {
    __typename
  }
}
    `;
export const ResendBusinessMemberInvitationDocument = `
    mutation resendBusinessMemberInvitation($businessMemberId: ID!) {
  businessMemberResend(businessMemberId: $businessMemberId) {
    __typename
  }
}
    `;
export const DeleteBusinessMemberInvitationDocument = `
    mutation deleteBusinessMemberInvitation($businessMemberId: ID!) {
  businessMemberDelete(businessMemberId: $businessMemberId) {
    __typename
  }
}
    `;
export const CreateCustomerDocument = `
    mutation createCustomer($makerId: ID!, $input: CustomerInput!) {
  customerCreate(makerId: $makerId, input: $input) {
    customer {
      ...CustomerShortSummary
    }
  }
}
    ${CustomerShortSummaryFragmentDoc}`;
export const UpdateCustomerDocument = `
    mutation updateCustomer($customerId: ID!, $input: CustomerInput!) {
  customerUpdate(customerId: $customerId, input: $input) {
    customer {
      __typename
    }
  }
}
    `;
export const CreateLocationDocument = `
    mutation createLocation($makerId: ID!, $input: LocationInput!) {
  locationCreate(makerId: $makerId, input: $input) {
    location {
      __typename
    }
  }
}
    `;
export const UpdateLocationDocument = `
    mutation updateLocation($locationId: ID!, $input: LocationInput!) {
  locationUpdate(locationId: $locationId, input: $input) {
    location {
      __typename
    }
  }
}
    `;
export const CreateCompanyDocument = `
    mutation createCompany($input: MakerInput!) {
  makerCreate(input: $input) {
    maker {
      __typename
    }
  }
}
    `;
export const UpdateCompanyDocument = `
    mutation updateCompany($makerId: ID!, $input: MakerInput!) {
  makerUpdate(id: $makerId, input: $input) {
    maker {
      __typename
    }
  }
}
    `;
export const CreateBankAccountDocument = `
    mutation createBankAccount($makerId: ID!, $input: BankAccountInput!) {
  bankAccountCreate(makerId: $makerId, input: $input) {
    bankAccount {
      __typename
    }
  }
}
    `;
export const UpdateBankAccountDocument = `
    mutation updateBankAccount($bankAccountId: ID!, $input: BankAccountInput!) {
  bankAccountUpdate(bankAccountId: $bankAccountId, input: $input) {
    bankAccount {
      __typename
    }
  }
}
    `;
export const MakerAcceptTermsDocument = `
    mutation makerAcceptTerms($makerId: ID!, $input: AcceptTermsInput!) {
  makerAcceptTerms(id: $makerId, input: $input) {
    maker {
      __typename
    }
  }
}
    `;
export const MakerUpdateTermsDocument = `
    mutation makerUpdateTerms($makerId: ID!, $input: TermsInput!) {
  makerUpdateTerms(id: $makerId, input: $input) {
    maker {
      __typename
    }
  }
}
    `;
export const ChargeMethodSetupDocument = `
    mutation chargeMethodSetup($makerId: ID!, $input: ChargeMethodSetupInput!) {
  chargeMethodSetup(makerId: $makerId, input: $input) {
    chargeMethodSetup {
      setupUrl
    }
  }
}
    `;
export const OnboardingCompleteDocument = `
    mutation onboardingComplete($makerId: ID!) {
  signingComplete(makerId: $makerId) {
    maker {
      __typename
    }
  }
}
    `;
export const CreateOrderDocument = `
    mutation createOrder($makerId: ID!, $input: OrderInput!) {
  orderCreate(makerId: $makerId, input: $input) {
    order {
      id
    }
  }
}
    `;
export const UpdateOrderDocument = `
    mutation updateOrder($orderId: ID!, $input: OrderInput!) {
  orderUpdate(orderId: $orderId, input: $input) {
    order {
      id
    }
  }
}
    `;
export const SubmitOrderDocument = `
    mutation submitOrder($orderId: ID!) {
  orderSubmit(orderId: $orderId) {
    order {
      __typename
    }
  }
}
    `;
export const DeleteOrderDocument = `
    mutation deleteOrder($orderId: ID!) {
  orderDelete(orderId: $orderId) {
    order {
      __typename
    }
  }
}
    `;
export const MarkOrderAwaitingPickupDocument = `
    mutation markOrderAwaitingPickup($orderId: ID!) {
  orderMarkAwaitingPickup(orderId: $orderId) {
    order {
      __typename
    }
  }
}
    `;
export const MarkOrderDeliveredDocument = `
    mutation markOrderDelivered($orderId: ID!) {
  orderMarkDelivered(orderId: $orderId) {
    order {
      __typename
    }
  }
}
    `;
export const ConfirmPickingListDocument = `
    mutation confirmPickingList($signedId: String, $orderId: ID, $trackingUrl: String, $orderItems: [PickingListItemInput!]!) {
  pickingListConfirm(
    signedId: $signedId
    orderId: $orderId
    trackingUrl: $trackingUrl
    orderItems: $orderItems
  ) {
    pickingList {
      contactEmail
      fulfillmentEmail
      order {
        number
      }
    }
  }
}
    `;
export const CreatePricingGroupDocument = `
    mutation createPricingGroup($makerId: ID!, $input: PricingGroupInput!) {
  pricingGroupCreate(makerId: $makerId, input: $input) {
    pricingGroup {
      ...PricingGroupShortSummary
    }
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const UpdatePricingGroupDocument = `
    mutation updatePricingGroup($pricingGroupId: ID!, $input: PricingGroupInput!) {
  pricingGroupUpdate(pricingGroupId: $pricingGroupId, input: $input) {
    pricingGroup {
      ...PricingGroupShortSummary
    }
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const DeletePricingGroupDocument = `
    mutation deletePricingGroup($pricingGroupId: ID!) {
  pricingGroupDelete(pricingGroupId: $pricingGroupId) {
    pricingGroup {
      id
    }
  }
}
    `;
export const AssignPricingGroupDocument = `
    mutation assignPricingGroup($pricingGroupId: ID!, $customerId: ID!) {
  pricingGroupAssign(pricingGroupId: $pricingGroupId, customerId: $customerId) {
    pricingGroup {
      ...PricingGroupShortSummary
    }
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const CreateBeverageDocument = `
    mutation createBeverage($id: ID!, $input: BeverageInput!) {
  createBeverage(makerId: $id, input: $input) {
    product {
      id
    }
  }
}
    `;
export const UpdateBeverageDocument = `
    mutation updateBeverage($id: ID!, $input: BeverageInput!) {
  updateBeverage(productId: $id, input: $input) {
    product {
      __typename
    }
  }
}
    `;
export const CreateBundleProductDocument = `
    mutation createBundleProduct($id: ID!, $input: ProductBundleInput!) {
  createProductBundle(makerId: $id, input: $input) {
    productBundle {
      id
    }
  }
}
    `;
export const UpdateBundleProductDocument = `
    mutation updateBundleProduct($id: ID!, $input: ProductBundleInput!) {
  updateProductBundle(bundleId: $id, input: $input) {
    productBundle {
      __typename
    }
  }
}
    `;
export const CreateExtraProductDocument = `
    mutation createExtraProduct($id: ID!, $input: ExtraInput!) {
  extraCreate(makerId: $id, input: $input) {
    extra {
      id
    }
  }
}
    `;
export const UpdateExtraProductDocument = `
    mutation updateExtraProduct($id: ID!, $input: ExtraInput!) {
  extraUpdate(id: $id, input: $input) {
    extra {
      __typename
    }
  }
}
    `;
export const DeleteProductDocument = `
    mutation deleteProduct($productId: ID!) {
  deleteProduct(productId: $productId) {
    product {
      __typename
    }
  }
}
    `;
export const UpdateShippingZoneDocument = `
    mutation updateShippingZone($shippingZoneId: ID!, $input: ShippingZoneInput!) {
  shippingZoneUpdate(id: $shippingZoneId, input: $input) {
    shippingZone {
      __typename
    }
  }
}
    `;
export const CreateStockMovementDocument = `
    mutation createStockMovement($makerId: ID!, $input: StockMovementInput!) {
  stockMovementCreate(makerId: $makerId, input: $input) {
    stockMovement {
      id
    }
  }
}
    `;
export const SubmitStockMovementDocument = `
    mutation submitStockMovement($stockMovementId: ID!) {
  stockMovementSubmit(id: $stockMovementId) {
    stockMovement {
      __typename
    }
  }
}
    `;
export const UpdateStockMovementDocument = `
    mutation updateStockMovement($stockMovementId: ID!, $input: StockMovementInput!) {
  stockMovementUpdate(id: $stockMovementId, input: $input) {
    stockMovement {
      __typename
    }
  }
}
    `;
export const ConfirmTracezillaOrderDocument = `
    mutation confirmTracezillaOrder($tracezillaOrderId: String) {
  tracezillaConfirm(tracezillaId: $tracezillaOrderId) {
    tracezillaOrder {
      number
      order {
        id
      }
    }
  }
}
    `;
export const ResendInvitationDocument = `
    mutation resendInvitation($invitationId: ID!) {
  invitationResend(id: $invitationId) {
    __typename
  }
}
    `;
export const CreateInvitationDocument = `
    mutation createInvitation($makerId: ID!, $input: InvitationInput!) {
  invitationCreate(makerId: $makerId, input: $input) {
    __typename
  }
}
    `;
export const UpdateWarehouseDocument = `
    mutation updateWarehouse($warehouseId: ID!, $input: WarehouseInput!) {
  warehouseUpdate(warehouseId: $warehouseId, input: $input) {
    warehouse {
      __typename
    }
  }
}
    `;
export const UpdateStoreDocument = `
    mutation updateStore($makerId: ID!, $input: StoreInput!) {
  storeUpdate(makerId: $makerId, input: $input) {
    store {
      __typename
    }
  }
}
    `;
export const UpdateShopDocument = `
    mutation updateShop($shopId: ID!, $input: ShopInput!) {
  shopUpdate(shopId: $shopId, input: $input) {
    shop {
      __typename
    }
  }
}
    `;
export const ImportShopProductsDocument = `
    mutation importShopProducts($shopId: ID!, $input: ShopCopyProductsInput!) {
  shopCopyProducts(shopId: $shopId, input: $input) {
    data {
      collectionsMigrated
      collectionsSkipped
      collectionsTotal
      productsMigrated
      productsSkipped
      productsTotal
    }
  }
}
    `;
export const CreateCollectionDocument = `
    mutation createCollection($shopId: ID!, $input: CollectionInput!) {
  collectionCreate(shopId: $shopId, input: $input) {
    collection {
      id
    }
  }
}
    `;
export const DeleteCollectionDocument = `
    mutation deleteCollection($collectionId: ID!) {
  collectionDelete(collectionId: $collectionId) {
    collection {
      __typename
    }
  }
}
    `;
export const UpdateCollectionDocument = `
    mutation updateCollection($collectionId: ID!, $input: CollectionInput!) {
  collectionUpdate(collectionId: $collectionId, input: $input) {
    collection {
      __typename
    }
  }
}
    `;
export const ReorderCollectionsDocument = `
    mutation reorderCollections($shopId: ID!, $input: [CollectionReorderInput!]!) {
  collectionReorder(shopId: $shopId, input: $input) {
    collections {
      __typename
    }
  }
}
    `;
export const UpdateCollectionMetaDocument = `
    mutation updateCollectionMeta($collectionId: ID!, $input: CollectionMetaInput!) {
  collectionUpdateMeta(collectionId: $collectionId, input: $input) {
    collection {
      __typename
    }
  }
}
    `;
export const CreateDiscountAmountDocument = `
    mutation createDiscountAmount($shopId: ID!, $input: DiscountAmountInput!) {
  discountAmountCreate(shopId: $shopId, input: $input) {
    discount {
      __typename
    }
  }
}
    `;
export const UpdateDiscountAmountDocument = `
    mutation updateDiscountAmount($discountId: ID!, $input: DiscountAmountInput!) {
  discountAmountUpdate(id: $discountId, input: $input) {
    discount {
      __typename
    }
  }
}
    `;
export const CreateDiscountPercentageDocument = `
    mutation createDiscountPercentage($shopId: ID!, $input: DiscountPercentageInput!) {
  discountPercentageCreate(shopId: $shopId, input: $input) {
    discount {
      __typename
    }
  }
}
    `;
export const UpdateDiscountPercentageDocument = `
    mutation updateDiscountPercentage($discountId: ID!, $input: DiscountPercentageInput!) {
  discountPercentageUpdate(id: $discountId, input: $input) {
    discount {
      __typename
    }
  }
}
    `;
export const DeleteDiscountDocument = `
    mutation deleteDiscount($discountId: ID!) {
  discountDelete(id: $discountId) {
    discount {
      code
    }
  }
}
    `;
export const CreateWebshopProductDocument = `
    mutation createWebshopProduct($shopId: ID!, $input: ShopProductInput!) {
  shopProductCreate(shopId: $shopId, input: $input) {
    shopProduct {
      name
      id
    }
  }
}
    `;
export const DeleteWebshopProductDocument = `
    mutation deleteWebshopProduct($shopProductId: ID!) {
  shopProductDelete(shopProductId: $shopProductId) {
    shopProduct {
      __typename
    }
  }
}
    `;
export const UpdateWebshopProductDocument = `
    mutation updateWebshopProduct($shopProductId: ID!, $input: ShopProductInput!) {
  shopProductUpdate(shopProductId: $shopProductId, input: $input) {
    shopProduct {
      __typename
    }
  }
}
    `;
export const UpdateWebshopProductMetaDocument = `
    mutation updateWebshopProductMeta($shopProductId: ID!, $input: ShopProductMetaInput!) {
  shopProductUpdateMeta(shopProductId: $shopProductId, input: $input) {
    shopProduct {
      __typename
    }
  }
}
    `;
export const PaginatedAllTransactionsDocument = `
    query paginatedAllTransactions($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $filterBy: TransactionFilter) {
  transactions(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    filterBy: $filterBy
  ) {
    nodes {
      ...TransactionsSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${TransactionsSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const GetFinanceOverviewDocument = `
    query getFinanceOverview($makerId: ID!) {
  financeOverview(makerId: $makerId) {
    ...FinanceOverviewSummary
  }
}
    ${FinanceOverviewSummaryFragmentDoc}`;
export const GetShopBannerDocument = `
    query getShopBanner($bannerId: ID!) {
  shopBanner(id: $bannerId) {
    ...PromoBannerSummary
  }
}
    ${PromoBannerSummaryFragmentDoc}`;
export const GetShopBannersDocument = `
    query getShopBanners($shopId: ID!) {
  shopBanners(shopId: $shopId) {
    ...PromoBannerSummary
  }
}
    ${PromoBannerSummaryFragmentDoc}`;
export const GetAnnouncementBarsDocument = `
    query getAnnouncementBars($shopId: ID!) {
  announcements(shopId: $shopId) {
    ...AnnouncementBarSummary
  }
}
    ${AnnouncementBarSummaryFragmentDoc}`;
export const GetAnnouncementBarByIdDocument = `
    query getAnnouncementBarById($announcementId: ID!) {
  announcement(announcementId: $announcementId) {
    ...AnnouncementBarSummary
  }
}
    ${AnnouncementBarSummaryFragmentDoc}`;
export const GetBrandByIdDocument = `
    query getBrandById($brandId: ID!) {
  brand(id: $brandId) {
    ...BrandSummary
  }
}
    ${BrandSummaryFragmentDoc}`;
export const GetAllBrandsDocument = `
    query getAllBrands($makerId: ID!) {
  brands(makerId: $makerId) {
    ...BrandShortSummary
  }
}
    ${BrandShortSummaryFragmentDoc}`;
export const GetCarriersByOrderDocument = `
    query getCarriersByOrder($order: OrderInput!) {
  findCarriers(order: $order) {
    ...CarrierSummary
  }
}
    ${CarrierSummaryFragmentDoc}`;
export const CurrentUserDocument = `
    query currentUser {
  current {
    impersonation
    currentMaker {
      ...CurrentMakerSummary
    }
    currentUser {
      ...CurrentUserSummary
    }
  }
}
    ${CurrentMakerSummaryFragmentDoc}
${CurrentUserSummaryFragmentDoc}`;
export const GetActivePlanDocument = `
    query getActivePlan($makerId: ID!) {
  activePlan(makerId: $makerId) {
    ...PlanSummary
  }
}
    ${PlanSummaryFragmentDoc}`;
export const GetCompanyInfoDocument = `
    query getCompanyInfo($makerId: ID!) {
  maker(id: $makerId) {
    ...CompanySummary
  }
}
    ${CompanySummaryFragmentDoc}`;
export const PaginatedAllCustomersDocument = `
    query paginatedAllCustomers($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: CustomerSorting, $filterBy: CustomerFilter) {
  allCustomers(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...CustomerShortSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${CustomerShortSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const GetCustomerByIdDocument = `
    query getCustomerById($customerId: ID!) {
  customer(id: $customerId) {
    ...CustomerSummary
  }
}
    ${CustomerSummaryFragmentDoc}`;
export const ExportCustomersDocument = `
    query exportCustomers($makerId: ID!, $filterBy: CustomerFilter) {
  exportCustomers(makerId: $makerId, filterBy: $filterBy) {
    ...CustomersMetaDataSummary
  }
}
    ${CustomersMetaDataSummaryFragmentDoc}`;
export const ExportSubscribersDocument = `
    query exportSubscribers($makerId: ID!, $filterBy: CustomerFilter!, $format: ExportFileTypes) {
  exportSubscribers(makerId: $makerId, filterBy: $filterBy, format: $format) {
    ...CustomersMetaDataSummary
  }
}
    ${CustomersMetaDataSummaryFragmentDoc}`;
export const GetBusinessMembersDocument = `
    query getBusinessMembers($customerId: ID!) {
  businessMembers(customerId: $customerId) {
    ...BusinessMemberSummary
  }
}
    ${BusinessMemberSummaryFragmentDoc}`;
export const GetBusinessMemberByIdDocument = `
    query getBusinessMemberById($businessMemberId: ID!) {
  businessMember(id: $businessMemberId) {
    ...BusinessMemberSummary
  }
}
    ${BusinessMemberSummaryFragmentDoc}`;
export const PaginatedAllStatementsDocument = `
    query paginatedAllStatements($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $filterBy: SettlementFilter) {
  settlements(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    filterBy: $filterBy
  ) {
    nodes {
      ...StatementsSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${StatementsSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const GetAllFulfillmentRulesDocument = `
    query getAllFulfillmentRules($makerId: ID!) {
  fulfillmentRules(makerId: $makerId) {
    ...FulfillmentRulesSummary
  }
}
    ${FulfillmentRulesSummaryFragmentDoc}`;
export const GetAllLocationsDocument = `
    query getAllLocations($makerId: ID!) {
  locations(makerId: $makerId) {
    ...LocationSummary
  }
}
    ${LocationSummaryFragmentDoc}`;
export const GetLocationByIdDocument = `
    query getLocationById($locationId: ID!) {
  location(locationId: $locationId) {
    ...LocationSummary
  }
}
    ${LocationSummaryFragmentDoc}`;
export const InvoicesMetaDataDocument = `
    query invoicesMetaData($makerId: ID!, $filterBy: OrderInvoiceSearchInput) {
  invoiceSummary(makerId: $makerId, filterBy: $filterBy) {
    ...InvoiceMetaDataSummary
  }
}
    ${InvoiceMetaDataSummaryFragmentDoc}`;
export const PaginatedAllInvoicesDocument = `
    query paginatedAllInvoices($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: OrderInvoiceSorting, $filterBy: OrderInvoiceSearchInput) {
  allOrderInvoices(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...InvoiceSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${InvoiceSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const PaginatedAllCreditNotesDocument = `
    query paginatedAllCreditNotes($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: OrderInvoiceSorting, $filterBy: OrderInvoiceSearchInput) {
  allCreditNotes(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...InvoiceSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${InvoiceSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const PaginatedAllOrdersDocument = `
    query paginatedAllOrders($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: OrderSorting, $filterBy: OrderFilter) {
  allOrders(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...OrderShortSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${OrderShortSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const GetOrderByIdDocument = `
    query getOrderById($orderId: ID!) {
  order(id: $orderId) {
    ...OrderSummary
  }
}
    ${OrderSummaryFragmentDoc}`;
export const GetDocumentsByIdDocument = `
    query getDocumentsById($orderId: ID!) {
  order(id: $orderId) {
    eadDocUrl
    invoiceDocUrl
    sadDocUrl
    shippingDocUrl
    proformaInvoiceDocUrl
    combinedDocsUrl
  }
}
    `;
export const GetOrderTabsMetadataDocument = `
    query getOrderTabsMetadata($makerId: ID!) {
  orderTabsMetadata(makerId: $makerId) {
    ...OrdersMetaDataSummary
  }
}
    ${OrdersMetaDataSummaryFragmentDoc}`;
export const CheckStockForOrderDocument = `
    query checkStockForOrder($order: OrderWithIdInput!, $orderItem: OrderItemInput!) {
  checkStockForOrder(order: $order, orderItem: $orderItem)
}
    `;
export const GetPickingListDocument = `
    query getPickingList($signedId: String, $orderId: ID) {
  pickingList(signedId: $signedId, orderId: $orderId) {
    ...PickingListSummary
  }
}
    ${PickingListSummaryFragmentDoc}`;
export const CalculatePriceDocument = `
    query calculatePrice($calculatorInput: PricingCalculatorInput!) {
  calculatePrice(calculatorInput: $calculatorInput) {
    ...PricingCalculationSummary
  }
}
    ${PricingCalculationSummaryFragmentDoc}`;
export const CalculatePriceSeedsDocument = `
    query calculatePriceSeeds($makerId: ID!) {
  calculatePriceSeeds(makerId: $makerId) {
    ...CalculatePriceSeedsSummary
  }
}
    ${CalculatePriceSeedsSummaryFragmentDoc}`;
export const GetAllPricingGroupsDocument = `
    query getAllPricingGroups($makerId: ID!) {
  pricingGroups(makerId: $makerId) {
    ...PricingGroupShortSummary
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const GetPreviewPricingGroupByIdDocument = `
    query getPreviewPricingGroupById($id: ID!) {
  pricingGroup(id: $id) {
    ...PricingGroupShortSummary
  }
}
    ${PricingGroupShortSummaryFragmentDoc}`;
export const GetPricingGroupByIdDocument = `
    query getPricingGroupById($id: ID!) {
  pricingGroup(id: $id) {
    ...PricingGroupSummary
  }
}
    ${PricingGroupSummaryFragmentDoc}`;
export const GetSuggestedProductPriceDocument = `
    query getSuggestedProductPrice($productId: ID!, $customerId: ID!, $country: String!) {
  suggestedProductPrice(
    productId: $productId
    customerId: $customerId
    country: $country
  ) {
    originalPrice {
      ...PriceSummary
    }
    price {
      ...PriceSummary
    }
  }
}
    ${PriceSummaryFragmentDoc}`;
export const GetDigitalProductsDocument = `
    query getDigitalProducts($makerId: ID!, $name: String!) {
  findProducts(
    makerId: $makerId
    filterBy: {search: $name, physicalProduct: false}
  ) {
    ...ProductSummary
  }
}
    ${ProductSummaryFragmentDoc}`;
export const PaginatedAllProductsDocument = `
    query paginatedAllProducts($makerId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ProductSorting, $filterBy: ProductFilter) {
  allProducts(
    makerId: $makerId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...AllProductsShortSummary
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${AllProductsShortSummaryFragmentDoc}
${PageInfoSummaryFragmentDoc}`;
export const GetBeverageByIdDocument = `
    query getBeverageById($productId: ID!) {
  beverage(id: $productId) {
    ...BeverageSummary
  }
}
    ${BeverageSummaryFragmentDoc}`;
export const GetProductExtraByIdDocument = `
    query getProductExtraById($productId: ID!) {
  extra(id: $productId) {
    ...ExtraSummary
  }
}
    ${ExtraSummaryFragmentDoc}`;
export const GetProductBundleByIdDocument = `
    query getProductBundleById($productId: ID!) {
  productBundle(id: $productId) {
    ...ProductBundleSummary
  }
}
    ${ProductBundleSummaryFragmentDoc}`;
export const GetProductDeletionDetailsDocument = `
    query getProductDeletionDetails($productId: ID!) {
  productDeletionDetails(productId: $productId) {
    ...ProductDeletionSummary
  }
}
    ${ProductDeletionSummaryFragmentDoc}`;
export const GetProductSeedsDocument = `
    query getProductSeeds($makerId: ID!) {
  productSeeds(makerId: $makerId) {
    allergens {
      ...ProductAllergen
    }
    brands {
      ...BrandSummary
    }
  }
}
    ${ProductAllergenFragmentDoc}
${BrandSummaryFragmentDoc}`;
export const CreateReportDocument = `
    query createReport($makerId: ID!, $reportKind: ReportKind!, $filterBy: ReportFilter) {
  generateReport(makerId: $makerId, kind: $reportKind, filterBy: $filterBy) {
    exportUrl
  }
}
    `;
export const GetNearestServicePointsDocument = `
    query getNearestServicePoints($address: FindServicePointInput!, $shippingCarrier: CarrierKind!) {
  findServicePoints(address: $address, shippingCarrier: $shippingCarrier) {
    ...ServicePointSummary
  }
}
    ${ServicePointSummaryFragmentDoc}`;
export const AllShippingZonesDocument = `
    query allShippingZones($makerId: ID!) {
  shippingZones(makerId: $makerId) {
    ...ShippingZoneSummary
  }
}
    ${ShippingZoneSummaryFragmentDoc}`;
export const CalculateShippingPriceDocument = `
    query calculateShippingPrice($makerId: ID!, $input: ShippingCalculatorInput!) {
  calculateShipping(makerId: $makerId, calculator: $input) {
    ...ShippingCalculatorOfferSummary
  }
}
    ${ShippingCalculatorOfferSummaryFragmentDoc}`;
export const GetAllStockMovementsDocument = `
    query getAllStockMovements($makerId: ID!) {
  stockMovements(makerId: $makerId) {
    ...StockMovementShortSummary
  }
}
    ${StockMovementShortSummaryFragmentDoc}`;
export const GetStockMovementByIdDocument = `
    query getStockMovementById($stockMovementId: ID!) {
  stockMovement(id: $stockMovementId) {
    ...StockMovementSummary
  }
}
    ${StockMovementSummaryFragmentDoc}`;
export const GetIntegrationCredentialsInfoDocument = `
    query getIntegrationCredentialsInfo {
  current {
    currentMaker {
      integrations
    }
  }
}
    `;
export const GetTracezillaOrderDocument = `
    query getTracezillaOrder($tracezillaOrderId: ID!) {
  tracezillaOrder(tracezillaId: $tracezillaOrderId) {
    number
    order {
      id
      number
      combinedDocsUrl
      status
    }
  }
}
    `;
export const GetMembershipListDocument = `
    query getMembershipList($makerId: ID!) {
  memberships(makerId: $makerId) {
    ...MembershipSummary
  }
}
    ${MembershipSummaryFragmentDoc}`;
export const GetInvitationListDocument = `
    query getInvitationList($makerId: ID!) {
  invitations(makerId: $makerId) {
    ...InvitationSummary
  }
}
    ${InvitationSummaryFragmentDoc}`;
export const GetUserListDocument = `
    query getUserList($makerId: ID!) {
  users(makerId: $makerId) {
    ...UserSummary
  }
}
    ${UserSummaryFragmentDoc}`;
export const GetInvitationByIdDocument = `
    query getInvitationById($invitationId: ID!) {
  invitation(id: $invitationId) {
    ...InvitationSummary
  }
}
    ${InvitationSummaryFragmentDoc}`;
export const GetAllWarehousesDocument = `
    query getAllWarehouses($makerId: ID!) {
  warehouses(makerId: $makerId) {
    ...WarehouseSummary
  }
}
    ${WarehouseSummaryFragmentDoc}`;
export const GetWarehouseByOrderDocument = `
    query getWarehouseByOrder($order: OrderInput!) {
  findWarehouse(order: $order) {
    ...WarehouseSummary
  }
}
    ${WarehouseSummaryFragmentDoc}`;
export const AllShopsDocument = `
    query allShops($makerId: ID!) {
  shops(makerId: $makerId) {
    ...WebshopSummary
  }
}
    ${WebshopSummaryFragmentDoc}`;
export const GetStoreDocument = `
    query getStore($makerId: ID!) {
  store(makerId: $makerId) {
    ...StoreSummary
  }
}
    ${StoreSummaryFragmentDoc}`;
export const GetAllCollectionsDocument = `
    query getAllCollections($shopId: ID!) {
  collections(shopId: $shopId) {
    ...CollectionShortSummary
  }
}
    ${CollectionShortSummaryFragmentDoc}`;
export const GetCollectionByIdDocument = `
    query getCollectionById($collectionId: ID!) {
  collection(collectionId: $collectionId) {
    ...CollectionSummary
  }
}
    ${CollectionSummaryFragmentDoc}`;
export const GetExtendedCollectionByIdDocument = `
    query getExtendedCollectionById($collectionId: ID!) {
  collection(collectionId: $collectionId) {
    ...ExtendedCollectionSummary
  }
}
    ${ExtendedCollectionSummaryFragmentDoc}`;
export const GetDiscountPercentageByIdDocument = `
    query getDiscountPercentageById($discountId: ID!) {
  discount(id: $discountId) {
    ...DiscountPercentageSummary
  }
}
    ${DiscountPercentageSummaryFragmentDoc}`;
export const GetAllShopDiscountsDocument = `
    query getAllShopDiscounts($shopId: ID!) {
  discounts(shopId: $shopId) {
    ...DiscountPercentageShortSummary
  }
}
    ${DiscountPercentageShortSummaryFragmentDoc}`;
export const GetWebshopProductByIdDocument = `
    query getWebshopProductById($shopProductId: ID!) {
  shopProduct(id: $shopProductId) {
    ...WebshopProductSummary
  }
}
    ${WebshopProductSummaryFragmentDoc}`;
export const PaginatedAllWebshopProductsDocument = `
    query paginatedAllWebshopProducts($shopId: ID!, $first: Int, $last: Int, $before: String, $after: String, $sortBy: ShopProductSorting, $filterBy: ShopProductSearchInput) {
  allShopProducts(
    shopId: $shopId
    first: $first
    last: $last
    before: $before
    after: $after
    sortBy: $sortBy
    filterBy: $filterBy
  ) {
    nodes {
      ...WebshopProductSmall
    }
    pageInfo {
      ...PageInfoSummary
    }
  }
}
    ${WebshopProductSmallFragmentDoc}
${PageInfoSummaryFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateProductPrices: build.mutation<UpdateProductPricesMutation, UpdateProductPricesMutationVariables>({
      query: (variables) => ({document: UpdateProductPricesDocument, variables}),
    }),
    searchCustomerByName: build.query<SearchCustomerByNameQuery, SearchCustomerByNameQueryVariables>({
      query: (variables) => ({document: SearchCustomerByNameDocument, variables}),
    }),
    getCustomerTags: build.query<GetCustomerTagsQuery, GetCustomerTagsQueryVariables>({
      query: (variables) => ({document: GetCustomerTagsDocument, variables}),
    }),
    searchCustomersWithoutPricingGroup: build.query<
      SearchCustomersWithoutPricingGroupQuery,
      SearchCustomersWithoutPricingGroupQueryVariables
    >({
      query: (variables) => ({document: SearchCustomersWithoutPricingGroupDocument, variables}),
    }),
    paginatedAllBeveragesForSearch: build.query<
      PaginatedAllBeveragesForSearchQuery,
      PaginatedAllBeveragesForSearchQueryVariables
    >({
      query: (variables) => ({document: PaginatedAllBeveragesForSearchDocument, variables}),
    }),
    paginatedAllProductBundlesForSearch: build.query<
      PaginatedAllProductBundlesForSearchQuery,
      PaginatedAllProductBundlesForSearchQueryVariables
    >({
      query: (variables) => ({document: PaginatedAllProductBundlesForSearchDocument, variables}),
    }),
    paginatedAllProductExtrasForSearch: build.query<
      PaginatedAllProductExtrasForSearchQuery,
      PaginatedAllProductExtrasForSearchQueryVariables
    >({
      query: (variables) => ({document: PaginatedAllProductExtrasForSearchDocument, variables}),
    }),
    searchProductsByName: build.query<SearchProductsByNameQuery, SearchProductsByNameQueryVariables>({
      query: (variables) => ({document: SearchProductsByNameDocument, variables}),
    }),
    searchShopProductsForCollection: build.query<
      SearchShopProductsForCollectionQuery,
      SearchShopProductsForCollectionQueryVariables
    >({
      query: (variables) => ({document: SearchShopProductsForCollectionDocument, variables}),
    }),
    searchShopProducts: build.query<SearchShopProductsQuery, SearchShopProductsQueryVariables>({
      query: (variables) => ({document: SearchShopProductsDocument, variables}),
    }),
    createBanner: build.mutation<CreateBannerMutation, CreateBannerMutationVariables>({
      query: (variables) => ({document: CreateBannerDocument, variables}),
    }),
    updateBanner: build.mutation<UpdateBannerMutation, UpdateBannerMutationVariables>({
      query: (variables) => ({document: UpdateBannerDocument, variables}),
    }),
    deleteBanner: build.mutation<DeleteBannerMutation, DeleteBannerMutationVariables>({
      query: (variables) => ({document: DeleteBannerDocument, variables}),
    }),
    createAnnouncementBar: build.mutation<CreateAnnouncementBarMutation, CreateAnnouncementBarMutationVariables>({
      query: (variables) => ({document: CreateAnnouncementBarDocument, variables}),
    }),
    updateAnnouncementBar: build.mutation<UpdateAnnouncementBarMutation, UpdateAnnouncementBarMutationVariables>({
      query: (variables) => ({document: UpdateAnnouncementBarDocument, variables}),
    }),
    deleteAnnouncementBar: build.mutation<DeleteAnnouncementBarMutation, DeleteAnnouncementBarMutationVariables>({
      query: (variables) => ({document: DeleteAnnouncementBarDocument, variables}),
    }),
    createBrand: build.mutation<CreateBrandMutation, CreateBrandMutationVariables>({
      query: (variables) => ({document: CreateBrandDocument, variables}),
    }),
    updateBrand: build.mutation<UpdateBrandMutation, UpdateBrandMutationVariables>({
      query: (variables) => ({document: UpdateBrandDocument, variables}),
    }),
    createBusinessMemberInvitation: build.mutation<
      CreateBusinessMemberInvitationMutation,
      CreateBusinessMemberInvitationMutationVariables
    >({
      query: (variables) => ({document: CreateBusinessMemberInvitationDocument, variables}),
    }),
    updateBusinessMemberInfo: build.mutation<
      UpdateBusinessMemberInfoMutation,
      UpdateBusinessMemberInfoMutationVariables
    >({
      query: (variables) => ({document: UpdateBusinessMemberInfoDocument, variables}),
    }),
    resendBusinessMemberInvitation: build.mutation<
      ResendBusinessMemberInvitationMutation,
      ResendBusinessMemberInvitationMutationVariables
    >({
      query: (variables) => ({document: ResendBusinessMemberInvitationDocument, variables}),
    }),
    deleteBusinessMemberInvitation: build.mutation<
      DeleteBusinessMemberInvitationMutation,
      DeleteBusinessMemberInvitationMutationVariables
    >({
      query: (variables) => ({document: DeleteBusinessMemberInvitationDocument, variables}),
    }),
    createCustomer: build.mutation<CreateCustomerMutation, CreateCustomerMutationVariables>({
      query: (variables) => ({document: CreateCustomerDocument, variables}),
    }),
    updateCustomer: build.mutation<UpdateCustomerMutation, UpdateCustomerMutationVariables>({
      query: (variables) => ({document: UpdateCustomerDocument, variables}),
    }),
    createLocation: build.mutation<CreateLocationMutation, CreateLocationMutationVariables>({
      query: (variables) => ({document: CreateLocationDocument, variables}),
    }),
    updateLocation: build.mutation<UpdateLocationMutation, UpdateLocationMutationVariables>({
      query: (variables) => ({document: UpdateLocationDocument, variables}),
    }),
    createCompany: build.mutation<CreateCompanyMutation, CreateCompanyMutationVariables>({
      query: (variables) => ({document: CreateCompanyDocument, variables}),
    }),
    updateCompany: build.mutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>({
      query: (variables) => ({document: UpdateCompanyDocument, variables}),
    }),
    createBankAccount: build.mutation<CreateBankAccountMutation, CreateBankAccountMutationVariables>({
      query: (variables) => ({document: CreateBankAccountDocument, variables}),
    }),
    updateBankAccount: build.mutation<UpdateBankAccountMutation, UpdateBankAccountMutationVariables>({
      query: (variables) => ({document: UpdateBankAccountDocument, variables}),
    }),
    makerAcceptTerms: build.mutation<MakerAcceptTermsMutation, MakerAcceptTermsMutationVariables>({
      query: (variables) => ({document: MakerAcceptTermsDocument, variables}),
    }),
    makerUpdateTerms: build.mutation<MakerUpdateTermsMutation, MakerUpdateTermsMutationVariables>({
      query: (variables) => ({document: MakerUpdateTermsDocument, variables}),
    }),
    chargeMethodSetup: build.mutation<ChargeMethodSetupMutation, ChargeMethodSetupMutationVariables>({
      query: (variables) => ({document: ChargeMethodSetupDocument, variables}),
    }),
    onboardingComplete: build.mutation<OnboardingCompleteMutation, OnboardingCompleteMutationVariables>({
      query: (variables) => ({document: OnboardingCompleteDocument, variables}),
    }),
    createOrder: build.mutation<CreateOrderMutation, CreateOrderMutationVariables>({
      query: (variables) => ({document: CreateOrderDocument, variables}),
    }),
    updateOrder: build.mutation<UpdateOrderMutation, UpdateOrderMutationVariables>({
      query: (variables) => ({document: UpdateOrderDocument, variables}),
    }),
    submitOrder: build.mutation<SubmitOrderMutation, SubmitOrderMutationVariables>({
      query: (variables) => ({document: SubmitOrderDocument, variables}),
    }),
    deleteOrder: build.mutation<DeleteOrderMutation, DeleteOrderMutationVariables>({
      query: (variables) => ({document: DeleteOrderDocument, variables}),
    }),
    markOrderAwaitingPickup: build.mutation<MarkOrderAwaitingPickupMutation, MarkOrderAwaitingPickupMutationVariables>({
      query: (variables) => ({document: MarkOrderAwaitingPickupDocument, variables}),
    }),
    markOrderDelivered: build.mutation<MarkOrderDeliveredMutation, MarkOrderDeliveredMutationVariables>({
      query: (variables) => ({document: MarkOrderDeliveredDocument, variables}),
    }),
    confirmPickingList: build.mutation<ConfirmPickingListMutation, ConfirmPickingListMutationVariables>({
      query: (variables) => ({document: ConfirmPickingListDocument, variables}),
    }),
    createPricingGroup: build.mutation<CreatePricingGroupMutation, CreatePricingGroupMutationVariables>({
      query: (variables) => ({document: CreatePricingGroupDocument, variables}),
    }),
    updatePricingGroup: build.mutation<UpdatePricingGroupMutation, UpdatePricingGroupMutationVariables>({
      query: (variables) => ({document: UpdatePricingGroupDocument, variables}),
    }),
    deletePricingGroup: build.mutation<DeletePricingGroupMutation, DeletePricingGroupMutationVariables>({
      query: (variables) => ({document: DeletePricingGroupDocument, variables}),
    }),
    assignPricingGroup: build.mutation<AssignPricingGroupMutation, AssignPricingGroupMutationVariables>({
      query: (variables) => ({document: AssignPricingGroupDocument, variables}),
    }),
    createBeverage: build.mutation<CreateBeverageMutation, CreateBeverageMutationVariables>({
      query: (variables) => ({document: CreateBeverageDocument, variables}),
    }),
    updateBeverage: build.mutation<UpdateBeverageMutation, UpdateBeverageMutationVariables>({
      query: (variables) => ({document: UpdateBeverageDocument, variables}),
    }),
    createBundleProduct: build.mutation<CreateBundleProductMutation, CreateBundleProductMutationVariables>({
      query: (variables) => ({document: CreateBundleProductDocument, variables}),
    }),
    updateBundleProduct: build.mutation<UpdateBundleProductMutation, UpdateBundleProductMutationVariables>({
      query: (variables) => ({document: UpdateBundleProductDocument, variables}),
    }),
    createExtraProduct: build.mutation<CreateExtraProductMutation, CreateExtraProductMutationVariables>({
      query: (variables) => ({document: CreateExtraProductDocument, variables}),
    }),
    updateExtraProduct: build.mutation<UpdateExtraProductMutation, UpdateExtraProductMutationVariables>({
      query: (variables) => ({document: UpdateExtraProductDocument, variables}),
    }),
    deleteProduct: build.mutation<DeleteProductMutation, DeleteProductMutationVariables>({
      query: (variables) => ({document: DeleteProductDocument, variables}),
    }),
    updateShippingZone: build.mutation<UpdateShippingZoneMutation, UpdateShippingZoneMutationVariables>({
      query: (variables) => ({document: UpdateShippingZoneDocument, variables}),
    }),
    createStockMovement: build.mutation<CreateStockMovementMutation, CreateStockMovementMutationVariables>({
      query: (variables) => ({document: CreateStockMovementDocument, variables}),
    }),
    submitStockMovement: build.mutation<SubmitStockMovementMutation, SubmitStockMovementMutationVariables>({
      query: (variables) => ({document: SubmitStockMovementDocument, variables}),
    }),
    updateStockMovement: build.mutation<UpdateStockMovementMutation, UpdateStockMovementMutationVariables>({
      query: (variables) => ({document: UpdateStockMovementDocument, variables}),
    }),
    confirmTracezillaOrder: build.mutation<
      ConfirmTracezillaOrderMutation,
      ConfirmTracezillaOrderMutationVariables | void
    >({
      query: (variables) => ({document: ConfirmTracezillaOrderDocument, variables}),
    }),
    resendInvitation: build.mutation<ResendInvitationMutation, ResendInvitationMutationVariables>({
      query: (variables) => ({document: ResendInvitationDocument, variables}),
    }),
    createInvitation: build.mutation<CreateInvitationMutation, CreateInvitationMutationVariables>({
      query: (variables) => ({document: CreateInvitationDocument, variables}),
    }),
    updateWarehouse: build.mutation<UpdateWarehouseMutation, UpdateWarehouseMutationVariables>({
      query: (variables) => ({document: UpdateWarehouseDocument, variables}),
    }),
    updateStore: build.mutation<UpdateStoreMutation, UpdateStoreMutationVariables>({
      query: (variables) => ({document: UpdateStoreDocument, variables}),
    }),
    updateShop: build.mutation<UpdateShopMutation, UpdateShopMutationVariables>({
      query: (variables) => ({document: UpdateShopDocument, variables}),
    }),
    importShopProducts: build.mutation<ImportShopProductsMutation, ImportShopProductsMutationVariables>({
      query: (variables) => ({document: ImportShopProductsDocument, variables}),
    }),
    createCollection: build.mutation<CreateCollectionMutation, CreateCollectionMutationVariables>({
      query: (variables) => ({document: CreateCollectionDocument, variables}),
    }),
    deleteCollection: build.mutation<DeleteCollectionMutation, DeleteCollectionMutationVariables>({
      query: (variables) => ({document: DeleteCollectionDocument, variables}),
    }),
    updateCollection: build.mutation<UpdateCollectionMutation, UpdateCollectionMutationVariables>({
      query: (variables) => ({document: UpdateCollectionDocument, variables}),
    }),
    reorderCollections: build.mutation<ReorderCollectionsMutation, ReorderCollectionsMutationVariables>({
      query: (variables) => ({document: ReorderCollectionsDocument, variables}),
    }),
    updateCollectionMeta: build.mutation<UpdateCollectionMetaMutation, UpdateCollectionMetaMutationVariables>({
      query: (variables) => ({document: UpdateCollectionMetaDocument, variables}),
    }),
    createDiscountAmount: build.mutation<CreateDiscountAmountMutation, CreateDiscountAmountMutationVariables>({
      query: (variables) => ({document: CreateDiscountAmountDocument, variables}),
    }),
    updateDiscountAmount: build.mutation<UpdateDiscountAmountMutation, UpdateDiscountAmountMutationVariables>({
      query: (variables) => ({document: UpdateDiscountAmountDocument, variables}),
    }),
    createDiscountPercentage: build.mutation<
      CreateDiscountPercentageMutation,
      CreateDiscountPercentageMutationVariables
    >({
      query: (variables) => ({document: CreateDiscountPercentageDocument, variables}),
    }),
    updateDiscountPercentage: build.mutation<
      UpdateDiscountPercentageMutation,
      UpdateDiscountPercentageMutationVariables
    >({
      query: (variables) => ({document: UpdateDiscountPercentageDocument, variables}),
    }),
    deleteDiscount: build.mutation<DeleteDiscountMutation, DeleteDiscountMutationVariables>({
      query: (variables) => ({document: DeleteDiscountDocument, variables}),
    }),
    createWebshopProduct: build.mutation<CreateWebshopProductMutation, CreateWebshopProductMutationVariables>({
      query: (variables) => ({document: CreateWebshopProductDocument, variables}),
    }),
    deleteWebshopProduct: build.mutation<DeleteWebshopProductMutation, DeleteWebshopProductMutationVariables>({
      query: (variables) => ({document: DeleteWebshopProductDocument, variables}),
    }),
    updateWebshopProduct: build.mutation<UpdateWebshopProductMutation, UpdateWebshopProductMutationVariables>({
      query: (variables) => ({document: UpdateWebshopProductDocument, variables}),
    }),
    updateWebshopProductMeta: build.mutation<
      UpdateWebshopProductMetaMutation,
      UpdateWebshopProductMetaMutationVariables
    >({
      query: (variables) => ({document: UpdateWebshopProductMetaDocument, variables}),
    }),
    paginatedAllTransactions: build.query<PaginatedAllTransactionsQuery, PaginatedAllTransactionsQueryVariables>({
      query: (variables) => ({document: PaginatedAllTransactionsDocument, variables}),
    }),
    getFinanceOverview: build.query<GetFinanceOverviewQuery, GetFinanceOverviewQueryVariables>({
      query: (variables) => ({document: GetFinanceOverviewDocument, variables}),
    }),
    getShopBanner: build.query<GetShopBannerQuery, GetShopBannerQueryVariables>({
      query: (variables) => ({document: GetShopBannerDocument, variables}),
    }),
    getShopBanners: build.query<GetShopBannersQuery, GetShopBannersQueryVariables>({
      query: (variables) => ({document: GetShopBannersDocument, variables}),
    }),
    getAnnouncementBars: build.query<GetAnnouncementBarsQuery, GetAnnouncementBarsQueryVariables>({
      query: (variables) => ({document: GetAnnouncementBarsDocument, variables}),
    }),
    getAnnouncementBarById: build.query<GetAnnouncementBarByIdQuery, GetAnnouncementBarByIdQueryVariables>({
      query: (variables) => ({document: GetAnnouncementBarByIdDocument, variables}),
    }),
    getBrandById: build.query<GetBrandByIdQuery, GetBrandByIdQueryVariables>({
      query: (variables) => ({document: GetBrandByIdDocument, variables}),
    }),
    getAllBrands: build.query<GetAllBrandsQuery, GetAllBrandsQueryVariables>({
      query: (variables) => ({document: GetAllBrandsDocument, variables}),
    }),
    getCarriersByOrder: build.query<GetCarriersByOrderQuery, GetCarriersByOrderQueryVariables>({
      query: (variables) => ({document: GetCarriersByOrderDocument, variables}),
    }),
    currentUser: build.query<CurrentUserQuery, CurrentUserQueryVariables | void>({
      query: (variables) => ({document: CurrentUserDocument, variables}),
    }),
    getActivePlan: build.query<GetActivePlanQuery, GetActivePlanQueryVariables>({
      query: (variables) => ({document: GetActivePlanDocument, variables}),
    }),
    getCompanyInfo: build.query<GetCompanyInfoQuery, GetCompanyInfoQueryVariables>({
      query: (variables) => ({document: GetCompanyInfoDocument, variables}),
    }),
    paginatedAllCustomers: build.query<PaginatedAllCustomersQuery, PaginatedAllCustomersQueryVariables>({
      query: (variables) => ({document: PaginatedAllCustomersDocument, variables}),
    }),
    getCustomerById: build.query<GetCustomerByIdQuery, GetCustomerByIdQueryVariables>({
      query: (variables) => ({document: GetCustomerByIdDocument, variables}),
    }),
    exportCustomers: build.query<ExportCustomersQuery, ExportCustomersQueryVariables>({
      query: (variables) => ({document: ExportCustomersDocument, variables}),
    }),
    exportSubscribers: build.query<ExportSubscribersQuery, ExportSubscribersQueryVariables>({
      query: (variables) => ({document: ExportSubscribersDocument, variables}),
    }),
    getBusinessMembers: build.query<GetBusinessMembersQuery, GetBusinessMembersQueryVariables>({
      query: (variables) => ({document: GetBusinessMembersDocument, variables}),
    }),
    getBusinessMemberById: build.query<GetBusinessMemberByIdQuery, GetBusinessMemberByIdQueryVariables>({
      query: (variables) => ({document: GetBusinessMemberByIdDocument, variables}),
    }),
    paginatedAllStatements: build.query<PaginatedAllStatementsQuery, PaginatedAllStatementsQueryVariables>({
      query: (variables) => ({document: PaginatedAllStatementsDocument, variables}),
    }),
    getAllFulfillmentRules: build.query<GetAllFulfillmentRulesQuery, GetAllFulfillmentRulesQueryVariables>({
      query: (variables) => ({document: GetAllFulfillmentRulesDocument, variables}),
    }),
    getAllLocations: build.query<GetAllLocationsQuery, GetAllLocationsQueryVariables>({
      query: (variables) => ({document: GetAllLocationsDocument, variables}),
    }),
    getLocationById: build.query<GetLocationByIdQuery, GetLocationByIdQueryVariables>({
      query: (variables) => ({document: GetLocationByIdDocument, variables}),
    }),
    invoicesMetaData: build.query<InvoicesMetaDataQuery, InvoicesMetaDataQueryVariables>({
      query: (variables) => ({document: InvoicesMetaDataDocument, variables}),
    }),
    paginatedAllInvoices: build.query<PaginatedAllInvoicesQuery, PaginatedAllInvoicesQueryVariables>({
      query: (variables) => ({document: PaginatedAllInvoicesDocument, variables}),
    }),
    paginatedAllCreditNotes: build.query<PaginatedAllCreditNotesQuery, PaginatedAllCreditNotesQueryVariables>({
      query: (variables) => ({document: PaginatedAllCreditNotesDocument, variables}),
    }),
    paginatedAllOrders: build.query<PaginatedAllOrdersQuery, PaginatedAllOrdersQueryVariables>({
      query: (variables) => ({document: PaginatedAllOrdersDocument, variables}),
    }),
    getOrderById: build.query<GetOrderByIdQuery, GetOrderByIdQueryVariables>({
      query: (variables) => ({document: GetOrderByIdDocument, variables}),
    }),
    getDocumentsById: build.query<GetDocumentsByIdQuery, GetDocumentsByIdQueryVariables>({
      query: (variables) => ({document: GetDocumentsByIdDocument, variables}),
    }),
    getOrderTabsMetadata: build.query<GetOrderTabsMetadataQuery, GetOrderTabsMetadataQueryVariables>({
      query: (variables) => ({document: GetOrderTabsMetadataDocument, variables}),
    }),
    checkStockForOrder: build.query<CheckStockForOrderQuery, CheckStockForOrderQueryVariables>({
      query: (variables) => ({document: CheckStockForOrderDocument, variables}),
    }),
    getPickingList: build.query<GetPickingListQuery, GetPickingListQueryVariables | void>({
      query: (variables) => ({document: GetPickingListDocument, variables}),
    }),
    calculatePrice: build.query<CalculatePriceQuery, CalculatePriceQueryVariables>({
      query: (variables) => ({document: CalculatePriceDocument, variables}),
    }),
    calculatePriceSeeds: build.query<CalculatePriceSeedsQuery, CalculatePriceSeedsQueryVariables>({
      query: (variables) => ({document: CalculatePriceSeedsDocument, variables}),
    }),
    getAllPricingGroups: build.query<GetAllPricingGroupsQuery, GetAllPricingGroupsQueryVariables>({
      query: (variables) => ({document: GetAllPricingGroupsDocument, variables}),
    }),
    getPreviewPricingGroupById: build.query<GetPreviewPricingGroupByIdQuery, GetPreviewPricingGroupByIdQueryVariables>({
      query: (variables) => ({document: GetPreviewPricingGroupByIdDocument, variables}),
    }),
    getPricingGroupById: build.query<GetPricingGroupByIdQuery, GetPricingGroupByIdQueryVariables>({
      query: (variables) => ({document: GetPricingGroupByIdDocument, variables}),
    }),
    getSuggestedProductPrice: build.query<GetSuggestedProductPriceQuery, GetSuggestedProductPriceQueryVariables>({
      query: (variables) => ({document: GetSuggestedProductPriceDocument, variables}),
    }),
    getDigitalProducts: build.query<GetDigitalProductsQuery, GetDigitalProductsQueryVariables>({
      query: (variables) => ({document: GetDigitalProductsDocument, variables}),
    }),
    paginatedAllProducts: build.query<PaginatedAllProductsQuery, PaginatedAllProductsQueryVariables>({
      query: (variables) => ({document: PaginatedAllProductsDocument, variables}),
    }),
    getBeverageById: build.query<GetBeverageByIdQuery, GetBeverageByIdQueryVariables>({
      query: (variables) => ({document: GetBeverageByIdDocument, variables}),
    }),
    getProductExtraById: build.query<GetProductExtraByIdQuery, GetProductExtraByIdQueryVariables>({
      query: (variables) => ({document: GetProductExtraByIdDocument, variables}),
    }),
    getProductBundleById: build.query<GetProductBundleByIdQuery, GetProductBundleByIdQueryVariables>({
      query: (variables) => ({document: GetProductBundleByIdDocument, variables}),
    }),
    getProductDeletionDetails: build.query<GetProductDeletionDetailsQuery, GetProductDeletionDetailsQueryVariables>({
      query: (variables) => ({document: GetProductDeletionDetailsDocument, variables}),
    }),
    getProductSeeds: build.query<GetProductSeedsQuery, GetProductSeedsQueryVariables>({
      query: (variables) => ({document: GetProductSeedsDocument, variables}),
    }),
    createReport: build.query<CreateReportQuery, CreateReportQueryVariables>({
      query: (variables) => ({document: CreateReportDocument, variables}),
    }),
    getNearestServicePoints: build.query<GetNearestServicePointsQuery, GetNearestServicePointsQueryVariables>({
      query: (variables) => ({document: GetNearestServicePointsDocument, variables}),
    }),
    allShippingZones: build.query<AllShippingZonesQuery, AllShippingZonesQueryVariables>({
      query: (variables) => ({document: AllShippingZonesDocument, variables}),
    }),
    calculateShippingPrice: build.query<CalculateShippingPriceQuery, CalculateShippingPriceQueryVariables>({
      query: (variables) => ({document: CalculateShippingPriceDocument, variables}),
    }),
    getAllStockMovements: build.query<GetAllStockMovementsQuery, GetAllStockMovementsQueryVariables>({
      query: (variables) => ({document: GetAllStockMovementsDocument, variables}),
    }),
    getStockMovementById: build.query<GetStockMovementByIdQuery, GetStockMovementByIdQueryVariables>({
      query: (variables) => ({document: GetStockMovementByIdDocument, variables}),
    }),
    getIntegrationCredentialsInfo: build.query<
      GetIntegrationCredentialsInfoQuery,
      GetIntegrationCredentialsInfoQueryVariables | void
    >({
      query: (variables) => ({document: GetIntegrationCredentialsInfoDocument, variables}),
    }),
    getTracezillaOrder: build.query<GetTracezillaOrderQuery, GetTracezillaOrderQueryVariables>({
      query: (variables) => ({document: GetTracezillaOrderDocument, variables}),
    }),
    getMembershipList: build.query<GetMembershipListQuery, GetMembershipListQueryVariables>({
      query: (variables) => ({document: GetMembershipListDocument, variables}),
    }),
    getInvitationList: build.query<GetInvitationListQuery, GetInvitationListQueryVariables>({
      query: (variables) => ({document: GetInvitationListDocument, variables}),
    }),
    getUserList: build.query<GetUserListQuery, GetUserListQueryVariables>({
      query: (variables) => ({document: GetUserListDocument, variables}),
    }),
    getInvitationById: build.query<GetInvitationByIdQuery, GetInvitationByIdQueryVariables>({
      query: (variables) => ({document: GetInvitationByIdDocument, variables}),
    }),
    getAllWarehouses: build.query<GetAllWarehousesQuery, GetAllWarehousesQueryVariables>({
      query: (variables) => ({document: GetAllWarehousesDocument, variables}),
    }),
    getWarehouseByOrder: build.query<GetWarehouseByOrderQuery, GetWarehouseByOrderQueryVariables>({
      query: (variables) => ({document: GetWarehouseByOrderDocument, variables}),
    }),
    allShops: build.query<AllShopsQuery, AllShopsQueryVariables>({
      query: (variables) => ({document: AllShopsDocument, variables}),
    }),
    getStore: build.query<GetStoreQuery, GetStoreQueryVariables>({
      query: (variables) => ({document: GetStoreDocument, variables}),
    }),
    getAllCollections: build.query<GetAllCollectionsQuery, GetAllCollectionsQueryVariables>({
      query: (variables) => ({document: GetAllCollectionsDocument, variables}),
    }),
    getCollectionById: build.query<GetCollectionByIdQuery, GetCollectionByIdQueryVariables>({
      query: (variables) => ({document: GetCollectionByIdDocument, variables}),
    }),
    getExtendedCollectionById: build.query<GetExtendedCollectionByIdQuery, GetExtendedCollectionByIdQueryVariables>({
      query: (variables) => ({document: GetExtendedCollectionByIdDocument, variables}),
    }),
    getDiscountPercentageById: build.query<GetDiscountPercentageByIdQuery, GetDiscountPercentageByIdQueryVariables>({
      query: (variables) => ({document: GetDiscountPercentageByIdDocument, variables}),
    }),
    getAllShopDiscounts: build.query<GetAllShopDiscountsQuery, GetAllShopDiscountsQueryVariables>({
      query: (variables) => ({document: GetAllShopDiscountsDocument, variables}),
    }),
    getWebshopProductById: build.query<GetWebshopProductByIdQuery, GetWebshopProductByIdQueryVariables>({
      query: (variables) => ({document: GetWebshopProductByIdDocument, variables}),
    }),
    paginatedAllWebshopProducts: build.query<
      PaginatedAllWebshopProductsQuery,
      PaginatedAllWebshopProductsQueryVariables
    >({
      query: (variables) => ({document: PaginatedAllWebshopProductsDocument, variables}),
    }),
  }),
});

export {injectedRtkApi as api};
export const {
  useUpdateProductPricesMutation,
  useSearchCustomerByNameQuery,
  useLazySearchCustomerByNameQuery,
  useGetCustomerTagsQuery,
  useLazyGetCustomerTagsQuery,
  useSearchCustomersWithoutPricingGroupQuery,
  useLazySearchCustomersWithoutPricingGroupQuery,
  usePaginatedAllBeveragesForSearchQuery,
  useLazyPaginatedAllBeveragesForSearchQuery,
  usePaginatedAllProductBundlesForSearchQuery,
  useLazyPaginatedAllProductBundlesForSearchQuery,
  usePaginatedAllProductExtrasForSearchQuery,
  useLazyPaginatedAllProductExtrasForSearchQuery,
  useSearchProductsByNameQuery,
  useLazySearchProductsByNameQuery,
  useSearchShopProductsForCollectionQuery,
  useLazySearchShopProductsForCollectionQuery,
  useSearchShopProductsQuery,
  useLazySearchShopProductsQuery,
  useCreateBannerMutation,
  useUpdateBannerMutation,
  useDeleteBannerMutation,
  useCreateAnnouncementBarMutation,
  useUpdateAnnouncementBarMutation,
  useDeleteAnnouncementBarMutation,
  useCreateBrandMutation,
  useUpdateBrandMutation,
  useCreateBusinessMemberInvitationMutation,
  useUpdateBusinessMemberInfoMutation,
  useResendBusinessMemberInvitationMutation,
  useDeleteBusinessMemberInvitationMutation,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useCreateLocationMutation,
  useUpdateLocationMutation,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useCreateBankAccountMutation,
  useUpdateBankAccountMutation,
  useMakerAcceptTermsMutation,
  useMakerUpdateTermsMutation,
  useChargeMethodSetupMutation,
  useOnboardingCompleteMutation,
  useCreateOrderMutation,
  useUpdateOrderMutation,
  useSubmitOrderMutation,
  useDeleteOrderMutation,
  useMarkOrderAwaitingPickupMutation,
  useMarkOrderDeliveredMutation,
  useConfirmPickingListMutation,
  useCreatePricingGroupMutation,
  useUpdatePricingGroupMutation,
  useDeletePricingGroupMutation,
  useAssignPricingGroupMutation,
  useCreateBeverageMutation,
  useUpdateBeverageMutation,
  useCreateBundleProductMutation,
  useUpdateBundleProductMutation,
  useCreateExtraProductMutation,
  useUpdateExtraProductMutation,
  useDeleteProductMutation,
  useUpdateShippingZoneMutation,
  useCreateStockMovementMutation,
  useSubmitStockMovementMutation,
  useUpdateStockMovementMutation,
  useConfirmTracezillaOrderMutation,
  useResendInvitationMutation,
  useCreateInvitationMutation,
  useUpdateWarehouseMutation,
  useUpdateStoreMutation,
  useUpdateShopMutation,
  useImportShopProductsMutation,
  useCreateCollectionMutation,
  useDeleteCollectionMutation,
  useUpdateCollectionMutation,
  useReorderCollectionsMutation,
  useUpdateCollectionMetaMutation,
  useCreateDiscountAmountMutation,
  useUpdateDiscountAmountMutation,
  useCreateDiscountPercentageMutation,
  useUpdateDiscountPercentageMutation,
  useDeleteDiscountMutation,
  useCreateWebshopProductMutation,
  useDeleteWebshopProductMutation,
  useUpdateWebshopProductMutation,
  useUpdateWebshopProductMetaMutation,
  usePaginatedAllTransactionsQuery,
  useLazyPaginatedAllTransactionsQuery,
  useGetFinanceOverviewQuery,
  useLazyGetFinanceOverviewQuery,
  useGetShopBannerQuery,
  useLazyGetShopBannerQuery,
  useGetShopBannersQuery,
  useLazyGetShopBannersQuery,
  useGetAnnouncementBarsQuery,
  useLazyGetAnnouncementBarsQuery,
  useGetAnnouncementBarByIdQuery,
  useLazyGetAnnouncementBarByIdQuery,
  useGetBrandByIdQuery,
  useLazyGetBrandByIdQuery,
  useGetAllBrandsQuery,
  useLazyGetAllBrandsQuery,
  useGetCarriersByOrderQuery,
  useLazyGetCarriersByOrderQuery,
  useCurrentUserQuery,
  useLazyCurrentUserQuery,
  useGetActivePlanQuery,
  useLazyGetActivePlanQuery,
  useGetCompanyInfoQuery,
  useLazyGetCompanyInfoQuery,
  usePaginatedAllCustomersQuery,
  useLazyPaginatedAllCustomersQuery,
  useGetCustomerByIdQuery,
  useLazyGetCustomerByIdQuery,
  useExportCustomersQuery,
  useLazyExportCustomersQuery,
  useExportSubscribersQuery,
  useLazyExportSubscribersQuery,
  useGetBusinessMembersQuery,
  useLazyGetBusinessMembersQuery,
  useGetBusinessMemberByIdQuery,
  useLazyGetBusinessMemberByIdQuery,
  usePaginatedAllStatementsQuery,
  useLazyPaginatedAllStatementsQuery,
  useGetAllFulfillmentRulesQuery,
  useLazyGetAllFulfillmentRulesQuery,
  useGetAllLocationsQuery,
  useLazyGetAllLocationsQuery,
  useGetLocationByIdQuery,
  useLazyGetLocationByIdQuery,
  useInvoicesMetaDataQuery,
  useLazyInvoicesMetaDataQuery,
  usePaginatedAllInvoicesQuery,
  useLazyPaginatedAllInvoicesQuery,
  usePaginatedAllCreditNotesQuery,
  useLazyPaginatedAllCreditNotesQuery,
  usePaginatedAllOrdersQuery,
  useLazyPaginatedAllOrdersQuery,
  useGetOrderByIdQuery,
  useLazyGetOrderByIdQuery,
  useGetDocumentsByIdQuery,
  useLazyGetDocumentsByIdQuery,
  useGetOrderTabsMetadataQuery,
  useLazyGetOrderTabsMetadataQuery,
  useCheckStockForOrderQuery,
  useLazyCheckStockForOrderQuery,
  useGetPickingListQuery,
  useLazyGetPickingListQuery,
  useCalculatePriceQuery,
  useLazyCalculatePriceQuery,
  useCalculatePriceSeedsQuery,
  useLazyCalculatePriceSeedsQuery,
  useGetAllPricingGroupsQuery,
  useLazyGetAllPricingGroupsQuery,
  useGetPreviewPricingGroupByIdQuery,
  useLazyGetPreviewPricingGroupByIdQuery,
  useGetPricingGroupByIdQuery,
  useLazyGetPricingGroupByIdQuery,
  useGetSuggestedProductPriceQuery,
  useLazyGetSuggestedProductPriceQuery,
  useGetDigitalProductsQuery,
  useLazyGetDigitalProductsQuery,
  usePaginatedAllProductsQuery,
  useLazyPaginatedAllProductsQuery,
  useGetBeverageByIdQuery,
  useLazyGetBeverageByIdQuery,
  useGetProductExtraByIdQuery,
  useLazyGetProductExtraByIdQuery,
  useGetProductBundleByIdQuery,
  useLazyGetProductBundleByIdQuery,
  useGetProductDeletionDetailsQuery,
  useLazyGetProductDeletionDetailsQuery,
  useGetProductSeedsQuery,
  useLazyGetProductSeedsQuery,
  useCreateReportQuery,
  useLazyCreateReportQuery,
  useGetNearestServicePointsQuery,
  useLazyGetNearestServicePointsQuery,
  useAllShippingZonesQuery,
  useLazyAllShippingZonesQuery,
  useCalculateShippingPriceQuery,
  useLazyCalculateShippingPriceQuery,
  useGetAllStockMovementsQuery,
  useLazyGetAllStockMovementsQuery,
  useGetStockMovementByIdQuery,
  useLazyGetStockMovementByIdQuery,
  useGetIntegrationCredentialsInfoQuery,
  useLazyGetIntegrationCredentialsInfoQuery,
  useGetTracezillaOrderQuery,
  useLazyGetTracezillaOrderQuery,
  useGetMembershipListQuery,
  useLazyGetMembershipListQuery,
  useGetInvitationListQuery,
  useLazyGetInvitationListQuery,
  useGetUserListQuery,
  useLazyGetUserListQuery,
  useGetInvitationByIdQuery,
  useLazyGetInvitationByIdQuery,
  useGetAllWarehousesQuery,
  useLazyGetAllWarehousesQuery,
  useGetWarehouseByOrderQuery,
  useLazyGetWarehouseByOrderQuery,
  useAllShopsQuery,
  useLazyAllShopsQuery,
  useGetStoreQuery,
  useLazyGetStoreQuery,
  useGetAllCollectionsQuery,
  useLazyGetAllCollectionsQuery,
  useGetCollectionByIdQuery,
  useLazyGetCollectionByIdQuery,
  useGetExtendedCollectionByIdQuery,
  useLazyGetExtendedCollectionByIdQuery,
  useGetDiscountPercentageByIdQuery,
  useLazyGetDiscountPercentageByIdQuery,
  useGetAllShopDiscountsQuery,
  useLazyGetAllShopDiscountsQuery,
  useGetWebshopProductByIdQuery,
  useLazyGetWebshopProductByIdQuery,
  usePaginatedAllWebshopProductsQuery,
  useLazyPaginatedAllWebshopProductsQuery,
} = injectedRtkApi;
